import React from "react"
import MetaTags from "react-meta-tags"
import {Button, Card,Input, CardBody, Col, Container, Label, Row,} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import SimpleReactValidator from 'simple-react-validator'
import {get,post} from "../../helpers/api_helper"
import {GET_ALL_MASTERS,ADD_VENDOR} from "../../helpers/api_url_helper";
import toastr from "toastr"
import PropTypes from "prop-types"
import Select from "react-select";
import '../../NewsTimeline.css';import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


class JobRoleAdd extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      company_name: "",
      brand: "",
      brand_sub_category: "",
      mobile: "",
      email: "",
      address: "",
      rate_amount: "",
      work_portfolio: "",
      remark: "",
      category: "",
      city: "",
      status: true,
      handleResponse: null,
      submit: false,
      vendor_category_master: [],
      location_master: [],
    }
    this.validator = new SimpleReactValidator({
      validators: {
          Number: {  // Add custom validation for number
              message: 'The :attribute must be a valid number.',
              rule: (val, params, validator) => {
                  return validator.helpers.testRegex(val, /^[0-9]+$/) && params.indexOf(val) === -1;
              },
              required: false  // Make the field required
          }
      }
    });
  }

  handleInput = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }

  handleFormSubmit = (e) => {
    e.preventDefault()
    if (this.validator.allValid()) {
      const formData = {
        "name":this.state.name,
        "company_name":this.state.company_name,
        "brand":this.state.brand,
        "brand_sub_category":this.state.brand_sub_category,
        "mobile": this.state.mobile,
        "email": this.state.email,
        "address": this.state.address,
        "rate_amount": this.state.rate_amount,
        "work_portfolio": this.state.work_portfolio,
        "remark": this.state.remark,
        "category": this.state.category?.value,
        "city": this.state.city?.value,
        "status" : this.state.status ? 1 : 0
      }
      this.setState({submit: true})
      post(ADD_VENDOR,formData).then(response => {
        if (response.status) {
          this.setState({submit: false})
          toastr.success('Vendor added successful.')
          // eslint-disable-next-line react/prop-types
          const {history} = this.props
          // eslint-disable-next-line react/prop-types
          history.push('/manage-vendor')
        }
      }).catch(err => {
        toastr.error(err?.response?.data?.message)
        this.setState({submit: false})
      })
    } else {
      this.validator.showMessages()
      this.forceUpdate()
    }
  }
  
  componentDidMount() {
    this.loadMaster();
  }
  
  loadMaster = () => {
    get(GET_ALL_MASTERS, { params: { masters: 'vendor_category,location' } }).then(res => {
      if (res.status) {
        let data = res.data;
        this.setState({ vendor_category_master: data.vendor_category , location_master: data.location})
      }
    }).catch(err => {
      toastr.error(err)
    })
  }

  render() {
    
    const { vendor_category_master,mobile ,location_master} = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>
              Manage Vendor | {process.env.REACT_APP_PROJECTNAME}
            </title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Manage Vendor" path="/manage-vendor" breadcrumbItem="Add"/>
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <form onSubmit={this.handleFormSubmit}>
                      <Row>
                        <h4 className="mb-3">Basic Details</h4>
                        <Col md="4" className="mb-3">
                          <Label htmlFor="category">Category <span className="text-danger">*</span></Label>
                          <Select isMulti={false} name="category" options={vendor_category_master} onChange={e => this.setState({ category: e })} classNamePrefix="select2-selection" />
                          {this.validator.message("category", this.state.category, "required")}
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label htmlFor="name">Name <span className="text-danger">*</span></Label>
                          <input name="name" placeholder="Type Name" defaultValue={this.state.name} type="text" className={"form-control"} onChange={this.handleInput}/>
                          {this.validator.message('name', this.state.name, 'required')}
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label htmlFor="brand">Brand</Label>
                          <input name="brand" placeholder="Type Brand" defaultValue={this.state.brand} type="text" className={"form-control"} onChange={this.handleInput}/>
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label htmlFor="brand_sub_category">Brand Sub Category</Label>
                          <input name="brand_sub_category" placeholder="Type Brand Sub Category" defaultValue={this.state.brand_sub_category} type="text" className={"form-control"} onChange={this.handleInput}/>
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label htmlFor="company_name">Company Name</Label>
                          <input name="company_name" placeholder="Type Company Name" defaultValue={this.state.company_name} type="text" className={"form-control"} onChange={this.handleInput}/>
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label htmlFor="Mobile">Mobile<span className="text-danger">*</span></Label>
                          <Input 
                              name="mobile" 
                              placeholder="Type Mobile" 
                              type="tel" 
                              value={mobile}
                              onChange={this.handleInput}
                              className="form-control" 
                          />
                          {this.validator.message("mobile", mobile, "required|Number")}
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label htmlFor="Email">Email</Label>
                          <input name="email" placeholder="Type Email Address" type="email" className={"form-control"}
                            onChange={this.handleInput} />
                          {this.validator.message("email", this.state.email, "email")}
                        </Col>  
                        <Col md="4" className="mb-3">
                          <Label htmlFor="city">City <span className="text-danger">*</span></Label>
                          <Select isMulti={false} name="city" options={location_master} onChange={e => this.setState({ city: e })} classNamePrefix="select2-selection" />
                          {this.validator.message("City", this.state.city, "required")}
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label htmlFor="address">Address</Label>
                          <textarea name="address" placeholder="Type Address" rows={3} className={"form-control"}
                            onChange={this.handleInput} />
                        </Col>
                      </Row>
                      <Row>
                        <h4 className="mt-4 mb-3">Additional Details</h4>
                        <Col md="4" className="mb-3">
                          <Label htmlFor="rate_amount">Rate/Amount</Label>
                          <Input name="rate_amount" placeholder="Type Rate/Amount" type="text" className={"form-control"}
                            onChange={this.handleInput} />
                          {this.validator.message("Rate/Amount", this.state.rate_amount, "Number")}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" className="mb-3">
                          <Label htmlFor="work_portfolio">
                          Work Portfolio
                          </Label>
                          <CKEditor
                              editor={ClassicEditor}
                              onChange={(event, editor) => {
                                  const data = editor.getData();
                                  this.setState({work_portfolio: data})
                              }}
                              name="work_portfolio"
                          />
                        </Col>
                        <Col md="6" className="mb-3">
                          <Label htmlFor="remark">
                          Remark
                          </Label>
                          <CKEditor
                              editor={ClassicEditor}
                              onChange={(event, editor) => {
                                  const data = editor.getData();
                                  this.setState({remark: data})
                              }}
                              name="remark"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="auto" className="mb-3 align-self-end">
                          <div className="form-check form-switch form-switch-lg">
                            <input type="checkbox" className="form-check-input" id="current_status" checked={this.state.status} onClick={() => {this.setState({ status: !this.state.status, })}} />
                            <label className="form-check-label" htmlFor="current_status">Status</label>
                          </div>
                        </Col>
                      </Row>
                      <div className="d-flex flex-wrap gap-2 justify-content-end">
                        <Button color="primary" type="submit" disabled={this.state.submit}>
                          {this.state.submit === true ? 'Please wait...' : 'Submit Data'}
                        </Button>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

JobRoleAdd.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  location: PropTypes.object
}

export default JobRoleAdd

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Route, Redirect, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NotFound from "../pages/Utility/pages-404";
import { apiGetUserPermissions } from "services/UsersService";
import { setUserPermissions } from "store/users/usersSlice";
let dummy = {
        "leaves": {
            "apply_leave": true,
            "logs": true,
            "approvals": true,
            "rules": {
                "show_rules": true,
                "create_rule": false,
                "assign_rule": true
            },
            "balance": true
        },
        "attendance": {
            "logs": true,
            "export": true,
            "monthly_logs": true,
            "approvals": true,
            "rules": {
                "show_rules": true,
                "create_rule": false,
                "assign_rule": true
            }
        },
        "expenses": {
            "category": {
                "add": true,
                "edit": false,
                "import": true
            },
            "Logs": true,
            "add": true,
            "export": true
        },
        "deductions": {
            "add": true,
            "export": true,
            "edit": false
        },
        "holiday": {
            "add": true,
            "import": true,
            "edit": false
        },
        "directory": {
            "add": true,
            "edit_others": true,
            "export": true
        },
        "company": [],
        "office_locations": [],
        "departments": {
            "export": true,
            "import": true,
            "add": true,
            "edit": false,
            "list": true
        },
        "subdepartments": {
            "export": true,
            "import": true,
            "add": true,
            "edit": false,
            "list": true
        },
        "employement_type": {
            "export": true,
            "import": true,
            "add": true,
            "edit": false,
            "list": true
        },
        "designations": {
            "export": true,
            "import": true,
            "add": true,
            "edit": false,
            "list": true
        },
        "notice_periods": {
            "export": true,
            "import": true,
            "add": true,
            "edit": false,
            "list": true
        },
        "probition_periods": {
            "export": true,
            "import": true,
            "add": true,
            "edit": false,
            "list": true
        },
        "permissions": {
            "export": true,
            "import": true,
            "add": true,
            "edit": false,
            "list": true
        },
        "ats": {
            "view": true,
            "add": true,
            "edit": false
        },
        "sample_csv": {
            "view": true,
            "add": true,
            "edit": false
        },
        "job_role": {
            "export": true,
            "import": true,
            "add": true,
            "edit": false,
            "list": true
        },
        "skills": {
            "export": true,
            "import": true,
            "add": true,
            "edit": false,
            "list": true
        },
        "client_source": {
            "export": true,
            "import": true,
            "add": true,
            "edit": false,
            "list": true
        },
        "candidate_source": {
            "export": true,
            "import": true,
            "add": true,
            "edit": false,
            "list": true
        },
        "salary_currency": {
            "export": true,
            "import": true,
            "add": true,
            "edit": false,
            "list": true
        },
        "client_status": {
            "export": true,
            "import": true,
            "add": true,
            "edit": false,
            "list": true
        },
        "requirement_status": {
            "export": true,
            "import": true,
            "add": true,
            "edit": false,
            "list": true
        },
        "country": {
            "export": true,
            "import": true,
            "add": true,
            "edit": false,
            "list": true
        },
        "state": {
            "export": true,
            "import": true,
            "add": true,
            "edit": false,
            "list": true
        },
        "city": {
            "export": true,
            "import": true,
            "add": true,
            "edit": false,
            "list": true
        }
    }
const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  requiredPermission,
  ...rest
}) => {
  const user = localStorage.getItem("authUser") !== "" && JSON.parse(localStorage.getItem("authUser"));
  const { userPermissions } = useSelector((state) => state.users);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation(); // Get the current location

  const hasPermission = () => {
    if (!requiredPermission) return true;
    switch (requiredPermission) {
      // Add your permission check logic here
      case "add_department":
        return userPermissions?.departments?.add;
      case "edit_department":
        return userPermissions?.departments?.edit;
      case "Department_list":
        return userPermissions?.departments?.list;
      // Sub-Department
      case "add_subdepartment":
        return userPermissions?.subdepartments?.add;
      case "edit_subdepartment":
        return userPermissions?.subdepartments?.edit;
      case "SubDepartment_list":
        return userPermissions?.subdepartments?.list;
    
      // Employment Type
      case "add_empType":
        return userPermissions?.employement_type?.add;
      case "edit_empType":
        return userPermissions?.employement_type?.edit;
      case "EmploymentType_list":
        return userPermissions?.employement_type?.list;
    
      // Designations
      case "add_designation":
        return userPermissions?.designations?.add;
      case "edit_designation":
        return userPermissions?.designations?.edit;
      case "Designations_list":
        return userPermissions?.designations?.list;
    
      // Probation Period
      case "add_probation":
        return userPermissions?.probition_periods?.add;
      case "edit_probation":
        return userPermissions?.probition_periods?.edit;
      case "ProbationPeriod_list":
        return userPermissions?.probition_periods?.list;
    
      // Holiday
      case "add_holiday":
        return userPermissions?.holiday?.add;
      case "edit_holiday":
        return userPermissions?.holiday?.edit;
      case "Holiday_list":
        return true;
    
      // Country
      case "add_country":
        return userPermissions?.country?.add;
      case "edit_country":
        return userPermissions?.country?.edit;
      case "Country_list":
        return userPermissions?.country?.list;
    
      // State
      case "add_state":
        return userPermissions?.state?.add;
      case "edit_state":
        return userPermissions?.state?.edit;
      case "State_list":
        return userPermissions?.state?.list;
    
      // City
      case "add_city":
        return userPermissions?.city?.add;
      case "edit_city":
        return userPermissions?.city?.edit;
      case "City_list":
        return userPermissions?.city?.list;
    
      // Office Location (no permissions provided, so no case needed)
      
      // Job Roles
      case "add_job_role":
        return userPermissions?.job_role?.add;
      case "edit_job_role":
        return userPermissions?.job_role?.edit;
      case "JobRole_list":
        return userPermissions?.job_role?.list;
    
      // Skills
      case "add_job_skill":
        return userPermissions?.skills?.add;
      case "edit_job_skill":
        return userPermissions?.skills?.edit;
      case "Skills_list":
        return userPermissions?.skills?.list;
    
      // Client Source
      case "add_client_source":
        return userPermissions?.client_source?.add;
      case "edit_client_source":
        return userPermissions?.client_source?.edit;
      case "ClientSource_list":
        return userPermissions?.client_source?.list;
    
      // Candidate Source
      case "add_candidate_saurce":
        return userPermissions?.candidate_source?.add;
      case "edit_candidate_saurce":
        return userPermissions?.candidate_source?.edit;
      case "CandidateSource_list":
        return userPermissions?.candidate_source?.list;
    
      // Client Status
      case "add_client_status":
        return userPermissions?.client_status?.add;
      case "edit_client_status":
        return userPermissions?.client_status?.edit;
      case "ClientStatus_list":
        return userPermissions?.client_status?.list;
    
      // Requirement Status
      case "add_requirement_status":
        return userPermissions?.requirement_status?.add;
      case "edit_requirement_status":
        return userPermissions?.requirement_status?.edit;
      case "RequirementStatus_list":
        return userPermissions?.requirement_status?.list;
    
      // Salary Currency
      case "add_salary_carurrency":
        return userPermissions?.salary_currency?.add;
      case "edit_salary_carurrency":
        return userPermissions?.salary_currency?.edit;
      case "SalaryCurrency_list":
        return userPermissions?.salary_currency?.list;
    
      // Notice Period
      case "add_notice":
        return userPermissions?.notice_periods?.add;
      case "edit_notice":
        return userPermissions?.notice_periods?.edit;
      case "NoticePeriod_list":
        return userPermissions?.notice_periods?.list;
        
      // Brand
       case "add_brands":
        return userPermissions?.brands?.add;
      case "edit_brands":
        return userPermissions?.brands?.edit;
      case "brands_list":
        return userPermissions?.brands?.list;
        
      // Busines Category 
      case "add_business_category":
        return userPermissions?.business_category?.add;
      case "edit_business_category":
        return userPermissions?.business_category?.edit;
      case "business_category_list":
        return userPermissions?.business_category?.list;
        
      // ES Element
      case "add_es_element":
        return userPermissions?.es_element?.add;
      case "edit_es_element":
        return userPermissions?.es_element?.edit;
      case "es_element_list":
        return userPermissions?.es_element?.list;
        
      // ES Category 
      case "add_es_category":
        return userPermissions?.es_category?.add;
      case "edit_es_category":
        return userPermissions?.es_category?.edit;
      case "es_category_list":
        return userPermissions?.es_category?.list;
        
      //Lead Source
      case "add_leads_source":
        return userPermissions?.leads_source?.add;
      case "edit_leads_source":
        return userPermissions?.leads_source?.edit;
      case "leads_source_list":
        return userPermissions?.leads_source?.list;
        
      //Vendor Category
      case "add_vendor_category":
        return userPermissions?.vendor_category?.add;
      case "edit_vendor_category":
        return userPermissions?.vendor_category?.edit;
      case "vendor_category_list":
        return userPermissions?.vendor_category?.list;
        
      //Vendor
      case "add_manage_vendor":
        return userPermissions?.manage_vendor?.add;
      case "edit_manage_vendor":
        return userPermissions?.manage_vendor?.edit;
      case "manage_vendor_list":
        return userPermissions?.manage_vendor?.list;
    
      // Additional cases
      case "leave_logs":
        return userPermissions?.leaves?.logs;
      case "add_user":
        return userPermissions?.directory?.add;
      case "attendence_logs":
        return userPermissions?.attendance?.logs;
      case "edit_attendance_rule":
        return userPermissions?.attendance?.rules?.create_rule;
      case "edit_leave_rule":
        return userPermissions?.leaves?.rules?.create_rule;
      case "expenses_category":
        return userPermissions?.expenses?.category?.list;
      case "expense_logs":
        if (!userPermissions?.expenses?.Logs) {
          history.push("/expenses"); // Redirect to /expenses if permission is not available
          return false;
        }
        return userPermissions?.expenses?.Logs;
      default:
        return false;
    }
  };

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const response = await apiGetUserPermissions();
        console.log('fetched', response?.data);

        dispatch(setUserPermissions(response?.data?.data)); 
        // dispatch(setUserPermissions(dummy)); 
      } catch (error) {
        console.error("Failed to fetch user permissions", error);
      } finally {
        setLoading(false);
      }
    };

    if (user && (!userPermissions || Object.keys(userPermissions).length === 0)) {
      // Only fetch permissions if not on the login page
      if (location.pathname !== "/login") {
        fetchUserPermissions();
      } else {
        setLoading(false); // Directly set loading to false on the login page
      }
    } else {
      setLoading(false);
    }
  }, []);


  return (
    <Route
      {...rest}
      render={(props) => {
        if (loading) {
          // Show loading spinner while permissions are being fetched
          return (
            <Layout>
              <div id="preloader" style={{ display: "block" }}>
                <div id="status" style={{ display: "block" }}>
                  <div className="spinner-chase">
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                    <div className="chase-dot" />
                  </div>
                </div>
              </div>
            </Layout>
          );
        }
        if ((props.location.pathname === "/login" || props.location.pathname === "/") && user) {
          return <Redirect to="/profile/view" />;
        }

        if (isAuthProtected && !user) {
          return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
        }

        if (user && parseJwt(user.token).exp * 1000 < Date.now()) {
          localStorage.setItem("authUser", "");
          return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
        }

        if (isAuthProtected && !hasPermission()) {
          return <NotFound />;
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  requiredPermission: PropTypes.string,
};

export default Authmiddleware;

import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
} from "reactstrap";
import MetaTags from "react-meta-tags";
import { TableHeader, Search } from "components/Datatable/index"
import { CSVLink } from "react-csv";
import { GET_ALL_MASTERS, TNA_IMPORT, GET_TARGET_ACHIEVEMENT } from "helpers/api_url_helper";
import { get } from "helpers/api_helper";
import { Link } from "react-router-dom"
import Select from "react-select";
import axios from "axios";
import accessToken from "helpers/jwt-token-access/accessToken";
import toastr from "toastr"
import { downloadTandASampleCsv } from "constants/CommonFunction";
import Pagination from "components/Common/Pagination"
import { useSelector } from "react-redux"
import useFullPageLoader from "../../components/Common/useFullPageLoader"

const TandA = () => {
  const [csvLoad, setCsvLoad] = useState(false);
  const [formMsg, setFormMsg] = useState("");
  const [loader, showLoader, hideLoader] = useFullPageLoader()
  const [totalItems, setTotalItems] = useState(0)
  const [customActiveTab, setCustomActiveTab] = useState("1");
  const [assignedToMaster, setAssignedToMaster] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [reloadData, setReloadData] = useState(false)

  const csvInstance = useRef(null);
  
  const [tableData, settableData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState("")
  const TableColum = [
    { name: "#", field: "id", sortable: false },
    { name: "Brand", field: "category_name", sortable: false },
    { name: "Business Category", field: "name", sortable: false },
    { name: "Employee", field: "ordering", sortable: false },
    { name: "Month", field: "status", sortable: false },
    { name: "Year", field: "status", sortable: false },
    { name: "Target", field: "status", sortable: false },
    { name: "Action", field: "", sortable: false },
  ].filter(Boolean);

  let PageSize = 10
  useEffect(() => {
    showLoader()
    const params = { keyword: search, length: PageSize, start: currentPage }
    get(GET_TARGET_ACHIEVEMENT, { params: params })
      .then(res => {
        hideLoader()
        if (res) {
          settableData(res.data)
          setTotalItems(res.total)
        }
      })
      .catch(err => {
        hideLoader()
        toastr.error(err?.response?.data?.error)
      })
  }, [currentPage, search, reloadData]) 

  const headers = [
    { label: "Brand", key: "brand" },
    { label: "Business Category", key: "business_category" },
    { label: "Employee Id", key: "employee_id" },
    { label: "Year", key: "year" },
    { label: "Month", key: "month" },
    { label: "Target", key: "target" }
  ];

  const newData = [
    {
      brand: "House of Vivaah",
      business_category: "Haldi",
      employee_id: "apc#123",
      year: "2024",
      month: "8",
      target: "4"
    },
    {
      brand: "Party House",
      business_category: "Wedding",
      employee_id: "AC0006",
      year: "2024",
      month: "9",
      target: "4"
    },
    {
      brand: "Party House",
      business_category: "Haldi",
      employee_id: "apc#123",
      year: "2024",
      month: "10",
      target: "2"
    },
    {
      brand: "Party House",
      business_category: "Wedding",
      employee_id: "apc#123",
      year: "2024",
      month: "9",
      target: "10"
    },
  ];

  const downloadData = () => {
    setCsvLoad(true);
    setCsvData(newData);
    setTimeout(() => {
      csvInstance.current.link.click();
      setCsvLoad(false);
    }, 1000);
  };

  
  const downloadRejectCSV = (data) => {
    // Convert the array of objects to CSV format
    const csvRows = [];
    
    // Get headers
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(',')); // join headers by comma
    
    // Loop over the rows
    for (const row of data) {
      const values = headers.map(header => {
        let cellValue = row[header] === null || row[header] === undefined ? '' : row[header]; // replace null or undefined with an empty string
        const escape = ('' + cellValue).replace(/"/g, '\\"');
        return `"${escape}"`; // wrap in double quotes and escape any inner quotes
      });
      csvRows.push(values.join(',')); // join values by comma
    }
  
    // Create a Blob from the CSV string
    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
  
    // Create a link element and trigger the download
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'data.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };   

  const ImportCSV = e => {
    e.preventDefault();
    let file = document.getElementById("file").files[0];
    if (file !== undefined) {
      const formData = new FormData();
      formData.append("master_name", "assign_target");
      formData.append("master_data", file);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_APIURL}${TNA_IMPORT}`,
        data: formData,
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
      })
        .then(response => {
          if (response.data.message && response.data.status == false) {
            toastr.error(response.data.message);
          }
          if (response.status) {
            setReloadData(prevState => !prevState); // Ensure toggling
            document.getElementById("file").value = "";
            if (response.data.data.msg) {
              toastr.success(response.data.data.msg);
            }
            if(response.data.data.result){
            downloadRejectCSV(response.data.data.result);
            }
          }else{
            toastr.error(response?.message);
          }
        })
        .catch(err => {
          // toastr.error(err?.response?.data?.message);
        });
    } else {
      setFormMsg("Please select a valid excel sheet");
    }
  };

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  };

  useEffect(() => {
    loadMaster();
  }, [reloadData]);

  const loadMaster = () => {
    get(GET_ALL_MASTERS, { params: { masters: "employees" } })
      .then((res) => {
        if (res.status) {
          setAssignedToMaster(res.data.employees);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Assign Target | PARTY CRUISERS LTD</title>
        </MetaTags>
        <div className="container-fluid">
          <Card className="sticky-main-header">
            <CardBody>
              <div className="row">
                <div className="col-sm-4">
                  <div className="mt-2 mb-3">
                    <h4 className="m-0">Assign Target</h4>
                  </div>
                </div>
                <div className="col-sm-8">
                  <Row className={"justify-content-end"}>
                    <Col md="auto mb-3">
                      <Button
                        title="Download"
                        type="button"
                        onClick={downloadTandASampleCsv}
                        className="btn-rounded btn btn-outline-primary"
                        color="white"
                      >
                        <i className="mdi mdi-file-export"></i>{" "}
                        {csvLoad === false ? "Sample Import" : "Please wait..."}
                      </Button>
                      <CSVLink
                        data={csvData}
                        headers={headers}
                        filename={`t&a-sample-data.csv`}
                        target="_blank"
                        ref={csvInstance}
                        style={{ display: "none" }}
                      />
                    </Col>
                    <Col md="auto mb-3">
                      <form onSubmit={ImportCSV} className="input-group">
                        <Input
                          type="file"
                          className="form-control"
                          name={"file"}
                          accept={".xlsx"}
                          id="file"
                          aria-describedby="file"
                          aria-label="Upload"
                        />
                        <Button
                          color="primary"
                          type="submit"
                          id="inputGroupFileAddon04"
                        >
                          Import
                        </Button>
                      </form>
                      {formMsg !== "" && (
                        <span className="text-danger font-size-12">
                          {formMsg}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="col-md-4">
                <Search
                  onSearch={value => {
                    setSearch(value)
                    setCurrentPage(1)
                  }}
                />
              </div>
              <div className="table-responsive mt-4">
                <table className="table table-striped">
                  <TableHeader
                    headers={TableColum}
                    // onSorting={(field, order) =>
                    //     setSorting({field, order})
                    // }
                  />
                  <tbody className="">
                    {loader && (
                      <tr>
                        <th colSpan={TableColum.length}>
                          <h4 className="text-center">Loading...</h4>
                        </th>
                      </tr>
                    )}
                    {!loader && tableData.length === 0 && (
                      <tr>
                        <th colSpan={TableColum.length}>
                          <h4 className="text-center">No data found</h4>
                        </th>
                      </tr>
                    )}
                    {!loader &&
                      tableData.map((value, index) => (
                        <tr key={++index}>
                          <th scope="row">
                            {PageSize * (currentPage - 1) + (index + 1)}
                          </th>
                          <td>{value.brand}</td>
                          <td>{value.business_category}</td>
                          <td>{value.employee}</td>
                          <td>{value.month}</td>
                          <td>{value.year}</td>
                          <td>{value.target}</td>
                          <td>
                            <Link to={`/assign-target/edit/${value.id}`}>
                              <i className="bx bxs-edit-alt"></i>{" "}
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <Row className="justify-content-center">
                <Col className="col-auto">
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={totalItems}
                    pageSize={PageSize}
                    onPageChange={page => setCurrentPage(page)}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TandA;

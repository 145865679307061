import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Button, Card, CardBody, CardTitle, Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchAuthProfileDetail,
  fetchProfileDetail,
  setEditPersonalDetail,
  uploadProfileImage,
} from "store/onBoarding/onBordingSlice"
import {
  formatDateFormat,
  getFullName,
  getUserId,
  sortName,
} from "constants/CommonFunction"
import { useLocation } from "react-router-dom"
import ImageUploader from "./ImageUpload"

const OnboardingPersonalDetail = () => {
  const dispatch = useDispatch()
  const { profileDetails, userId, editProfile,  } = useSelector(
    state => state.onBoarding
  );
  const authUserId = getUserId();

  const location = useLocation()
  

  return (
    <>
      <div className="d-flex justify-content-center">
        <Row className="profile-container custom-card-container">
          <Col lg="3">
            <Card style={{ height: "250px" }}>
              <CardBody>
                <CardTitle className="header-text">Profile Image</CardTitle>
                <hr className="underline" />
                  <ImageUploader profileDetails={profileDetails} name={location?.state?.row} editProfile={editProfile} />
              </CardBody>
            </Card>
          </Col>
          <Col lg="9">
            <Card className="mb-lg-75">
              <CardBody>
                <Row>
                  <Col md={12}>
                    <div className="d-flex align-items-center">
                      <CardTitle className="text-[#495057]">
                        Personal Info
                      </CardTitle>
                      {editProfile && (
                        <Col>
                          <div className="d-flex justify-content-end">
                            <i
                              className="bx bxs-edit mx-4 text-primary fs-5 cursor-pointer"
                              onClick={() => {
                                dispatch(setEditPersonalDetail(true))
                              }}
                            />
                          </div>
                        </Col>
                      )}
                    </div>
                    <hr className="underline" />
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label className="labels" htmlFor="first_name">
                      First Name
                    </Label>
                    <div className="values">
                      {profileDetails?.first_name
                        ? profileDetails?.first_name
                        : "-"}
                    </div>
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label className="labels" htmlFor="last_name">
                      Last Name
                    </Label>
                    <div className="values">
                      {profileDetails?.last_name
                        ? profileDetails?.last_name
                        : "-"}
                    </div>
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label className="labels" htmlFor="gender">
                      Gender
                    </Label>
                    <div className="values">
                      {profileDetails?.gender ? profileDetails?.gender : "-"}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-lg-75 ">
                  <Col md="3" className="mb-3">
                    <Label className="labels" htmlFor="blood_group">
                      Blood Group
                    </Label>
                    <div className="values">
                      {profileDetails?.blood_group
                        ? profileDetails?.blood_group
                        : "-"}
                    </div>
                  </Col>
                  <Col md="3" className="mb-3">
                    <Label className="labels" htmlFor="marital_status">
                      Marital Status
                    </Label>
                    <div className="values">
                      {profileDetails?.marital_status
                        ? profileDetails?.marital_status
                        : "-"}
                    </div>
                  </Col>
                  <Col md="3" className="mb-3">
                    <Label className="labels" htmlFor="date_of_birth">
                      Date Of Birth
                    </Label>
                    <div className="values">
                      {profileDetails?.dob
                        ? formatDateFormat(profileDetails?.dob)
                        : "-"}
                    </div>
                  </Col>
                  <Col md="3" className="mb-3">
                    <Label className="labels" htmlFor="date_of_joining">
                      Date Of Joining
                    </Label>
                    <div className="values">
                      {profileDetails?.date_of_joining
                        ? formatDateFormat(profileDetails?.date_of_joining)
                        : "-"}
                    </div>
                  </Col>
                  <Col md="3" className="mb-3">
                    <Label className="labels" htmlFor="Office_location">
                      Office Location
                    </Label>
                    <div className="values">
                      {profileDetails?.office_location_name}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default OnboardingPersonalDetail

import React, { useState } from "react";
import ImageUploading from "react-images-uploading";
import Cropper from "react-easy-crop";
import { cropImage } from "constants/cropUtils";
import { Button, Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { getFullName, getUserId, sortName } from "constants/CommonFunction";
import { fetchAuthProfileDetail, fetchProfileDetail, uploadProfileImage } from "store/onBoarding/onBordingSlice";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr"

const ImageCropper = ({ open, image, onComplete, setDialogOpen }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const toggle = () => setDialogOpen(!open);

  return (
    <Modal isOpen={open} toggle={toggle} size="lg" centered>
      <ModalHeader toggle={toggle}>Crop Image</ModalHeader>
      <ModalBody>
        <Row>
            <Col xl="12" style={{height:"200px"}}>
                {image ? (
                <Cropper
                    image={image}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={setCrop}
                    onCropComplete={(_, croppedArea) => setCroppedAreaPixels(croppedArea)}
                    onZoomChange={setZoom}
                />
                ) : (
                <p>No image to crop</p>
                )}
            </Col>
        </Row>
        <div className="d-flex justify-content-end mt-3">
          <Button
            color="primary"
            onClick={() => {
              const croppedImgPromise = cropImage(image, croppedAreaPixels);
              onComplete(croppedImgPromise);
            }}
          >
            Submit
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default function ImageUploader({ profileDetails, name, editProfile }) {
  const [image, setImage] = useState([]);
  const [preview, setPreview] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch()
  const { userId } = useSelector(
    state => state.onBoarding
  );
  const authUserId = getUserId();

  const handleImageChange = async (file) => {
    if (file) {
      // Convert Base64 string to Blob if necessary
      const isBase64 = typeof file === "string" && file.startsWith("data:image");
      let binaryFile = file;
  
      if (isBase64) {
        const base64Response = await fetch(file);
        binaryFile = await base64Response.blob(); // Convert Base64 to Blob
      }
  
      // Check if binaryFile is valid
      if (!(binaryFile instanceof Blob)) {
        console.error("Invalid file format:", binaryFile);
        return;
      }
  
      const formData = new FormData();
      formData.append("profile_photo", binaryFile); // Send the binary file
  
      try {
        const response = await dispatch(
          uploadProfileImage({ id: userId, data: formData })
        );
  
        if (response.payload?.status === "success") {
          toastr.success(response?.payload?.data?.data?.message);
          if (userId === authUserId) {
            dispatch(fetchAuthProfileDetail(authUserId));
          }
        } else {
          toastr.error(response?.payload?.message);
        }
      } catch (error) {
        toastr.error("Something went wrong.");
      }
  
      await dispatch(fetchProfileDetail(userId));
      // Preview the uploaded file
      if (binaryFile) {
        setPreview(URL.createObjectURL(binaryFile));
      }
    }
  };
  

  return (
    <>
      <div className="d-flex flex-column align-items-center">
        <div className="text-center">
          {preview ? (
            <img
              src={preview}
              alt="Profile Preview"
              className="rounded-circle profile-image"
              style={{ height: "130px", width: "140px" }}
            />
          ) : profileDetails?.profile_photo ? (
            <img
              src={profileDetails.profile_photo}
              alt="Profile"
              className="rounded-circle profile-image"
              style={{ height: "130px", width: "140px" }}
            />
          ) : (
            <div
              className="avatar-lg align-self-center profile-placeholder"
              style={{ height: "130px", width: "140px" }}
            >
              <span className="avatar-title rounded-circle bg-primary bg-soft fs-3 text-primary">
                {name ? sortName(name.first_name + " " + name.last_name) : sortName(getFullName())}
              </span>
            </div>
          )}
        </div>
        {editProfile && (
          <Label className="btn btn-primary mt-2 d-flex align-items-center btn-sm">
            <input
              type="file"
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files[0];
                if (file) {
                  setImage([{ dataURL: URL.createObjectURL(file), file }]);
                  setDialogOpen(true); // Open modal when file is selected
                }
              }}
              className="form-control"
              style={{ display: "none" }}
            />
            {profileDetails?.profile_photo ? "Update Image" : "Upload Image"}
          </Label>
        )}
      </div>
      
      <ImageCropper
        open={dialogOpen}
        setDialogOpen={setDialogOpen}
        image={image.length > 0 ? image[0].dataURL : null}
        onComplete={async (croppedImgPromise) => {
          const croppedImage = await croppedImgPromise;
          setCroppedImage(croppedImage);
          handleImageChange(croppedImage);
          setDialogOpen(false);
        }}
      />
    </>
  );
}

import axios from "axios"
import { clearUserDetails, getUserDetails } from "constants/CommonFunction"
const TOKEN_TYPE = "Bearer"
const REQUEST_HEADER_AUTH_KEY = "Authorization"

const BaseService = axios.create({
  timeout: 600000,
  baseURL: process.env.REACT_APP_APIURL,
})

BaseService.interceptors.request.use(
  config => {
    const accessToken = getUserDetails()
    if (accessToken) {
      config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE} ${accessToken}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

BaseService.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      if (error.response.status === 401) {
        clearUserDetails()
        // Get current location
        const currentPath = window.location.pathname;
        localStorage.removeItem("authUser");        // Redirect to login page with next query parameter
        window.location.href = `/login?next=${currentPath}`;
      }
      return Promise.reject(error)
    } else {
      return Promise.reject(error)
    }
  }
)

export default BaseService

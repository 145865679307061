import React, { useEffect, useMemo, useState } from "react"
import { Row, Col, Card, CardBody, Button, CardFooter, CustomInput, Input, ButtonToggle, Spinner } from "reactstrap"
import Pagination from "components/Common/Pagination"
import { Search } from "components/Datatable/index"
import GlobalTable from "components/Datatable/GlobalTable"
import { useDispatch, useSelector } from "react-redux"
import toastr from "toastr"
import { sortName } from "constants/CommonFunction"
import {
  fetchUsers,
  setUsersCurrentPage,
  setUserSearchQuery,
} from "store/users/usersSlice"
import { Link, useHistory } from "react-router-dom"
import useExportXlsx from "components/Hooks/useExportXlsx"
import { Form } from "react-bootstrap"
import axios from "axios"
import { updateUserStatus } from "services/UsersService"
import { EXPORT_MASTER } from "helpers/api_url_helper"
import accessToken from "helpers/jwt-token-access/accessToken"
import useImportXlxs from "components/Hooks/useImportXlxs"
import { setOnBoardingActiveTabs } from "store/onBoarding/onBordingSlice"

export const UsersView = () => {
  const {
    currentPage,
    userSearchQuery,
    usersData,
    UserstotalData,
    loading,
    verifyUser,
  } = useSelector(state => state.users);
  const [file, setFile] = useState(null)
  const dispatch = useDispatch()
  const history = useHistory();

  const handleProfileClick =(row)=>{
    dispatch(setOnBoardingActiveTabs("1"))
    history.replace({ pathname: `/profile/view`, state: { row: row.original }});
  }
  const columns = useMemo(() => {
    return [
      {
        id: "srNo",
        Header: () => <div style={{ width: "60px" }}>Sr.No</div>,
        accessor: (_row, index) => index + 1,
        width: 120,
        Cell: ({ row }) => (
          <div>{(currentPage - 1) * 10 + (row.index + 1)}</div>
        ),
      },
      {
        Header: () => <div style={{ width: "120px" }}>ID</div>,
        accessor: "emp_id",
        Cell: ({ row }) => (
          <div>
            {row.original.emp_id ? (
              directory?.edit_others ? (
                <a
                  onClick={()=>{handleProfileClick(row)}}
                >
                  {row.original.emp_id}
                </a>
              ) : (
                row.original.emp_id
              )
            ) : (
              "-"
            )}
          </div>
        ),
      },
      {
        accessor: "first_name",
        Header: () => <div style={{ width: "200px" }}>Employee Name</div>,
        Cell: ({ row }) => (
          directory?.edit_others?(
            <Link
            to={{
              pathname: `/profile/view`,
              state: { row: row.original },
            }}
            className="d-flex align-items-center"
            style={{ gap: "10px", textDecoration: "none", color: "inherit" }}
          >
            {row.original?.profile_photo ? (
              <img
                src={row.original?.profile_photo}
                alt="Employee"
                className="rounded-circle avatar-xs me-2"
              />
            ) : (
              <div className="avatar-xs align-self-center">
                <span className="avatar-title rounded-circle bg-primary bg-soft text-primary">
                  {sortName(
                    `${row.original?.first_name} ${
                      row.original?.last_name || ""
                    }`
                  )}
                </span>
              </div>
            )}
            <div>
              <p className="mb-0">{`${row.original.first_name} ${
                row.original.last_name ? row.original.last_name : ""
              }`}</p>
            </div>
          </Link>
          ):
          (
            <div
            className="d-flex align-items-center"
            style={{ gap: "10px", textDecoration: "none", color: "inherit" }}
          >
            {row.original?.profile_photo ? (
              <img
                src={row.original?.profile_photo}
                alt="Employee"
                className="rounded-circle avatar-xs me-2"
              />
            ) : (
              <div className="avatar-xs align-self-center">
                <span className="avatar-title rounded-circle bg-primary bg-soft text-primary">
                  {sortName(
                    `${row.original?.first_name} ${
                      row.original?.last_name || ""
                    }`
                  )}
                </span>
              </div>
            )}
            <div>
              <p className="mb-0">{`${row.original.first_name} ${
                row.original.last_name ? row.original.last_name : ""
              }`}</p>
            </div>
          </div>
          )
        ),
      },
      {
        accessor: "email",
        Header: () => <div style={{ width: "220px" }}>Email</div>,
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        accessor: "dob",
        Header: () => <div style={{ width: "220px" }}>Date of Birth</div>,
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        accessor: "date_of_joining",
        Header: () => <div style={{ width: "220px" }}>Date of Joining</div>,
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        accessor: "department_master_name",
        Header: () => <div style={{ width: "220px" }}>Department</div>,
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        accessor: "designation_master_name",
        Header: () => <div style={{ width: "120px" }}>Designation</div>,
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        accessor: "reporting_manager_first_name",
        Header: () => <div style={{ width: "200px" }}>Reporting Manager</div>,
        Cell: ({ row }) => {
          const { reporting_manager_first_name, reporting_manager_last_name } =
            row.original
          return (
            <div>
              <p className="mb-0">
                {reporting_manager_first_name}
                {reporting_manager_last_name &&
                  ` ${reporting_manager_last_name}`}
              </p>
            </div>
          )
        },
      },
      {
        accessor: "mobile",
        Header: () => <div style={{ width: "100px" }}>Phone</div>,
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        accessor: "status",
        Header: () => <div style={{ width: "100px" }}>Status</div>,
        Cell: ({ value, row }) => {
          const [isLoading, setIsLoading] = useState(false);  // Loading state for the API request
          const [status, setStatus] = useState(value);  // Set the initial state based on the cell value
      
          // Function to handle the toggle action
          const handleToggle = async () => {
            setIsLoading(true);  // Show loading indicator when the API is being called
            let employee_id=row.original.id;
            try {
              // Example API call (adjust URL and method as needed)
              const response = await updateUserStatus(
                employee_id,
                {
                status: status==2?1:2    // Send the new status
              });
      
              // If the request is successful, update the status state
              setStatus(status==2?1:2);
            } catch (error) {
              console.error("Error updating status:", error);
              // You can handle the error here (e.g., show a toast notification)
            } finally {
              setIsLoading(false);  // Remove loading indicator after the request
            }
          };
      
          return (
            <Button 
              color={status==1 ? "success" : "danger"}  // Toggle button color based on the status
              onClick={handleToggle}
              active={status==1?true:false}
              disabled={isLoading}  // Disable the button while loading
            >
              {isLoading ? <Spinner size="sm" /> : status==1 ? "On" : "Off"}
            </Button>
          );
        }
      },
    ]
  }, [dispatch, currentPage]);
  const { exportXlsx, xlsxLoader } = useExportXlsx();
  const { directory } = useSelector(state => state.users.userPermissions);
  const [includeInActive, setIncludeInActive] = useState(false);

  useEffect(() => {
    dispatch(
      fetchUsers({
        length: 10,
        start: currentPage,
        keyword: userSearchQuery.length > 0 ? userSearchQuery : undefined,
        status: includeInActive? "":"1"

      })
    )
  }, [currentPage, userSearchQuery, includeInActive])

  const { importXlxs } = useImportXlxs();
  const ImportCSV = async e => {
    e.preventDefault();
    if (file) {
      await importXlxs("Users", file);
      document.getElementById("file").value = "";
      fetchUsers({
        length: 10,
        start: currentPage,
        keyword: userSearchQuery.length > 0 ? userSearchQuery : undefined,
        status: includeInActive? "":"1"

      })
      setFile(null); // Ensure the state gets cleared
    } else {
      toastr.error("No file selected"); // Handle case where file is not present
    }
  };

  const handleFileChange = e => {
    setFile(e.target.files[0])
  }

  const updateList =()=>setIncludeInActive(!includeInActive)
  
  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
          <h5 className=" mb-3" >Directory</h5>
          <h6 className=" mb-3" style={{fontSize:'0.915625rem'}}>Number of Employees: {UserstotalData}</h6>
            
            <Card className="cardBorder">
              <CardBody style={{ paddingBottom: "0px" }}>
                <Row className=" align-items-start">
                  <Col md={6} sm={12} className="mb-2 d-flex justify-content-start gap-3 align-items-center flex-wrap">
                    <Search
                      onSearch={value => {
                        dispatch(setUserSearchQuery(value))
                        dispatch(setUsersCurrentPage(1))
                      }}
                    />
                      <div className="d-flex gap-2 align-items-center">
                        <input className="cursor-pointer" type="checkbox" name="inactiveEmployess" onChange={updateList} id="inactiveEmployess" />
                        <label className="mb-0 cursor-pointer" htmlFor="inactiveEmployess">Include Inactive Employees</label>
                      </div>
                  </Col>
                  {verifyUser && (
                    <Col
                      md={6}
                      sm={12}
                      className="mb-2 d-flex justify-content-end flex-wrap"
                    >
                      {directory?.export &&<div className="d-flex align-items-center mb-2">
                        <form onSubmit={ImportCSV}>
                          <div className="input-group">
                            <Input
                              type="file"
                              className="form-control"
                              name={"file"}
                              accept={".xlsx"}
                              id="file"
                              aria-describedby="file"
                              aria-label="Upload"
                              onChange={handleFileChange}
                            />
                            <Button
                              color="primary"
                              type="submit"
                              id="inputGroupFileAddon04"
                              disabled={!file}
                            >
                              Import
                            </Button>
                          </div>
                        </form>
                      </div>}
                      {directory?.export && <Button
                      className="bg-primary mx-2 mb-2 mb-md-0 px-2 d-flex align-items-center"
                      style={{ borderRadius: "5px", height: "38px" }}
                      onClick={() => exportXlsx("Users", "Directory", {status: includeInActive? "":"1"})}
                      disabled={xlsxLoader}
                      >
                      <i className="bx bx-export font-size-16 me-2" />
                      {xlsxLoader ? "Exporting..." : "Export"}
                      </Button>}
                      {directory?.add && <Link to="/user-boarding/add">
                        <Button className="bg-primary mx-2 mb-2 mb-md-0 px-2 d-flex align-items-center">
                          <i
                            className="dripicons-plus mx-2 d-flex"
                            style={{
                              border: "1px solid white",
                              borderRadius: "50%",
                            }}
                          />
                          Add
                        </Button>
                      </Link>}
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col
                    md={12}
                    className="overflow-auto"
                    style={{
                      height: "56vh",
                      scrollbarWidth: "thin",
                      padding: "0px",
                    }}
                  >
                    <GlobalTable
                      columns={columns}
                      data={usersData ? usersData : []}
                      loading={loading}
                    />
                  </Col>
                </Row>
              </CardBody>
              <CardFooter
                className="d-flex justify-content-end"
                style={{
                  background: "#ECECEC",
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                }}
              >
                <div
                  style={{
                    height: "30px",
                    marginLeft: "0px",
                  }}
                >
                  <Pagination
                    className="mx-2"
                    currentPage={currentPage}
                    totalCount={UserstotalData}
                    pageSize={10}
                    onPageChange={async page => {
                      dispatch(setUsersCurrentPage(page))
                    }}
                  />
                </div>
              </CardFooter>
            </Card>
          </div>

        </div>
      </div>
    </div>
  )
}

import React ,{useEffect} from "react"
import {
  Button,
  Col,
  Row,
  Label,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import toastr from "toastr"
import Select from "react-select"
import {
  createEmployeeAcccessory,
  editEmployeeAcccessory,
  fetchEmployeeAccessory,
  setOpenAccessoryModal,
  fetchEmployeeFormData,
  fetchUsersOfficeLocation,
  fetchAssetType,
} from "store/employeeAccessory/employeeAccessorySlice"
import "react-datepicker/dist/react-datepicker.css"; // Import the CSS for react-datepicker
import moment from "moment"
import { customStyles, status } from "constants/Constants"

export const EmployeeAccessoryForm = () => {
  const dispatch = useDispatch()

  const { openAccessoryModal,
          accessorySearchQuery,
         employeeAccessoryRowData,
         currentPage ,
         employeeFormData,
         officeLocationData,
         assetType}
  = useSelector(state => state.employeeAccessory )


  const validationSchema = Yup.object({
    employee_id:Yup.string().required("Required"),
    brand: Yup.string().required("Required"),
    model: Yup.string().required("Required"),
    asset_type_master_id: Yup.string().required("Required"),
    date: Yup.date().required("Required").typeError("Invalid date format")
    .test('format', 'Invalid date format. Use dd-MM-yyyy', (value) => {
      // Validate date format
      return value ? moment(value, 'DD-MM-YYYY', true).isValid() : false;
    }),
    office_location_id:Yup.string().required("Required"),
    status:Yup.string().required("Required"),

  })

  useEffect(() => {
    const fetchData =  async () => {
       await dispatch(fetchEmployeeFormData())
       await dispatch(fetchUsersOfficeLocation())
         dispatch(fetchAssetType())
    }
    fetchData()
  }, [dispatch])

  const toggle = async () => {
    await dispatch(setOpenAccessoryModal(false))
  }


  return (

    <Modal
    isOpen={openAccessoryModal}
    style={{ maxWidth: "400px" }}
    scrollable
    toggle={toggle}
    size="lg"
    centered>
      <ModalHeader toggle={toggle}>
        {employeeAccessoryRowData?.id ? "Edit" : "Add"} Employee Accessory
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xl="12">
            <Formik
              initialValues={{
                asset_type_master_id: employeeAccessoryRowData?.asset_type_master_id || "",
                brand: employeeAccessoryRowData?.brand || "",
                model: employeeAccessoryRowData?.model || "",
                employee_id: employeeAccessoryRowData?.employee_id || "",
                office_location_id: employeeAccessoryRowData?.office_location_id || "",
                date: employeeAccessoryRowData?.date || "",
                status: employeeAccessoryRowData?.status ? Number(employeeAccessoryRowData?.status) : "",
                remark: employeeAccessoryRowData?.remark || "",
                date_of_return: employeeAccessoryRowData?.date_of_return || "",
              }}
              validationSchema={validationSchema}
              onSubmit={async (values, { setSubmitting })=> {
                const formData = new FormData();
                  formData.append('asset_type_master_id', values.asset_type_master_id);
                  formData.append('brand', values.brand);
                  formData.append('model', values.model);
                  formData.append('employee_id', values.employee_id);
                  formData.append('office_location_id', values.office_location_id);
                  formData.append('date', values.date);
                  formData.append('status', values.status);
                  formData.append('remark', values.remark);
                  formData.append('date_of_return', values.date_of_return);

                const response = employeeAccessoryRowData?.id
                  ? await dispatch(
                      editEmployeeAcccessory({
                        id: employeeAccessoryRowData?.id,
                        data: formData,
                      })
                    ).unwrap()
                  : await dispatch(createEmployeeAcccessory(formData)).unwrap()
                  try {
                  if (response.data?.status) {
                    toastr.success(response?.data?.message || "Operation successful")
                  } else {
                    toastr.error(response?.data?.message || "Something went wrong")
                  }
                } catch (error) {
                  toastr.error("Something Went Wrong Please Try Later")
                } finally {
                  await dispatch(
                      fetchEmployeeAccessory({
                        length: 10,
                        start: currentPage,
                        keyword: accessorySearchQuery.length > 0 ? accessorySearchQuery : undefined,

                      })

                  )
                  await dispatch(setOpenAccessoryModal(false))
                }
                setSubmitting(false);
              }}
            >
              {({
                values,
                setFieldValue,
                touched,
                errors,
                isSubmitting,
                dirty,
              }) => (

                <Form>
                  <Row>
                  <Col md="12" className="mb-3">
                    <Label htmlFor="asset_type_master_id">
                    Asset Type
                      <span className="text-danger">*</span>
                    </Label>
                    <Select
                      name="Asset Type"
                      value={assetType?.find(
                      option => option.value == values.asset_type_master_id
                      )}
                      onChange={option => {
                      setFieldValue(
                      "asset_type_master_id",
                      Number(option.value)
                      )
                      }}
                      options={assetType}
                      />

                  <ErrorMessage
                    name="asset_type_master_name"
                    component="div"
                    className="text-danger"
                  />
                </Col>
                    <Col md="12" className="mb-3">
                    <Label htmlFor="brand">
                      Brand <span className="text-danger">*</span>
                      </Label>
                      <Field
                      name="brand"
                      placeholder="Brand"
                      type="text"
                      className={`form-control ${
                        touched.brand && errors.brand ? "is-invalid" : ""
                      }`}
                      />
                      <ErrorMessage
                      name="brand"
                      component="div"
                      className="text-danger"
                      />
                    </Col>
                    <Col md="12" className="mb-3">
                      <Label htmlFor="model">
                      Model No <span className="text-danger">*</span>
                      </Label>
                      <Field
                        name="model"
                        type="text"
                        placeholder="model"
                        className={`form-control ${
                          touched.model && errors.model ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        name="model"
                        component="div"
                        className="text-danger"
                      />
                    </Col>
                    <Col md="12" className="mb-3">
                      <Label htmlFor="remark">
                      Name of person  <span className="text-danger">*</span>
                      </Label>
                      <Select
                        name="employee_id"
                        value={employeeFormData.find(employee => employee.value == values.employee_id)}
                        onChange={option => setFieldValue("employee_id", option?.value || "")}
                        options={employeeFormData}
                        classNamePrefix="select2-selection"
                      />
                      <ErrorMessage
                        name="employee_id"
                        component="div"
                        className="text-danger"
                      />
                    </Col>
                    <Col md="12" className="mb-3">
                      <Label htmlFor="remark">
                      Location  <span className="text-danger">*</span>
                      </Label>
                      <Select
                        name="office_location_id"
                        value={officeLocationData?.find(
                        option => option.value == values?.office_location_id
                        )}
                        onChange={option => {
                        setFieldValue(
                        "office_location_id",
                        Number(option.value)
                        )
                        }}
                        options={officeLocationData}
                        classNamePrefix="select2-selection"
                        />
                      <ErrorMessage
                        name="office_location_id"
                        component="div"
                        className="text-danger"
                      />
                    </Col>

                    <Col md="12" className="mb-3">
                    <Label htmlFor="dob"> Date<span className="text-danger">*</span>
                    </Label>
                    <Field
                      name="date"
                      type="date"
                      className={`form-control ${
                        touched.date && errors.date
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    {touched.date && errors.date && (
                      <div className="text-danger">{errors.date}</div>
                    )}
                      </Col>
                      <Col md="12" className="mb-3">
                    <Label htmlFor="dob"> Date of Return
                    </Label>
                    <Field
                      name="date_of_return"
                      type="date"
                      min={values.date}
                      className={`form-control`}
                    />
                      </Col>
                      <Col md="12" className="mb-3">
                      <Label htmlFor="remark">
                      Status  <span className="text-danger">*</span>
                      </Label>
                      <Select
                        name="Status"
                        value={status?.find(
                        option => option.value === values?.status
                        )}
                        onChange={option => {
                        setFieldValue(
                        "status",
                        Number(option.value)
                        )
                        }}
                        options={status}
                        styles={customStyles}
                        className={`${
                          touched.type && errors.type ? "is-invalid" : ""
                        }`}
                        />
                      <ErrorMessage
                        name="status"
                        component="div"
                        className="text-danger"
                      />
                    </Col>
                    <Col md="12" className="mb-3">
                   <Label htmlFor="remark">
                     Remarks
                     </Label>
                     <Field
                     name="remark"
                     placeholder="Remark"
                     type="text"
                     className={`form-control ${
                       touched.remark && errors.remark ? "is-invalid" : ""
                     }`}
                     />
                     <ErrorMessage
                     name="remark"
                     component="div"
                     className="text-danger"
                     />
                   </Col>
                  </Row>
                  <div className="d-flex flex-wrap gap-2 justify-content-end">
                    <Button
                      color="primary"
                      type="submit"
                      disabled={isSubmitting || !dirty}
                    >

                      {employeeAccessoryRowData?.id ? (
                        <>{isSubmitting ? "Updating..." : "Update"}</>
                      ) : (
                        <>{isSubmitting ? "Submitting..." : "Submit"}</>
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import Pagination from "components/Common/Pagination";
import { get, put, post } from "helpers/api_helper";
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, Modal, Button,Input, Label, Form } from "reactstrap";
import classnames from "classnames"
import { LEADS_DETAIL, EDIT_LEAD_DATA, ADD_NOTES_DATA,ADD_MEETING_DATA,ADD_CALL_LOG_DATA,ADD_CALL_SCHEDULE_DATA,ADD_ATTACHMENT_DATA, GET_BUSINESS_CATEGORY, GET_SERVICES_MASTER,GET_ALL_MASTERS, GET_NOTES_DATA,GET_MEETING_DATA,GET_CALL_SCHEDULE_DATA,GET_CALL_LOG_DATA,GET_ATTACHMENT_DATA,GET_ES_TRANSACTION_DATA, DELETE_NOTES_DATA,DELETE_MEETING_DATA,DELETE_CALL_LOG_DATA,DELETE_CALL_SCHEDULE_DATA, DELETE_ATTACHMENT_DATA, EDIT_NOTES_DATA, EDIT_MEETING_DATA,EDIT_CALL_LOG_DATA,EDIT_CALL_SCHEDULE_DATA,GET_ES_DATA,ADD_ELEMENT_SHEET,EDIT_ELEMENT_SHEET,ES_TRANSACTION_IMPORT,ES_TRANSACTION_EXPORT,GET_EMPLOYEES_PERMISSION, GET_MEETING_LINK, GET_GOOGLE_LOCATION} from "helpers/api_url_helper";
import Select from "react-select";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import toastr from "toastr";
import '../../NewsTimeline.css';import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CreatableSelect from 'react-select/creatable';
import accessToken from "../../helpers/jwt-token-access/accessToken";
import axios from "axios"
import DOMPurify from 'dompurify';
import AddTransaction from "./add-transaction";
import EditTransaction from "./edit-transaction";
import { downloadEstransactionSampleCsv } from "../../constants/CommonFunction";

class LeadOverview extends Component {
    constructor(props) {
        super(props);
        
        const urlParams = new URLSearchParams(window.location.search);
        const initialTabId = urlParams.get('tabId') || '1';
        
        this.state = {
            status: false,
            is_edit: false,
            is_edit_es: false,
            isShowing: false,
            isEditShowing: false,
            submit: false,
            notesSubmit: false,
            meeting_type: null,
            meeting_link: '',
            meeting_location: '',
            meeting_purpose: '',
            meeting_with: '',
            meeting_start_date_time: '',
            meeting_end_date_time: '',
            meeting_conducted_by: '',
            meeting_participants: [],
            meeting_agenda: '',
            meetingSubmit: false,
            call_log_purpose: '',
            call_log_to: '',
            call_log_type: null,
            call_log_status: 'Completed',
            call_log_start_date_time: '',
            call_log_end_date_time: '',
            call_log_conducted_by: '',
            call_log_discussion: '',
            callLogSubmit: false,
            call_schedule_type: null,
            call_schedule_purpose: '',
            call_schedule_to: '',
            call_schedule_status: 'Scheduled',
            call_schedule_start_date_time: '',
            call_schedule_end_date_time: '',
            call_schedule_conducted_by: '',
            call_schedule_agenda: '',
            callScheduleSubmit: false,            
            attachmentSubmit: false,
            editnotesSubmit: false,
            editmeetingSubmit: false,
            editCallLogSubmit: false,
            editCallScheduleSubmit: false,
            addElementSheetSubmit: false,
            editElementSheetSubmit: false,
            customActiveTab: initialTabId,
            customActiveTab2: "1",
            data: [],
            client_location: "",
            state: "",
            notice_period: "",
            stage: "",
            source: "",
            brand: "",
            assigned_to: "",
            business_category: "",
            service_required: "",
            type_of_franchise: "",
            country_code: "",
            alternate_country_code: "",
            prefer_locations: "",
            location_master: [],
            notice_period_master: [],
            stage_master: [],
            source_master: [],
            brand_master: [],
            assigned_to_master: [],
            participant_master: [],
            business_category_master: [],
            service_required_master: [],
            data_load: true,
            current_ctc: "",
            expected_ctc: "",
            add_attachment: '',
            attachment_file_name: '',
            styleConfig: {
                counterStyle: {
                    height: '20px',
                    backgroundColor: '#F58220',
                    paddingLeft: '12px',
                    paddingRight: '12px',
                    color: '#FFF',
                    lineHeight: '20px',
                },
                starContainer: {
                    fontSize: '14px',
                    backgroundColor: '#F2F2F2',
                    height: '20px',
                },
                statusStyle: {
                    height: '20px',
                    backgroundColor: '#F58220',
                    paddingLeft: '12px',
                    paddingRight: '12px',
                    color: '#FFF',
                    lineHeight: '28px',
                    minWidth: '100px',
                    fontSize: '18px',
                    textAlign: 'left',
                },
                tooltipStyle: {
                    fontSize: '14px',
                    padding: '5px',
                }
            },
            showNotesModal: false,
            showAttachmentModal: false,
            showNotesModalEdit: false,
            editNote: {
                id: '',
                leads_id: '',
                edit_note: ''
            },
            editMeeting: {
                id: '',
                leads_id: '',
                type: null,
                link: '',
                location: '',
                purpose: '',
                with: '',
                start_date_time: '',
                end_date_time: '',
                conducted_by: '',
                participants: [],
                agenda: ''
            },
            editCallLog: {
                id: '',
                leads_id: '',
                type: null,
                purpose: '',
                to: '',
                start_date_time: '',
                end_date_time: '',
                conducted_by: '',
                discussion: ''
            },
            editCallSchedule: {
                id: '',
                leads_id: '',
                type: null,
                purpose: '',
                to: '',
                start_date_time: '',
                end_date_time: '',
                conducted_by: '',
                agenda: ''
            },
            showCallModal: false,
            showCallScheduleModal: false,
            showMeetingModal: false,
            showCallLogModalLog: false,
            showCallScheduleModalLog: false,
            showMeetingModalEdit: false,
            log_start_date: '',
            log_end_date: '',
            schedule_start_date: '',
            schedule_end_date: '',
            meetingType: null,
            formData: {
                title: "",
                link: "",
                date: "",
                description: ""
            }, 
            loginUserId: 0,
            notes: [],
            meetings: [],
            call_log: [],
            call_schedule: [],
            attachments: [],
            es_transactions: [],
            elementSheetLeadData: null,
            elementSheet: null,
            notes_currentPage: 1,
            meeting_currentPage: 1,
            call_log_currentPage: 1,
            call_schedule_currentPage: 1,
            attachment_currentPage: 1,
            es_transaction_currentPage: 1,
            notes_totalItems: 0,
            meeting_totalItems: 0,
            call_log_totalItems: 0,
            call_schedule_totalItems: 0,
            atachment_totalItems: 0,
            es_transaction_totalItems: 0,
            attachment_path_url: '',
            notes_PageSize: 5,
            meeting_PageSize: 5,
            call_log_PageSize: 5,
            call_schedule_PageSize: 5,
            attachment_PageSize: 10,
            es_transaction_PageSize: 10,
            add_es_event: '',
            add_es_date: '',
            add_es_venue: '',
            add_es_management_fees: '',
            add_es_total_of_elements: '',
            add_es_total_event_cost: '',
            add_es_less_advance_1: '',
            add_es_balance: '',
            add_es_client: '',
            edit_es_id: '',
            edit_es_event: '',
            edit_es_date: '',
            edit_es_venue: '',
            edit_es_management_fees: '',
            edit_es_total_of_elements: '',
            edit_es_total_event_cost: '',
            edit_es_less_advance_1: '',
            edit_es_balance: '',
            edit_es_client: '',
            showDesignation: false,
            showDateOfEvent: false,
            showBrideGroomName: false,
            showVenueOfEvent: false,
            showNoOfPeople: false,
            showNoOfFunctions: false,
            showNoOfDays: false,
            showWhatStarHotel: false,
            showCommunity: false,
            showArtist: false,
            showBudget: false,
            showServices: false,
            showFranchise: false,
            showJobBusinessType: false,
            showProfile: false,
        }
        this.toggleCustom = this.toggleCustom.bind(this)
        this.toggleCustom2 = this.toggleCustom2.bind(this)
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.notesvalidator = new SimpleReactValidator({ autoForceUpdate: this });
        this.meetingvalidator = new SimpleReactValidator({ autoForceUpdate: this });
        this.callLogvalidator = new SimpleReactValidator({ autoForceUpdate: this });
        this.callSchedulevalidator = new SimpleReactValidator({ autoForceUpdate: this });
        this.attachmentvalidator = new SimpleReactValidator({ autoForceUpdate: this });
        this.noteseditvalidator = new SimpleReactValidator({ autoForceUpdate: this });
        this.meetingeditvalidator = new SimpleReactValidator({ autoForceUpdate: this });
        this.callLogeditvalidator = new SimpleReactValidator({ autoForceUpdate: this });
        this.callScheduleEditvalidator = new SimpleReactValidator({ autoForceUpdate: this });
        this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
        this.handleEndTimeChange = this.handleEndTimeChange.bind(this);
        this.handleStartTimeChange1 = this.handleStartTimeChange1.bind(this);
        this.handleEndTimeChange1 = this.handleEndTimeChange1.bind(this);
        this.handleStartTimeChange2 = this.handleStartTimeChange2.bind(this);
        this.handleStartTimeChange4 = this.handleStartTimeChange4.bind(this);
        this.handleAttachment = this.handleAttachment.bind(this);
        this.handleInputChangecallLog = this.handleInputChangecallLog.bind(this);
        this.handleInputChangecallSchedule = this.handleInputChangecallSchedule.bind(this);
        this.handleSelectChangeCallSchedule = this.handleSelectChangeCallSchedule.bind(this);
        this.handleSelectChangeCallLog = this.handleSelectChangeCallLog.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleEditModal = this.toggleEditModal.bind(this);
        this.addElementSheetValidator = new SimpleReactValidator({ autoForceUpdate: this });
        this.editElementSheetValidator = new SimpleReactValidator({ autoForceUpdate: this });
        this.handleProfile = this.handleProfile.bind(this);
    }

    componentDidMount() {
        this.loadLead()
        this.loadMaster()
        this.fetchNotes();
        this.fetchMeeting();
        this.fetchAttachment();
        this.fetchCallLog();
        this.fetchScheduledLog();
        this.fetchElementSheet();
    }

    loadLead = () => {
        this.setState({ data_load: true });
        get(LEADS_DETAIL, { params: { lead_id: this.props.match.params.id } })
        .then(res => {
            if (res.status) {
                let loginUserId = res.user_id;
                this.setState({ loginUserId });
                let data = res.data;
                this.setState({ data });
                this.setState({ client_location: { value: data.client_location_id, label: data.client_location } });
                this.setState({ stage: { value: data.stage_id, label: data.stage } });
                this.setState({ source: { value: data.source_id, label: data.source } });
                this.setState({ brand: { value: data.brand_id, label: data.brand } }, this.loadBusinessCategory); // Call loadBusinessCategory after setting brand
                this.setState({ assigned_to: { value: data.assigned_to_id, label: data.assigned_to } });
                this.setState({ business_category: { value: data.business_category_id, label: data.business_category } });
                this.setState({ service_required: { value: data.service_required_id, label: data.service_required } });
                this.setState({ type_of_franchise: { value: data.type_of_franchise_id, label: data.type_of_franchise } });
                this.setState({ country_code: { value: data.country_code, label: data.country_code } });
                this.setState({ alternate_country_code: { value: data.alternate_country_code, label: data.alternate_country_code } });
                this.setState({
                    lead_name: data.name,
                    campaign_name: data.campaign_name,
                    meeting_with: data.name,
                    call_log_to: data.name,
                    call_schedule_to: data.name,
                    bride_groom_name: data.bride_groom_name,
                    venue_of_event: data.venue_of_event,
                    no_of_people: data.no_of_people,
                    no_of_functions: data.no_of_functions,
                    no_of_days: data.no_of_days,
                    what_star_hotel: data.what_star_hotel,
                    community: data.community,
                    type_of_artist_required: data.type_of_artist_required,
                    job_business_type: data.job_business_type,
                    profile_url: data.profile,
                    url_path: res.url,
                    budget: data.budget,
                    enquiry_date: data.enquiry_date,
                    date_of_event: data.date_of_event,
                    email: data.email,
                    mobile: data.mobile,
                    alternatemobile: data.alternate_mobile,
                    current_company: data.company_name,
                    state: data.state,
                    designation: data.designation,
                    created_by: data.created_by,
                    meeting_conducted_by: data.created_by,
                    call_log_conducted_by: data.created_by,
                    call_schedule_conducted_by: data.created_by,
                    created_at: data.created_at,
                    log: data.log,
                    created_by_id: data.created_by_id
                });
                 // Based on the selected brand, control which fields to show
                this.setState({ 
                    showDesignation: data.brand_id && data.brand_id === '6'|| data.brand_id === '3',
                    showDateOfEvent: data.brand_id && (data.brand_id === '1' || data.brand_id === '2'|| data.brand_id === '3'|| data.brand_id === '4'|| data.brand_id === '5'|| data.brand_id === '7'),
                    showBrideGroomName: data.brand_id && (data.brand_id === '1' || data.brand_id === '2' || data.brand_id === '5'),
                    showVenueOfEvent: data.brand_id && (data.brand_id === '1' || data.brand_id === '2'|| data.brand_id === '3'|| data.brand_id === '4'|| data.brand_id === '5'|| data.brand_id === '6' || data.brand_id === '7'),
                    showNoOfPeople: data.brand_id && (data.brand_id === '1' || data.brand_id === '2'|| data.brand_id === '3'|| data.brand_id === '4'|| data.brand_id === '5'|| data.brand_id === '7'),
                    showNoOfFunctions: data.brand_id && (data.brand_id === '1' || data.brand_id === '2' || data.brand_id === '4'|| data.brand_id === '5'),
                    showNoOfDays: data.brand_id && data.brand_id === '3',
                    showWhatStarHotel: data.brand_id && (data.brand_id === '1' || data.brand_id === '2' || data.brand_id === '4'|| data.brand_id === '5'),
                    showCommunity: data.brand_id && (data.brand_id === '1' || data.brand_id === '2' || data.brand_id === '4'|| data.brand_id === '5'),
                    showArtist: data.brand_id && data.brand_id === '5',
                    showBudget: data.brand_id &&  (data.brand_id === '1' || data.brand_id === '2' || data.brand_id === '3' || data.brand_id === '4'|| data.brand_id === '5'),
                    showServices: data.brand_id &&  (data.brand_id === '1' || data.brand_id === '2' || data.brand_id === '3' || data.brand_id === '4'|| data.brand_id === '6' || data.brand_id === '7'),
                    showFranchise: data.brand_id && (data.brand_id === '6' || data.brand_id === '7' ||  data.brand_id === '8'),
                    showJobBusinessType: data.brand_id && data.brand_id === '9',
                    showProfile: data.brand_id && data.brand_id === '9',
                });
            }
            this.setState({ data_load: false });
        })
        .catch(err => {
            toastr.error(err);
            this.setState({ data_load: false });
            window.location.href = '/leads';
        });
    }
    
    handleEditMeetingTypeChange = (selectedOption) => {
        this.setState(prevState => ({
            editMeeting: {
                ...prevState.editMeeting,
                type: selectedOption
            }
        }));
    };
    
    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            editMeeting: {
                ...prevState.editMeeting,
                [name]: value
            }
        }));
    };
    
    handleInputChangecallSchedule(event) {
        const { name, value } = event.target;
        this.setState(prevState => ({
            editCallSchedule: {
                ...prevState.editCallSchedule,
                [name]: value
            }
        }));
    }
    
    handleSelectChangeCallSchedule(selectedOption) {
        this.setState(prevState => ({
            editCallSchedule: {
                ...prevState.editCallSchedule,
                type: selectedOption
            }
        }));
    }
    
    handleInputChangecallLog(event) {
        const { name, value } = event.target;
        this.setState(prevState => ({
            editCallLog: {
                ...prevState.editCallLog,
                [name]: value
            }
        }));
    }
    
    handleSelectChangeCallLog(selectedOption) {
        this.setState(prevState => ({
            editCallLog: {
                ...prevState.editCallLog,
                type: selectedOption
            }
        }));
    }
    
    handleStartTimeChange2 = (event) => {
        const { value } = event.target;
        this.setState(prevState => ({
            editMeeting: {
                ...prevState.editMeeting,
                start_date_time: value
            }
        }));
    };
    
    handleStartTimeChange4 = (event) => {
        const { value } = event.target;
        this.setState(prevState => ({
            editCallSchedule: {
                ...prevState.editCallSchedule,
                start_date_time: value
            }
        }));
    };

    handleInput = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        if(e.target.name==="meeting_location" && e.target.value){
            this.getGoogleLocationApi(e.target.value);
          }
          console.log(e)
    };
    handleLocationSet = (e) => {
        const { google_location_list, highlightedIndex } = this.state;
        let newIndex = highlightedIndex;
    
        // Prevent default behavior for Space key to avoid unwanted scrolling
        if (e.key === " " || e.code === "Space") {
            e.preventDefault();
        }
    
        if (e.target.value !== "" && e.key !== " ") {
            this.getGoogleLocationApi(e.target.value);
        }
    
        if (google_location_list.length > 0) {
            if (e.key === "ArrowDown") {
                // Move down in the list, wrap around to the first item if at the end
                newIndex = (highlightedIndex === -1 || highlightedIndex === google_location_list.length - 1)
                    ? 0
                    : highlightedIndex + 1;
    
                this.setState({
                    highlightedIndex: newIndex,
                    meeting_location: google_location_list[newIndex],
                });
            } else if (e.key === "ArrowUp") {
                // Move up in the list, wrap around to the last item if at the beginning
                newIndex = (highlightedIndex === -1 || highlightedIndex === 0)
                    ? google_location_list.length - 1
                    : highlightedIndex - 1;
    
                this.setState({
                    highlightedIndex: newIndex,
                    meeting_location: google_location_list[newIndex],
                });
            } else if (e.key === "Enter" || e.code === "Space") {
                // On Enter or Space, close the list
                this.setState({ 
                    google_location_list: [], 
                    highlightedIndex: -1 
                });
            }
        }
    };
    handleAttachment = (e) => {
        let add_attachment = e.target.files[0];
        this.setState({ add_attachment: add_attachment });
    }    

    loadMaster = () => {
        get(GET_ALL_MASTERS, { params: { masters: 'location,notice_period,stage_master,lead_source_master,brand_master,employees_email' } }).then(res => {
            if (res.status) {
                let data = res.data;
                this.setState({ location_master: data.location, notice_period_master: data.notice_period, stage_master: data.stage_master , source_master: data.lead_source_master, brand_master: data.brand_master, participant_master: data.employees_email })
            }
        }).catch(err => {
            toastr.error(err)
        })
        
        get(GET_EMPLOYEES_PERMISSION, { params: { status: 1 } }).then(res => {
            if (res.status) {
              let data = res.data;
              this.setState({ assigned_to_master: data })
            }
          }).catch(err => {
            toastr.error(err)
          })
    }

    loadBusinessCategory = () => {
        const { brand } = this.state; // Destructure brand from state
        const brand_id = brand.value; // Directly assign brand.value to brand_id
        get(GET_BUSINESS_CATEGORY, { params: { id: brand_id } })
        .then((response) => {
          if (response.status) {
            this.setState({ business_category_master: response.data });
          } else {
            this.setState({ business_category_master: [] });
            toastr.error(response.message || "No business categories found.");
          }
        })
        .catch((error) => {
          console.error("There was an error fetching the business categories!", error);
        //   toastr.error("Error fetching business categories.");
        });
        get(GET_SERVICES_MASTER, { params: { id: brand_id } })
        .then((response) => {
          if (response.status) {
            this.setState({ service_required_master: response.data });
          } else {
            this.setState({ service_required_master: [] });
          }
        })
        .catch((error) => {
          console.error("There was an error fetching the service!", error);
        //   toastr.error("Error fetching service.");
        });
    } 
    
    handleBrandChange = (selectedBrand) => {
        this.setState({ brand: selectedBrand, business_category: null, service_required: null }, () => {
          // Revalidate the brand field
          this.validator.showMessageFor("brand");
        });
    
        if (selectedBrand) {
          get(GET_BUSINESS_CATEGORY, { params: { id: selectedBrand.value } })
            .then((response) => {
              console.log(response);
              if (response.status) {
                this.setState({ business_category_master: response.data });
              } else {
                this.setState({ business_category_master: [] });
                toastr.error(response.message || "No business categories found.");
              }
            })
            .catch((error) => {
              console.error("There was an error fetching the business categories!", error);
              toastr.error("Error fetching business categories.");
            });
        } else {
          this.setState({ business_category_master: [] });
        }
        
        if (selectedBrand) {
            get(GET_SERVICES_MASTER, { params: { id: selectedBrand.value } })
              .then((response) => {
                console.log(response);
                if (response.status) {
                  this.setState({ service_required_master: response.data });
                } else {
                  this.setState({ service_required_master: [] });
                }
              })
              .catch((error) => {
                console.error("There was an error fetching the service", error);
                toastr.error("Error fetching service.");
              });
          } else {
            this.setState({ service_required_master: [] });
        }
        
         // Based on the selected brand, control which fields to show
        this.setState({ 
            showDesignation: selectedBrand && selectedBrand.value === '6'|| selectedBrand.value === '3',
            showDateOfEvent: selectedBrand && (selectedBrand.value === '1' || selectedBrand.value === '2'|| selectedBrand.value === '3'|| selectedBrand.value === '4'|| selectedBrand.value === '5'|| selectedBrand.value === '7'),
            showBrideGroomName: selectedBrand && (selectedBrand.value === '1' || selectedBrand.value === '2' || selectedBrand.value === '5'),
            showVenueOfEvent: selectedBrand && (selectedBrand.value === '1' || selectedBrand.value === '2'|| selectedBrand.value === '3'|| selectedBrand.value === '4'|| selectedBrand.value === '5'|| selectedBrand.value === '6' || selectedBrand.value === '7'),
            showNoOfPeople: selectedBrand && (selectedBrand.value === '1' || selectedBrand.value === '2'|| selectedBrand.value === '3'|| selectedBrand.value === '4'|| selectedBrand.value === '5'|| selectedBrand.value === '7'),
            showNoOfFunctions: selectedBrand && (selectedBrand.value === '1' || selectedBrand.value === '2' || selectedBrand.value === '4'|| selectedBrand.value === '5'),
            showNoOfDays: selectedBrand && selectedBrand.value === '3',
            showWhatStarHotel: selectedBrand && (selectedBrand.value === '1' || selectedBrand.value === '2' || selectedBrand.value === '4'|| selectedBrand.value === '5'),
            showCommunity: selectedBrand && (selectedBrand.value === '1' || selectedBrand.value === '2' || selectedBrand.value === '4'|| selectedBrand.value === '5'),
            showArtist: selectedBrand && selectedBrand.value === '5',
            showBudget: selectedBrand &&  (selectedBrand.value === '1' || selectedBrand.value === '2' || selectedBrand.value === '3' || selectedBrand.value === '4'|| selectedBrand.value === '5'),
            showServices: selectedBrand &&  (selectedBrand.value === '1' || selectedBrand.value === '2' || selectedBrand.value === '3' || selectedBrand.value === '4'|| selectedBrand.value === '6' || selectedBrand.value === '7'),
            showFranchise: selectedBrand && (selectedBrand.value === '6' || selectedBrand.value === '7' ||  selectedBrand.value === '8'),
            showJobBusinessType: selectedBrand && selectedBrand.value === '9',
            showProfile: selectedBrand && selectedBrand.value === '9',
        });
    };
    
    handleProfile = (e) => {
        let profile = e.target.files[0];
        this.setState({ profile: profile });
    } 

    toggleCustom(tab) {
        if (this.state.customActiveTab !== tab) {
            this.setState({
                customActiveTab: tab,
            })
        }
    }
    
    toggleCustom2(tab) {
        if (this.state.customActiveTab2 !== tab) {
            this.setState({
                customActiveTab2: tab,
            })
        }
    }
    
    editFormHide() {
        if (this.validator.allValid()) {
            this.setState({ is_edit: false })
            this.loadLead()
        }else{
            this.validator.showMessages();            
        }
    }

    selectMeetingLocation = (location) => {
        this.setState({
          meeting_location: location,
          google_location_list: []
        });
      };
      
      clearLocation = () => {
        const { meeting_location, google_location_list } = this.state;
      
        // Check if the current meeting_location exists in the google_location_list
        const locationExistsInList = google_location_list.includes(meeting_location) || true;
      
        // Clear the meeting_location only if it does not exist in the list
        if (!locationExistsInList) {
          this.setState({ meeting_location: '', google_location_list: [] });
        }else{
          this.setState({ google_location_list: [] });
        }
      };
      
      
      handleLocationBlur = (e) => {
        if (!e.relatedTarget || !e.relatedTarget.classList.contains("google_location_option")) {
          this.clearLocation();
        }
      };
      
      getGoogleLocationApi = async (keyword) =>{
        const formData = new FormData();
          formData.append("location", keyword);
          axios({
            method: "post",
            url: `${process.env.REACT_APP_APIURL}${GET_GOOGLE_LOCATION}`,
            data: formData,
            headers: {
              "Content-Type": "application/json",
              Authorization: accessToken,
            },
          })
            .then((response) => {
              if (response.status) {
                this.setState({
                  google_location_list: response?.data?.data
                });
              }
            })
            .catch((err) => {
              toastr.error(err.message);
              console.error(err.message);
            });
          
      }
      generateMeetingLink = async () => {
        try {
          const response = await axios.post(`${process.env.REACT_APP_APIURL}${GET_MEETING_LINK}`, {}, {
            headers: {
              Authorization: accessToken,
            }
          });
          
          if (response.status) {
            this.setState({ meeting_link: 'response.data.link' });
          } else {
            toastr.error('Failed to generate meeting link');
          }
        } catch (error) {
          toastr.error('Error generating meeting link');
          console.error(error);
        }
      };

    handleFormSubmit = (e) => {
        e.preventDefault();
        if (this.validator.allValid()) { 
            let formData = new FormData();
    
            // Append all your form fields to FormData
            formData.append('id', this.props.match.params.id);
            formData.append('lead_name', this.state.lead_name || '');
            formData.append('stage', this.state.stage?.value || '');
            formData.append('source', this.state.source?.value || '');
            formData.append('brand', this.state.brand?.value || '');
            formData.append('business_category', this.state.business_category?.value || '');
            formData.append('email', this.state.email || '');
            formData.append('country_code', this.state.country_code?.value || '');
            formData.append('mobile', this.state.mobile || '');
            formData.append('alternate_country_code', this.state.alternate_country_code?.value || '');
            formData.append('alternatemobile', this.state.alternatemobile || '');
            formData.append('current_company', this.state.current_company || '');
            formData.append('designation', this.state.designation || '');
            formData.append('enquiry_date', this.state.enquiry_date === '0000-00-00' ? '' : this.state.enquiry_date);
            formData.append('date_of_event', this.state.date_of_event === '0000-00-00' ? '' : this.state.date_of_event);
            formData.append('bride_groom_name', this.state.bride_groom_name || '');
            formData.append('venue_of_event', this.state.venue_of_event || '');
            formData.append('client_location', this.state.client_location?.value || '');
            formData.append('no_of_people', this.state.no_of_people || '');
            formData.append('no_of_days', this.state.no_of_days || '');
            formData.append('what_star_hotel', this.state.what_star_hotel || '');
            formData.append('community', this.state.community || '');
            formData.append('type_of_artist_required', this.state.type_of_artist_required || '');
            formData.append('job_business_type', this.state.job_business_type || '');
            
            if (this.state.profile) {
                formData.append('profile', this.state.profile);
            }
    
            formData.append('budget', this.state.budget || '');
            formData.append('no_of_functions', this.state.no_of_functions || '');
            formData.append('service_required', this.state.service_required?.value || '');
            formData.append('type_of_franchise', this.state.type_of_franchise?.value || '');
            formData.append('assigned_to', this.state.assigned_to?.value || '');
    
            this.setState({ submit: true });
    
            // Make the API call
            axios({
                method: "post",
                url: `${process.env.REACT_APP_APIURL}${EDIT_LEAD_DATA}`,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": accessToken,
                }
            })
            .then(res => {
                if (res.status === 201) {
                    this.setState({ submit: false, is_edit: false });
                    toastr.success('Lead updated successfully.');
                    this.loadLead();
                    this.fetchElementSheet();
                    this.fetchMeeting();
                    this.fetchCallLog();
                    this.fetchScheduledLog();
                } else {
                    // Handle unexpected success response codes
                    this.setState({ submit: false });
                    toastr.error('Unexpected response from server. Please try again.');
                }
            })
            .catch(err => {
                this.setState({ submit: false });
                if (err.response && err.response.data && err.response.data.message) {
                    toastr.error(err.response.data.message); // Display the error message from the response
                } else {
                    toastr.error('An error occurred. Please try again.');
                }
            });
        } else {
            this.setState({ submit: false });
            this.validator.showMessages();
            this.forceUpdate();
        }
    }    
    
    handleFormAddESSubmit = (e) => {
        e.preventDefault();
        if (this.addElementSheetValidator.allValid()) {
            let formData = {
                leads_id: this.props.match.params.id,
                event: this.state.add_es_event,
                date: this.state.add_es_date,
                venue: this.state.add_es_venue,
                management_fees: this.state.add_es_management_fees,
                total_elements: this.state.add_es_total_of_elements,
                total_event_cost: this.state.add_es_total_event_cost,
                advance: this.state.add_es_less_advance_1,
                balance: this.state.add_es_balance,
            }
            this.setState({addElementSheetSubmit : true})
            post(ADD_ELEMENT_SHEET, formData).then(res => {
                if (res.status) {
                    this.setState({addElementSheetSubmit : false})
                    toastr.success('Element Sheet added successfully')
                    this.loadLead()
                    this.fetchElementSheet()
                }
            }).catch(err => {
                this.setState({addElementSheetSubmit : false})
                toastr.error(err)
            })
        } else {
            this.setState({addElementSheetSubmit : false})
            this.addElementSheetValidator.showMessages();
            this.forceUpdate();
        }

    }
    
    handleFormEditESSubmit = (e) => {
        e.preventDefault();
        if (this.editElementSheetValidator.allValid()) {
            let formData = {
                id: this.state.edit_es_id,
                leads_id: this.props.match.params.id,
                event: this.state.edit_es_event,
                date: this.state.edit_es_date,
                venue: this.state.edit_es_venue,
                management_fees: this.state.edit_es_management_fees,
                total_elements: this.state.edit_es_total_of_elements,
                total_event_cost: this.state.edit_es_total_event_cost,
                advance: this.state.edit_es_less_advance_1,
                balance: this.state.edit_es_balance,
            }
            this.setState({editElementSheetSubmit : true})
            put(EDIT_ELEMENT_SHEET, formData).then(res => {
                if (res.status) {
                    this.setState({editElementSheetSubmit : false})
                    this.setState({ is_edit_es: false })
                    toastr.success('Element Sheet updated successfully')
                    this.loadLead()
                    this.fetchElementSheet()
                }
            }).catch(err => {
                this.setState({editElementSheetSubmit : false})
                toastr.error(err)
            })
        } else {
            this.setState({editElementSheetSubmit : false})
            this.editElementSheetValidator.showMessages();
            this.forceUpdate();
        }

    }
      
    convertDateFormat = (dateString) => {
        const date = new Date(dateString);
    
        // Get day
        const day = date.getDate().toString().padStart(2, '0');
    
        // Get month and convert to full month name
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const month = monthNames[date.getMonth()];
    
        // Get year
        const year = date.getFullYear();
    
        return `${day} ${month} ${year}`;
    };
    
    convertDateTimeFormat = (dateTimeString) => {
        const date = new Date(dateTimeString);
    
        // Get day
        const day = date.getDate().toString().padStart(2, '0');
    
        // Get month and convert to full month name
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const month = monthNames[date.getMonth()];
    
        // Get year
        const year = date.getFullYear();
    
        // Get hours and convert to 12-hour format
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
    
        // Format hours and minutes
        const time = `${hours.toString().padStart(2, '0')}:${minutes} ${ampm}`;
    
        return `${day} ${month} ${year} | ${time}`;
    };
    
    convertDateTimeFormat2 = (dateTimeString) => {
        const date = new Date(dateTimeString);
    
        // Get day
        const day = date.getDate().toString().padStart(2, '0');
    
        // Get month and convert to full month name
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const month = monthNames[date.getMonth()];
    
        // Get year
        const year = date.getFullYear();
    
        // Get hours and convert to 12-hour format
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
    
        // Format hours and minutes
        const time = `${hours.toString().padStart(2, '0')}:${minutes} ${ampm}`;
    
        return `${month} ${day}, ${year} - ${time}`;
    };
    
    // Function to get today's date in YYYY-MM-DD format
    pastDate = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
    };
    
    pastDateTime = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    
    return `${year}-${month}-${day} ${hours}:${minutes}`;
    };
      
      
    toggleNotesModal = () => {
        this.setState({ showNotesModal: !this.state.showNotesModal });
    }
    
    toggleAttachmentModal = () => {
        this.setState({ showAttachmentModal: !this.state.showAttachmentModal });
    }
    
    toggleCallModal = () => {
        this.setState({ showCallModal: !this.state.showCallModal });
    }
    
    toggleCallScheduleModal = () => {
        this.setState({ showCallScheduleModal: !this.state.showCallScheduleModal });
    }
    
    toggleMeetingModal = () => {
        this.setState({ 
            showMeetingModal: !this.state.showMeetingModal,
            meeting_link:"",
            meeting_location:"",
            meetingType:null
         });
    }    
    
    toggleCallLogModalEdit = (log) => {
        this.setState({ showCallLogModalLog: !this.state.showCallLogModalLog });
        this.setState({
            // showCallLogModalLog: !this.state.showCallLogModalLog,
            editCallLog: log ? {
                id: log.id,
                leads_id: log.leads_id,
                type: log.type_name ? { label: log.type_name, value: log.type } : null,
                purpose: log.purpose,
                to: log.call_to,
                start_date_time: log.start_date_time,
                end_date_time: log.end_date_time,
                conducted_by: log.created_by,
                discussion: log.discussion
                
            } : { 
                id: '',
                leads_id: '',
                type: null,
                purpose: '',
                to: '',
                start_date_time: '',
                end_date_time: '',
                conducted_by: '',
                discussion: ''
            }
        });
    };
    
    toggleCallScheduleModalEdit = (schedule) => {
        this.setState({ showCallScheduleModalLog: !this.state.showCallScheduleModalLog });
        this.setState({
            // showCallScheduleModalLog: !this.state.showCallScheduleModalLog,
            editCallSchedule: schedule ? {
                id: schedule.id,
                leads_id: schedule.leads_id,
                type: schedule.type_name ? { label: schedule.type_name, value: schedule.type } : null,
                purpose: schedule.purpose,
                to: schedule.call_to,
                start_date_time: schedule.start_date_time,
                end_date_time: schedule.end_date_time,
                conducted_by: schedule.created_by,
                agenda: schedule.discussion
                
            } : { 
                id: '',
                leads_id: '',
                type: null,
                purpose: '',
                to: '',
                start_date_time: '',
                end_date_time: '',
                conducted_by: '',
                agenda: ''
            }
        });
    }
    
    handleStartTimeChange(event) {
        const log_start_date = event.target.value;
        this.setState({ log_start_date });
    
        // Set the min attribute of the end time input dynamically
        const log_end_time_input = document.getElementsByName('log_end_time')[0];
        log_end_time_input.min = log_start_date;
    }

    handleEndTimeChange(event) {
    this.setState({ log_end_date: event.target.value });
    }
    
    handleStartTimeChange1(event) {
        const schedule_start_date = event.target.value;
        this.setState({ schedule_start_date });
    
        // Set the min attribute of the end time input dynamically
        const log_end_time_input1 = document.getElementsByName('schedule_end_date')[0];
        log_end_time_input1.min = schedule_start_date;
    }
    
    handleEndTimeChange1(event) {
        this.setState({ schedule_end_date: event.target.value });
    }
    
    handleStartTimeChange2(event) {
        const meeting_start_date_time = event.target.value;
    
        // Set the min attribute of the end time input dynamically
        const meeting_end_date_time_input = document.getElementsByName('meeting_end_date_time')[0];
        meeting_end_date_time_input.min = meeting_start_date_time;
    }
    
    handleStartTimeChange4(event) {
        const call_schedule_start_date_time = event.target.value;
    
        // Set the min attribute of the end time input dynamically
        const call_schedule_end_date_time_input = document.getElementsByName('call_schedule_end_date_time')[0];
        call_schedule_end_date_time_input.min = call_schedule_start_date_time;
    }
    
    handleMeetingTypeChange = (selectedOption) => {
        this.setState({ meetingType: selectedOption });
    };

    handleParticipantsChange = (newValue) => {
        this.setState({ meetingParticipants: newValue });
    };
    
    handleNotesFormSubmit = (e) => {
        e.preventDefault();
        if (this.notesvalidator.allValid()) {
            let formData = {
                lead_id: this.props.match.params.id,
                add_note: this.state.add_note
            }
            this.setState({notesSubmit : true})
            post(ADD_NOTES_DATA, formData).then(res => {
                if (res.status) {
                    this.setState({notesSubmit : false})
                    toastr.success('Note added successfully.')
                    this.toggleNotesModal();
                    this.fetchNotes();
                    this.loadLead();
                }
            }).catch(err => {
                this.setState({notesSubmit : false})
                toastr.error(err)
            })
        } else {
            this.setState({notesSubmit : false})
            this.notesvalidator.showMessages();
            this.forceUpdate();
        }
    }
    
    handleEditParticipantsChange = (newValue) => {
        this.setState(prevState => ({
            editMeeting: {
                ...prevState.editMeeting,
                participants: newValue
            }
        }));
    };
    
    handleCallLogFormSubmit = (e) => {
        e.preventDefault();
        if (this.callLogvalidator.allValid()) {
            const {
                call_log_purpose, call_log_to,call_log_type,call_log_status,
                call_log_start_date_time,
                call_log_end_date_time, call_log_conducted_by,
                call_log_discussion
            } = this.state;

            let formData = {
                lead_id: this.props.match.params.id,
                call_log_type: call_log_type?.value,
                call_log_purpose,
                call_log_to,
                call_log_status,
                call_log_start_date_time,
                call_log_end_date_time,
                call_log_conducted_by,
                call_log_discussion
            };

            this.setState({ callLogSubmit: true });
            post(ADD_CALL_LOG_DATA, formData).then(res => {
                if (res.status) {
                    toastr.success('Call Log added successfully.');
                    this.toggleCallModal();
                    this.fetchCallLog();
                    this.loadLead();
                    this.setState({
                        callLogSubmit: false,
                        call_log_purpose: '',
                        call_log_to: '',
                        call_log_type: null,
                        call_log_status: 'Completed',
                        call_log_start_date_time: '',
                        call_log_end_date_time: '',
                        call_log_conducted_by: '',
                        call_log_discussion: ''
                    });
                }
            }).catch(err => {
                this.setState({ callLogSubmit: false });
                // toastr.error(err);
                toastr.error(err.response.data.message);
            });
        } else {
            this.setState({ callLogSubmit: false });
            this.callLogvalidator.showMessages();
            this.forceUpdate();
        }
    };
    
    fetchCallLog = () => {
        const {call_log_currentPage, call_log_PageSize } = this.state;
        const lead_id = this.props.match.params.id;
        const params = {
            length: call_log_PageSize,
            start: call_log_currentPage,
            lead_id: lead_id
        };

        post(GET_CALL_LOG_DATA, params).then(res => {
            this.setState({
                call_log: res.data,
                call_log_totalItems: res.total
            });
        }).catch(err => {
            toastr.error(err.message || 'Failed to fetch notes.');
        });
    }
    
    handleCallScheduleFormSubmit = (e) => {
        e.preventDefault();
        if (this.callSchedulevalidator.allValid()) {
            const {
                call_schedule_type,call_schedule_purpose,call_schedule_to,call_schedule_status,call_schedule_start_date_time,call_schedule_end_date_time,call_schedule_conducted_by,call_schedule_agenda
            } = this.state;

            let formData = {
                lead_id: this.props.match.params.id,
                call_schedule_type: call_schedule_type?.value,
                call_schedule_purpose,
                call_schedule_to,
                call_schedule_status,
                call_schedule_start_date_time,
                call_schedule_end_date_time,
                call_schedule_conducted_by,
                call_schedule_agenda
            };

            this.setState({ callScheduleSubmit: true });
            post(ADD_CALL_SCHEDULE_DATA, formData).then(res => {
                if (res.status) {
                    toastr.success('Call Scheduled successfully.');
                    this.toggleCallScheduleModal();
                    this.fetchScheduledLog();
                    this.loadLead();
                    this.setState({
                        callScheduleSubmit: false,
                        call_schedule_type: null,
                        call_schedule_purpose: '',
                        call_schedule_to: '',
                        call_schedule_status: 'Scheduled',
                        call_schedule_start_date_time: '',
                        call_schedule_end_date_time: '',
                        call_schedule_conducted_by: '',
                        call_schedule_agenda: ''
                    });
                }
            }).catch(err => {
                this.setState({ callScheduleSubmit: false });
                // toastr.error(err);
                toastr.error(err.response.data.message);
            });
        } else {
            this.setState({ callScheduleSubmit: false });
            this.callSchedulevalidator.showMessages();
            this.forceUpdate();
        }
    };
    
    fetchScheduledLog = () => {
        const { call_schedule_currentPage, call_schedule_PageSize } = this.state;
        const lead_id = this.props.match.params.id;
        const params = {
            length: call_schedule_PageSize,
            start: call_schedule_currentPage,
            lead_id: lead_id
        };

        post(GET_CALL_SCHEDULE_DATA, params).then(res => {
            this.setState({
                call_schedule: res.data,
                call_schedule_totalItems: res.total
            });
        }).catch(err => {
            toastr.error(err.message || 'Failed to fetch notes.');
        });
    }
    
    handleMeetingFormSubmit = (e) => {
        e.preventDefault();
        if (this.meetingvalidator.allValid()) {
            const {
                meeting_type, meeting_link, meeting_location,
                meeting_purpose, meeting_with, meeting_start_date_time,
                meeting_end_date_time, meeting_conducted_by, meeting_participants,
                meeting_agenda
            } = this.state;

            let formData = {
                lead_id: this.props.match.params.id,
                meeting_type: meeting_type?.value,
                meeting_link,
                meeting_location,
                meeting_purpose,
                meeting_with,
                meeting_start_date_time,
                meeting_end_date_time,
                meeting_conducted_by,
                meeting_participants: JSON.stringify(meeting_participants),
                meeting_agenda
            };

            this.setState({ meetingSubmit: true });
            post(ADD_MEETING_DATA, formData).then(res => {
                if (res.status) {
                    toastr.success('Meeting added successfully.');
                    this.toggleMeetingModal();
                    this.fetchMeeting();
                    this.loadLead();
                    this.setState({
                        meetingSubmit: false,
                        meeting_type: null,
                        meeting_link: '',
                        meeting_location: '',
                        meeting_purpose: '',
                        meeting_with: '',
                        meeting_start_date_time: '',
                        meeting_end_date_time: '',
                        meeting_conducted_by: '',
                        meeting_participants: [],
                        meeting_agenda: '', 
                        google_location_list:[],
                    });
                }
            }).catch(err => {
                this.setState({ meetingSubmit: false });
                // toastr.error(err);
                toastr.error(err.response.data.message);
            });
        } else {
            this.setState({ meetingSubmit: false });
            this.meetingvalidator.showMessages();
            this.forceUpdate();
        }
    };
    
    handleAttachmentFormSubmit = (e) => {
        e.preventDefault();
        if (this.attachmentvalidator.allValid()) {
          const formData = new FormData()
          formData.append('lead_id', this.props.match.params.id);
          formData.append('add_attachment', this.state.add_attachment);
          formData.append('attachment_file_name', this.state.attachment_file_name);
    
          this.setState({ submit: true });
          axios({
            method: "post", url: `${process.env.REACT_APP_APIURL}${ADD_ATTACHMENT_DATA}`, data: formData, headers: {
              'Content-Type': 'application/json',
              "Authorization": accessToken,
            }
          }).then(response => {
            if (response.status) {
                this.setState({attachmentSubmit : false})
                toastr.success('Attachment added successfully.')
                this.toggleAttachmentModal();
                this.fetchAttachment();
                this.loadLead();
            }
          }).catch(err => {
            this.setState({attachmentSubmit : false})
            toastr.error(err)
          })
        } else {
            this.setState({attachmentSubmit : false})
            this.attachmentvalidator.showMessages();
            this.forceUpdate();
        }
    };
    
    handleNoteDelete = (note_id, lead_id) => {
        let formData = {
            "note_id": note_id,
            "lead_id": lead_id,
        }
        put(DELETE_NOTES_DATA, formData).then(response => {
            if (response.status) {
                toastr.success('Notes delete successfully.')
                this.fetchNotes();
                this.loadLead();
            }
        }).catch(err => {
            toastr.error(err.message)
        })
    }
    
    handleMeetingDelete = (meeting_id, lead_id) => {
        let formData = {
            "meeting_id": meeting_id,
            "lead_id": lead_id,
        }
        put(DELETE_MEETING_DATA, formData).then(response => {
            if (response.status) {
                toastr.success('Meeting delete successfully.')
                this.fetchMeeting();
                this.loadLead();
            }
        }).catch(err => {
            toastr.error(err.message)
        })
    }
    
    handleCallLogDelete = (call_id, lead_id) => {
        let formData = {
            "call_id": call_id,
            "lead_id": lead_id,
        }
        put(DELETE_CALL_LOG_DATA, formData).then(response => {
            if (response.status) {
                toastr.success('call delete successfully.')
                this.fetchCallLog();
                this.loadLead();
            }
        }).catch(err => {
            toastr.error(err.message)
        })
    }
    
    handleCallScheduleDelete = (call_id, lead_id) => {
        let formData = {
            "call_id": call_id,
            "lead_id": lead_id,
        }
        put(DELETE_CALL_SCHEDULE_DATA, formData).then(response => {
            if (response.status) {
                toastr.success('call delete successfully.')
                this.fetchScheduledLog();
                this.loadLead();
            }
        }).catch(err => {
            toastr.error(err.message)
        })
    }
    
    handleAttachmentDelete = (attachment_id, lead_id) => {
        let formData = {
            "attachment_id": attachment_id,
            "lead_id": lead_id,
        }
        put(DELETE_ATTACHMENT_DATA, formData).then(response => {
            if (response.status) {
                toastr.success('Attachment delete successfully.')
                this.fetchAttachment();
                this.loadLead();
            }
        }).catch(err => {
            toastr.error(err.message)
        })
    }
    
    fetchAttachment = () => {
        const { attachment_currentPage, attachment_PageSize } = this.state;
        const lead_id = this.props.match.params.id;
        const params = {
            length: attachment_PageSize,
            start: attachment_currentPage,
            lead_id: lead_id
        };

        post(GET_ATTACHMENT_DATA, params).then(res => {
            this.setState({
                attachments: res.data,
                atachment_totalItems: res.total,
                attachment_path_url: res.path_url
            });
        }).catch(err => {
            toastr.error(err.message || 'Failed to fetch attachement.');
        });
    }
    
    fetchElementSheet = () => {        
        get(GET_ES_DATA, { params: { leads_id: this.props.match.params.id } })
        .then((res) => {
            if (res.data.event == null) {
                this.setState({
                    elementSheet: res.data,
                    add_es_client: res.data.client,
                    add_es_date: res.data.date,
                    add_es_venue: res.data.venue,
                    elementSheetLeadData: null,
                });               
            } else {
                this.setState({
                    elementSheetLeadData: res.data,
                    edit_es_id: res.data.id,
                    edit_es_event: res.data.event,
                    edit_es_date: res.data.date,
                    edit_es_venue: res.data.venue,
                    edit_es_management_fees: res.data.management_fees,
                    edit_es_total_of_elements: res.data.total_elements,
                    edit_es_total_event_cost: res.data.total_event_cost,
                    edit_es_less_advance_1: res.data.advance,
                    edit_es_balance: res.data.balance,
                    edit_es_client: res.data.client,
                    elementSheet: null,
                }, () => {
                    this.fetchEsTransaction();
                });
            }
        })
        .catch((error) => {
            console.error("There was an error fetching the Element Sheet!", error);
            toastr.error("Error fetching Element Sheet.");
        });
    }    
    
    fetchEsTransaction = () => {
        const { es_transaction_currentPage, es_transaction_PageSize } = this.state;
        const params = {
            length: es_transaction_PageSize,
            start: es_transaction_currentPage,
            leads_id: this.props.match.params.id
        };

        get(GET_ES_TRANSACTION_DATA, { params: params }).then(res => {
            this.setState({
                es_transactions: res.data,
                es_transaction_totalItems: res.total
            });
        }).catch(err => {
            toastr.error(err.message || 'Failed to fetch es transaction.');
        });
    }
    
    sanitizeHtml(content) {
        return { __html: DOMPurify.sanitize(content) };
    }
    
    fetchMeeting = () => {
        const { meeting_currentPage, meeting_PageSize } = this.state;
        const lead_id = this.props.match.params.id;
        const params = {
            length: meeting_PageSize,
            start: meeting_currentPage,
            lead_id: lead_id
        };

        post(GET_MEETING_DATA, params).then(res => {
            this.setState({
                meetings: res.data,
                meeting_totalItems: res.total
            });
        }).catch(err => {
            toastr.error(err.message || 'Failed to fetch notes.');
        });
    }
    
    fetchNotes = () => {
        const { notes_currentPage, notes_PageSize } = this.state;
        const lead_id = this.props.match.params.id;
        const params = {
            length: notes_PageSize,
            start: notes_currentPage,
            lead_id: lead_id
        };

        post(GET_NOTES_DATA, params).then(res => {
            this.setState({
                notes: res.data,
                notes_totalItems: res.total
            });
        }).catch(err => {
            toastr.error(err.message || 'Failed to fetch notes.');
        });
    };

    handleNotesPageChange = (page) => {
        this.setState({ notes_currentPage: page }, () => {
            this.fetchNotes();
        });
    };
    
    handleMeetingPageChange = (page) => {
        this.setState({ meeting_currentPage: page }, () => {
            this.fetchMeeting();
        });
    };
    
    handleCallLogPageChange = (page) => {
        this.setState({ call_log_currentPage: page }, () => {
            this.fetchCallLog();
        });
    };
    
    handleCallSchedulePageChange = (page) => {
        this.setState({ call_schedule_currentPage: page }, () => {
            this.fetchScheduledLog();
        });
    };
    
    handleAttachmentPageChange = (page) => {
        this.setState({ attachment_currentPage: page }, () => {
            this.fetchAttachment();
        });
    };
    
    handleEsTransactionPageChange = (page) => {
        this.setState({ es_transaction_currentPage: page }, () => {
            this.fetchEsTransaction();
        });
    };
    
    toggleNotesModalEdit = (note) => {
        this.setState({
            showNotesModalEdit: !this.state.showNotesModalEdit,
            editNote: note ? {
                id: note.id,
                leads_id: note.leads_id,
                edit_note: note.notes
            } : { id: '', leads_id: '', edit_note: '' }
        });
    };
    
    toggleMeetingModalEdit = (meeting) => {
        this.setState({ showMeetingModalEdit: !this.state.showMeetingModalEdit });
        this.setState({
            // showMeetingModalEdit: !this.state.showMeetingModalEdit,
            editMeeting: meeting ? {
                id: meeting.id,
                leads_id: meeting.leads_id,
                type: meeting.type_name ? { label: meeting.type_name, value: meeting.type } : null,
                link: meeting.link,
                location: meeting.location,
                purpose: meeting.purpose,
                with: meeting.meeting_with,
                start_date_time: meeting.start_date_time,
                end_date_time: meeting.end_date_time,
                conducted_by: meeting.created_by,
                participants: meeting.participants.map(participant => ({
                    label: participant.participant_label || participant.participants_email,
                    value: participant.participants_id
                })),
                agenda: meeting.agenda
                
            } : { 
                id: '', 
                leads_id: '',  
                type: null,
                link: '',
                location: '',
                purpose: '',
                with: '',
                start_date_time: '',
                end_date_time: '',
                conducted_by: '',
                participants: [],
                agenda: '' 
            }
        });
    };
    
    handleInputEditNotesChange = (e) => {
        const { name, value } = e.target;
        this.setState(prevState => ({
            editNote: {
                ...prevState.editNote,
                [name]: value
            }
        }));
    };
    
    handleNotesEditFormSubmit = (e) => {
        e.preventDefault();
        if (this.noteseditvalidator.allValid()) {           
            const { editNote } = this.state;
            this.setState({editnotesSubmit : true})
            put(EDIT_NOTES_DATA, editNote).then(res => {
                if (res.status) {
                    this.setState({editnotesSubmit : false})
                    toastr.success('Note updated successfully.')
                    this.toggleNotesModalEdit();
                    this.fetchNotes();
                    this.loadLead();
                }
            }).catch(err => {
                this.setState({editnotesSubmit : false})
                toastr.error(err)
            })
        } else {
            this.setState({editnotesSubmit : false})
            this.noteseditvalidator.showMessages();
            this.forceUpdate();
        }
    }
    
    handleMeetingEditFormSubmit = (e) => {
        e.preventDefault();
        if (this.meetingeditvalidator.allValid()) {
            const { editMeeting } = this.state;
            // Extract the type value
            const meetingDataToSubmit = {
                ...editMeeting,
                type: editMeeting.type.value, // Ensure only the value is passed
            };
            this.setState({ editmeetingSubmit: true });
            put(EDIT_MEETING_DATA, meetingDataToSubmit).then(res => {
                if (res.status) {
                    this.setState({ editmeetingSubmit: false });
                    toastr.success('Meeting updated successfully.');
                    this.toggleMeetingModalEdit();
                    this.fetchMeeting();
                    this.loadLead();
                }
            }).catch(err => {
                this.setState({ editmeetingSubmit: false });
                // toastr.error(err);
                toastr.error(err.response.data.message);
            });
        } else {
            this.setState({ editmeetingSubmit: false });
            this.meetingeditvalidator.showMessages();
            this.forceUpdate();
        }
    }
    
    handleCallLogEditFormSubmit  = (e) => {
        e.preventDefault();
        if (this.callLogeditvalidator.allValid()) {
            const { editCallLog } = this.state;
            // Extract the type value
            const callLogDataToSubmit = {
                ...editCallLog,
                type: editCallLog.type.value, // Ensure only the value is passed
            };
            this.setState({ editCallLogSubmit: true });
            put(EDIT_CALL_LOG_DATA, callLogDataToSubmit).then(res => {
                if (res.status) {
                    this.setState({ editCallLogSubmit: false });
                    toastr.success('call log updated successfully.');
                    this.toggleCallLogModalEdit();
                    this.fetchCallLog();
                    this.loadLead();
                }
            }).catch(err => {
                this.setState({ editCallLogSubmit: false });
                // toastr.error(err);
                toastr.error(err.response.data.message);
            });
        } else {
            this.setState({ editCallLogSubmit: false });
            this.callLogeditvalidator.showMessages();
            this.forceUpdate();
        }
    }
    
    handleCallScheduleEditFormSubmit = (e) => {
        e.preventDefault();
        if (this.callScheduleEditvalidator.allValid()) {
            const { editCallSchedule } = this.state;
            // Extract the type value
            const callLogDataToSubmit = {
                ...editCallSchedule,
                type: editCallSchedule.type.value, // Ensure only the value is passed
            };
            this.setState({ editCallScheduleSubmit: true });
            put(EDIT_CALL_SCHEDULE_DATA, callLogDataToSubmit).then(res => {
                if (res.status) {
                    this.setState({ editCallScheduleSubmit: false });
                    toastr.success('call Schedule updated successfully.');
                    this.toggleCallScheduleModalEdit();
                    this.fetchScheduledLog();
                    this.loadLead();
                }
            }).catch(err => {
                this.setState({ editCallScheduleSubmit: false });
                // toastr.error(err);
                toastr.error(err.response.data.message);
            });
        } else {
            this.setState({ editCallScheduleSubmit: false });
            this.callScheduleEditvalidator.showMessages();
            this.forceUpdate();
        }
    }
    
    toggleModal() {
        this.setState({ isShowing: !this.state.isShowing });
    }
     
    toggleEditModal(id = null) {
        this.setState({
            isEditShowing: !this.state.isEditShowing,
            selectedTransactionId: id 
        });
    }
    
    downloadCSV = (data,filename) => {
        // Convert the array of objects to CSV format
        const csvRows = [];
        
        // Get headers
        const headers = Object.keys(data[0]);
        csvRows.push(headers.join(',')); // join headers by comma
        
        // Loop over the rows
        for (const row of data) {
          const values = headers.map(header => {
            const escape = ('' + row[header]).replace(/"/g, '\\"');
            return `"${escape}"`; // wrap in double quotes and escape any inner quotes
          });
          csvRows.push(values.join(',')); // join values by comma
        }
      
        // Create a Blob from the CSV string
        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
      
        // Create a link element and trigger the download
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', filename);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };
    
    ImportCSV = (e) => {
        e.preventDefault();
        let file = document.getElementById("file").files[0];
        if (file !== undefined) {
          const formData = new FormData();
          formData.append("master_name", "es_transaction");
          formData.append("leads_id", this.props.match.params.id);
          formData.append("master_data", file);
          axios({
            method: "post",
            url: `${process.env.REACT_APP_APIURL}${ES_TRANSACTION_IMPORT}`,
            data: formData,
            headers: {
              "Content-Type": "application/json",
              Authorization: accessToken,
            },
          })
            .then(response => {
              if (response.data.status == true) {
                this.fetchElementSheet();
                document.getElementById("file").value = "";
                if (response.data.data.msg) {
                  toastr.success(response.data.data.msg);
                }
                if(response.data.data.result){
                this.downloadCSV(response.data.data.result,'es_transaction_rejected_data.csv');
                }
              }else{
                toastr.error(response.data.message);
              }
            });
        } else {
            toastr.error("Please select a valid excel sheet");
        }
    };
    
    ExportCSV = (type) => {
        var leads_id = this.props.match.params.id;
        axios({
          method: "get",
          url: `${process.env.REACT_APP_APIURL}${ES_TRANSACTION_EXPORT}`,
          params: { type, leads_id }, // Correct way to pass query params
          headers: {
            "Content-Type": "application/json",
            Authorization: accessToken,
          },
        }).then(response => {
          if (response.data.status === true) {
            if (response.data.data) {
              // Process data to replace null values with empty strings
              const processedData = response.data.data.map(row => {
                return Object.keys(row).reduce((acc, key) => {
                  acc[key] = row[key] === null ? '' : row[key]; // Replace null with empty string
                  return acc;
                }, {});
              });
      
              // Download the CSV with the processed data
              this.downloadCSV(processedData, `es_transaction_${type}.csv`);
            }
          } else {
            toastr.error(response.data.message);
          }
        });
      };          
    
    render() {
        const minDate = this.pastDate();
        const minDateTime = this.pastDateTime();
        const { data_load, is_edit,is_edit_es,isShowing,isEditShowing, location_master,client_location,stage, stage_master, source, source_master,brand,brand_master,assigned_to,assigned_to_master,participant_master,business_category,service_required,type_of_franchise,country_code,alternate_country_code,business_category_master,service_required_master, submit,loginUserId, notesSubmit, editnotesSubmit,editmeetingSubmit,editCallLogSubmit,editCallScheduleSubmit,meetingSubmit,callLogSubmit,callScheduleSubmit,attachmentSubmit, notes,meetings,call_log,call_schedule, notes_currentPage,meeting_currentPage,call_schedule_currentPage,call_log_currentPage,attachment_currentPage,es_transaction_currentPage,fetchEsTransaction, notes_totalItems,meeting_totalItems,call_log_totalItems,call_schedule_totalItems,atachment_totalItems,es_transaction_totalItems,attachment_path_url,attachments,es_transactions, notes_PageSize,meeting_PageSize,call_log_PageSize,call_schedule_PageSize,attachment_PageSize,es_transaction_PageSize, showNotesModalEdit,showMeetingModalEdit ,editNote, editMeeting,elementSheet,addElementSheetSubmit,editElementSheetSubmit } = this.state
        const countryCodes = [
            { value: "91", label: "(+91) India" },
            { value: "213", label: "(+213) Algeria" },
            { value: "376", label: "(+376) Andorra" },
            { value: "244", label: "(+244) Angola" },
            { value: "1264", label: "(+1264) Anguilla" },
            { value: "1268", label: "(+1268) Antigua & Barbuda" },
            { value: "54", label: "(+54) Argentina" },
            { value: "374", label: "(+374) Armenia" },
            { value: "297", label: "(+297) Aruba" },
            { value: "61", label: "(+61) Australia" },
            { value: "43", label: "(+43) Austria" },
            { value: "994", label: "(+994) Azerbaijan" },
            { value: "1242", label: "(+1242) Bahamas" },
            { value: "973", label: "(+973) Bahrain" },
            { value: "880", label: "(+880) Bangladesh" },
            { value: "1246", label: "(+1246) Barbados" },
            { value: "375", label: "(+375) Belarus" },
            { value: "32", label: "(+32) Belgium" },
            { value: "501", label: "(+501) Belize" },
            { value: "229", label: "(+229) Benin" },
            { value: "1441", label: "(+1441) Bermuda" },
            { value: "975", label: "(+975) Bhutan" },
            { value: "591", label: "(+591) Bolivia" },
            { value: "387", label: "(+387) Bosnia Herzegovina" },
            { value: "267", label: "(+267) Botswana" },
            { value: "55", label: "(+55) Brazil" },
            { value: "673", label: "(+673) Brunei" },
            { value: "359", label: "(+359) Bulgaria" },
            { value: "226", label: "(+226) Burkina Faso" },
            { value: "257", label: "(+257) Burundi" },
            { value: "855", label: "(+855) Cambodia" },
            { value: "237", label: "(+237) Cameroon" },
            { value: "1", label: "(+1) Canada" },
            { value: "238", label: "(+238) Cape Verde Islands" },
            { value: "1345", label: "(+1345) Cayman Islands" },
            { value: "236", label: "(+236) Central African Republic" },
            { value: "56", label: "(+56) Chile" },
            { value: "86", label: "(+86) China" },
            { value: "57", label: "(+57) Colombia" },
            { value: "269", label: "(+269) Comoros" },
            { value: "242", label: "(+242) Congo" },
            { value: "682", label: "(+682) Cook Islands" },
            { value: "506", label: "(+506) Costa Rica" },
            { value: "385", label: "(+385) Croatia" },
            { value: "53", label: "(+53) Cuba" },
            { value: "90392", label: "(+90392) Cyprus North" },
            { value: "357", label: "(+357) Cyprus South" },
            { value: "42", label: "(+42) Czech Republic" },
            { value: "45", label: "(+45) Denmark" },
            { value: "253", label: "(+253) Djibouti" },
            { value: "1809", label: "(+1809) Dominica" },
            { value: "1809", label: "(+1809) Dominican Republic" },
            { value: "593", label: "(+593) Ecuador" },
            { value: "20", label: "(+20) Egypt" },
            { value: "503", label: "(+503) El Salvador" },
            { value: "240", label: "(+240) Equatorial Guinea" },
            { value: "291", label: "(+291) Eritrea" },
            { value: "372", label: "(+372) Estonia" },
            { value: "251", label: "(+251) Ethiopia" },
            { value: "500", label: "(+500) Falkland Islands" },
            { value: "298", label: "(+298) Faroe Islands" },
            { value: "679", label: "(+679) Fiji" },
            { value: "358", label: "(+358) Finland" },
            { value: "33", label: "(+33) France" },
            { value: "594", label: "(+594) French Guiana" },
            { value: "689", label: "(+689) French Polynesia" },
            { value: "241", label: "(+241) Gabon" },
            { value: "220", label: "(+220) Gambia" },
            { value: "7880", label: "(+7880) Georgia" },
            { value: "49", label: "(+49) Germany" },
            { value: "233", label: "(+233) Ghana" },
            { value: "350", label: "(+350) Gibraltar" },
            { value: "30", label: "(+30) Greece" },
            { value: "299", label: "(+299) Greenland" },
            { value: "1473", label: "(+1473) Grenada" },
            { value: "590", label: "(+590) Guadeloupe" },
            { value: "671", label: "(+671) Guam" },
            { value: "502", label: "(+502) Guatemala" },
            { value: "224", label: "(+224) Guinea" },
            { value: "245", label: "(+245) Guinea - Bissau" },
            { value: "592", label: "(+592) Guyana" },
            { value: "509", label: "(+509) Haiti" },
            { value: "504", label: "(+504) Honduras" },
            { value: "852", label: "(+852) Hong Kong" },
            { value: "36", label: "(+36) Hungary" },
            { value: "354", label: "(+354) Iceland" },
            { value: "62", label: "(+62) Indonesia" },
            { value: "98", label: "(+98) Iran" },
            { value: "964", label: "(+964) Iraq" },
            { value: "353", label: "(+353) Ireland" },
            { value: "972", label: "(+972) Israel" },
            { value: "39", label: "(+39) Italy" },
            { value: "1876", label: "(+1876) Jamaica" },
            { value: "81", label: "(+81) Japan" },
            { value: "962", label: "(+962) Jordan" },
            { value: "7", label: "(+7) Kazakhstan" },
            { value: "254", label: "(+254) Kenya" },
            { value: "686", label: "(+686) Kiribati" },
            { value: "850", label: "(+850) Korea North" },
            { value: "82", label: "(+82) Korea South" },
            { value: "965", label: "(+965) Kuwait" },
            { value: "996", label: "(+996) Kyrgyzstan" },
            { value: "856", label: "(+856) Laos" },
            { value: "371", label: "(+371) Latvia" },
            { value: "961", label: "(+961) Lebanon" },
            { value: "266", label: "(+266) Lesotho" },
            { value: "231", label: "(+231) Liberia" },
            { value: "218", label: "(+218) Libya" },
            { value: "417", label: "(+417) Liechtenstein" },
            { value: "370", label: "(+370) Lithuania" },
            { value: "352", label: "(+352) Luxembourg" },
            { value: "853", label: "(+853) Macao" },
            { value: "389", label: "(+389) Macedonia" },
            { value: "261", label: "(+261) Madagascar" },
            { value: "265", label: "(+265) Malawi" },
            { value: "60", label: "(+60) Malaysia" },
            { value: "960", label: "(+960) Maldives" },
            { value: "223", label: "(+223) Mali" },
            { value: "356", label: "(+356) Malta" },
            { value: "692", label: "(+692) Marshall Islands" },
            { value: "596", label: "(+596) Martinique" },
            { value: "222", label: "(+222) Mauritania" },
            { value: "269", label: "(+269) Mayotte" },
            { value: "52", label: "(+52) Mexico" },
            { value: "691", label: "(+691) Micronesia" },
            { value: "373", label: "(+373) Moldova" },
            { value: "377", label: "(+377) Monaco" },
            { value: "976", label: "(+976) Mongolia" },
            { value: "1664", label: "(+1664) Montserrat" },
            { value: "212", label: "(+212) Morocco" },
            { value: "258", label: "(+258) Mozambique" },
            { value: "95", label: "(+95) Myanmar" },
            { value: "264", label: "(+264) Namibia" },
            { value: "674", label: "(+674) Nauru" },
            { value: "977", label: "(+977) Nepal" },
            { value: "31", label: "(+31) Netherlands" },
            { value: "687", label: "(+687) New Caledonia" },
            { value: "64", label: "(+64) New Zealand" },
            { value: "505", label: "(+505) Nicaragua" },
            { value: "227", label: "(+227) Niger" },
            { value: "234", label: "(+234) Nigeria" },
            { value: "683", label: "(+683) Niue" },
            { value: "672", label: "(+672) Norfolk Islands" },
            { value: "670", label: "(+670) Northern Marianas" },
            { value: "47", label: "(+47) Norway" },
            { value: "968", label: "(+968) Oman" },
            { value: "680", label: "(+680) Palau" },
            { value: "507", label: "(+507) Panama" },
            { value: "675", label: "(+675) Papua New Guinea" },
            { value: "595", label: "(+595) Paraguay" },
            { value: "51", label: "(+51) Peru" },
            { value: "63", label: "(+63) Philippines" },
            { value: "48", label: "(+48) Poland" },
            { value: "351", label: "(+351) Portugal" },
            { value: "1787", label: "(+1787) Puerto Rico" },
            { value: "974", label: "(+974) Qatar" },
            { value: "262", label: "(+262) Reunion" },
            { value: "40", label: "(+40) Romania" },
            { value: "7", label: "(+7) Russia" },
            { value: "250", label: "(+250) Rwanda" },
            { value: "378", label: "(+378) San Marino" },
            { value: "239", label: "(+239) Sao Tome &amp; Principe " },
            { value: "966", label: "(+966) Saudi Arabia" },
            { value: "221", label: "(+221) Senegal " },
            { value: "381", label: "(+381) Serbia " },
            { value: "248", label: "(+248) Seychelles" },
            { value: "232", label: "(+232) Sierra Leone" },
            { value: "65", label: "(+65) Singapore" },
            { value: "421", label: "(+421) Slovak Republic" },
            { value: "386", label: "(+386) Slovenia" },
            { value: "677", label: "(+677) Solomon Islands" },
            { value: "252", label: "(+252) Somalia" },
            { value: "27", label: "(+27) South Africa" },
            { value: "34", label: "(+34) Spain" },
            { value: "94", label: "(+94) Sri Lanka " },
            { value: "290", label: "(+290) St. Helena " },
            { value: "1869", label: "(+1869) St. Kitts " },
            { value: "1758", label: "(+1758) St. Lucia " },
            { value: "249", label: "(+249) Sudan" },
            { value: "597", label: "(+597) Suriname" },
            { value: "268", label: "(+268) Swaziland" },
            { value: "46", label: "(+46) Sweden" },
            { value: "41", label: "(+41) Switzerland" },
            { value: "963", label: "(+963) Syria" },
            { value: "886", label: "(+886) Taiwan" },
            { value: "7", label: "(+7) Tajikstan" },
            { value: "66", label: "(+66) Thailand" },
            { value: "228", label: "(+228) Togo" },
            { value: "676", label: "(+676) Tonga" },
            { value: "1868", label: "(+1868) Trinidad &amp; Tobago" },
            { value: "216", label: "(+216) Tunisia" },
            { value: "90", label: "(+90) Turkey" },
            { value: "7", label: "(+7) Turkmenistan" },
            { value: "993", label: "(+993) Turkmenistan" },
            { value: "1649", label: "(+1649) Turks &amp; Caicos Islands" },
            { value: "688", label: "(+688) Tuvalu" },
            { value: "256", label: "(+256) Uganda" },
            { value: "44", label: "(+44) UK" },
            { value: "380", label: "(+380) Ukraine" },
            { value: "971", label: "(+971) United Arab Emirates" },
            { value: "598", label: "(+598) Uruguay " },
            { value: "1", label: "(+1) USA " },
            { value: "7", label: "(+7) Uzbekistan" },
            { value: "678", label: "(+678) Vanuatu" },
            { value: "379", label: "(+379) Vatican City" },
            { value: "58", label: "(+58) Venezuela" },
            { value: "84", label: "(+84) Vietnam" },
            { value: "84", label: "(+1284) Virgin Islands - British" },
            { value: "84", label: "(+1340) Virgin Islands - US " },
            { value: "681", label: "(+681) Wallis &amp; Futuna" },
            { value: "969", label: "(+969) Yemen (North)" },
            { value: "967", label: "(+967) Yemen (South)" },
            { value: "260", label: "(+260) Zambia" },
            { value: "263", label: "(+263) Zimbabwe" }
          ];
          const { log } = this.state;
          
        const { meetingType } = this.state;
        
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Leads Profile | {process.env.REACT_APP_PROJECTNAME}</title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Row className="justify-content-between">
                            <Col md="2" className="mb-4">
                                <h4 className="mb-0 font-size-20">Leads Profile</h4>
                            </Col>
                            <Col md="10" className="text-end mb-4">
                                <Nav className="icon-tab nav-justified">
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: this.state.customActiveTab === "1",
                                            })}
                                            onClick={() => {
                                                this.toggleCustom("1")
                                            }}
                                        >
                                            <span className="d-none d-sm-block"><i className="fas fa-user"></i> Overview</span>
                                            <span className="d-block d-sm-none"><i className="fas fa-user"></i></span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: this.state.customActiveTab === "3",
                                            })}
                                            onClick={() => {
                                                this.toggleCustom("3")
                                            }}
                                        >
                                            
                                            <span className="d-none d-sm-block"><i className="bx bx-phone"></i> Call </span>
                                            <span className="d-block d-sm-none"><i className="bx bx-phone"></i></span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: this.state.customActiveTab === "4",
                                            })}
                                            onClick={() => {
                                                this.toggleCustom("4")
                                            }}
                                        >
                                            
                                            <span className="d-none d-sm-block"><i className="bx bx-calendar"></i>Meeting</span>
                                            <span className="d-block d-sm-none"><i className="bx bx-calendar"></i></span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: this.state.customActiveTab === "5",
                                            })}
                                            onClick={() => {
                                                this.toggleCustom("5")
                                            }}
                                        >
                                            
                                            <span className="d-none d-sm-block"><i className="bx bx-paperclip"></i>Attachments</span>
                                            <span className="d-block d-sm-none"><i className="bx bx-paperclip"></i></span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: this.state.customActiveTab === "2",
                                            })}
                                            onClick={() => {
                                                this.toggleCustom("2")
                                            }}
                                        >
                                            <span className="d-none d-sm-block"><i className="far fa-sticky-note"></i> Notes</span>
                                            <span className="d-block d-sm-none"><i className="far fa-sticky-note"></i></span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: this.state.customActiveTab === "6",
                                            })}
                                            onClick={() => {
                                                this.toggleCustom("6")
                                            }}
                                        >
                                            <span className="d-none d-sm-block"><i className="far fa-file"></i> Element Sheet</span>
                                            <span className="d-block d-sm-none"><i className="far fa-file"></i></span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>
                        {data_load === true && <div style={{ "height": "100px", "textAlign": "center" }}><h4>Loading...</h4></div>}
                        {!data_load &&
                            <Row>
                                <Col md="12">
                                    <TabContent activeTab={this.state.customActiveTab}>
                                        <TabPane tabId="1">
                                            <Row>
                                                <Col sm="12">
                                                    <Card>
                                                        <CardBody>
                                                            <div className="d-flex justify-content-between flex-wrap profile-card-header">
                                                                <div className="d-flex"> 
                                                                    <h4>Overview</h4>
                                                                    {is_edit === false &&
                                                                    <Link className="font-size-16 ms-4" onClick={() => this.setState({ is_edit: true })} to="#"><i className='bx bxs-edit-alt'></i></Link>
                                                                    }
                                                                </div>
                                                                <div className="candidate-source">SOURCE: {source.label} | Assigned To: {assigned_to.label}</div>
                                                            </div>
                                                            <div className="profile-card-block">
                                                                <form onSubmit={this.handleFormSubmit}>
                                                                    <Row>
                                                                        <Col md="6">
                                                                            <Row>
                                                                                <Col md="4" className="mb-3">
                                                                                    <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Lead Name: </div>
                                                                                </Col>
                                                                                <Col md="8" className="mb-3">
                                                                                    <div className="main-profile-text" >{is_edit === false && <div className="d-flex justify-content-between">
                                                                                        <p className="font-size-16 mb-0">{this.state.lead_name}</p>
                                                                                    </div>}
                                                                                        {is_edit === true &&
                                                                                            <div style={{ width: "100%" }}>
                                                                                                <input type="text" className="form-control" style={{ width: "100%", maxWidth: "250px" }} name="lead_name"  onChange={this.handleInput} defaultValue={this.state.lead_name} />
                                                                                                {this.validator.message("lead_name", this.state.lead_name, "required")}
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col md="6">
                                                                            <Row>
                                                                                <Col md="4" className="mb-3">
                                                                                    <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Stage: </div>
                                                                                </Col>
                                                                                <Col md="8" className="mb-3">
                                                                                    <div className="main-profile-text" >{is_edit === false && <div className="d-flex justify-content-between">
                                                                                        <p className="font-size-16 mb-0">{stage.label}</p>
                                                                                    </div>}
                                                                                        {is_edit === true &&
                                                                                            <div style={{ maxWidth: "250px" }} >
                                                                                                <Select isMulti={false} value={stage} name="stage" options={stage_master} onChange={e => this.setState({ stage: e })} classNamePrefix="select2-selection" />
                                                                                                {this.validator.message("stage", this.state.stage, "required")}
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        {is_edit === false && this.state.campaign_name && (
                                                                            <Col md="6">
                                                                                <Row>
                                                                                    <Col md="4" className="mb-3">
                                                                                        <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Campaign: </div>
                                                                                    </Col>
                                                                                    <Col md="8" className="mb-3">
                                                                                        <div className="main-profile-text">
                                                                                            <div className="d-flex justify-content-between">
                                                                                                <p className="font-size-16 mb-0">{this.state.campaign_name}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        )}
                                                                        <Col md="6">
                                                                            <Row>
                                                                                <Col md="4" className="mb-3">
                                                                                    <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Source: </div>
                                                                                </Col>
                                                                                <Col md="8" className="mb-3">
                                                                                    <div className="main-profile-text" >{is_edit === false && <div className="d-flex justify-content-between">
                                                                                        <p className="font-size-16 mb-0">{source.label}</p>
                                                                                    </div>}
                                                                                        {is_edit === true &&
                                                                                            <div style={{ maxWidth: "250px" }} >
                                                                                                <Select isMulti={false} value={source} name="source" options={source_master} onChange={e => this.setState({ source: e })} classNamePrefix="select2-selection" />
                                                                                                {this.validator.message("source", this.state.source, "required")}
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col md="6">
                                                                            <Row>
                                                                                <Col md="4" className="mb-3">
                                                                                    <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Brand: </div>
                                                                                </Col>
                                                                                <Col md="8" className="mb-3">
                                                                                    <div className="main-profile-text" >{is_edit === false && <div className="d-flex justify-content-between">
                                                                                        <p className="font-size-16 mb-0">{brand.label}</p>
                                                                                    </div>}
                                                                                        {is_edit === true &&
                                                                                            <div style={{ maxWidth: "250px" }} >
                                                                                                <Select isMulti={false} value={brand} name="brand" options={brand_master} onChange={e => {this.setState({ brand: e }); this.handleBrandChange(e); }} classNamePrefix="select2-selection" />
                                                                                                {this.validator.message("brand", this.state.brand, "required")}
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col md="6">
                                                                            <Row>
                                                                                <Col md="4" className="mb-3">
                                                                                    <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Business Category: </div>
                                                                                </Col>
                                                                                <Col md="8" className="mb-3">
                                                                                    <div className="main-profile-text" >{is_edit === false && <div className="d-flex justify-content-between">
                                                                                        <p className="font-size-16 mb-0">{business_category.label}</p>
                                                                                    </div>}
                                                                                        {is_edit === true &&
                                                                                            <div style={{ maxWidth: "250px" }}>
                                                                                                <Select 
                                                                                                    isMulti={false} 
                                                                                                    value={business_category} 
                                                                                                    options={business_category_master} 
                                                                                                    onChange={e => this.setState({ business_category: e })} 
                                                                                                    classNamePrefix="select2-selection" 
                                                                                                    name="business_category"
                                                                                                />
                                                                                                {this.validator.message("business_category", business_category, "required")}
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col md="6">
                                                                            <Row>
                                                                                <Col md="4" className="mb-3">
                                                                                    <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Email: </div>
                                                                                </Col>
                                                                                <Col md="8" className="mb-3">
                                                                                    <div className="main-profile-text" >{is_edit === false && <div className="d-flex justify-content-between">
                                                                                        <p className="font-size-16 mb-0">{this.state.email}</p>
                                                                                    </div>}
                                                                                        {is_edit === true &&
                                                                                            <div style={{ width: "100%" }}>
                                                                                                <input type="email" className="form-control" style={{ width: "100%",maxWidth: "250px" }} name="email" defaultValue={this.state.email} onChange={this.handleInput} />
                                                                                                {this.validator.message("email", this.state.email, "email")}
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col md="6">
                                                                            <Row>
                                                                                <Col md="4" className="mb-3">
                                                                                    <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Mobile: </div>
                                                                                </Col>
                                                                                <Col md="8" className="mb-3">
                                                                                    <div className="main-profile-text" >{is_edit === false && <div className="d-flex justify-content-between">
                                                                                        <p className="font-size-16 mb-0">{country_code.label} {this.state.mobile}</p>
                                                                                    </div>}
                                                                                        {is_edit === true &&
                                                                                            <Row>
                                                                                                <Col md="12" className="mb-2">
                                                                                                    <div style={{ maxWidth: "250px", marginRight: "10px"}} >
                                                                                                        <Select isMulti={false} value={country_code} name="country_code" options={countryCodes} onChange={e => this.setState({ country_code: e })} classNamePrefix="select2-selection" />
                                                                                                        {this.validator.message("country_code", this.state.country_code, "required")}
                                                                                                    </div>
                                                                                                </Col>
                                                                                                <Col md="12">
                                                                                                    <div style={{ width: "100%" }}>
                                                                                                        <input type="tel" className="form-control" style={{  width: "100%", maxWidth: "250px" }} name="mobile" defaultValue={this.state.mobile} onChange={this.handleInput} />
                                                                                                        {this.validator.message("mobile", this.state.mobile, "required")}
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col md="6">
                                                                            <Row>
                                                                                <Col md="4" className="mb-3">
                                                                                    <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Alternate Mobile: </div>
                                                                                </Col>
                                                                                <Col md="8" className="mb-3">
                                                                                    <div className="main-profile-text" >{is_edit === false && <div className="d-flex justify-content-between">
                                                                                        <p className="font-size-16 mb-0">{alternate_country_code.label} {this.state.alternatemobile}</p>
                                                                                    </div>}
                                                                                        {is_edit === true &&
                                                                                            <Row>
                                                                                                <Col md="12" className="mb-2">
                                                                                                    <div style={{ maxWidth: "250px" , marginRight: "10px"}} >
                                                                                                        <Select isMulti={false} value={alternate_country_code} name="alternate_country_code" options={countryCodes} onChange={e => this.setState({ alternate_country_code: e })} classNamePrefix="select2-selection" />
                                                                                                        {this.validator.message("alternate_country_code", this.state.alternate_country_code, "")}
                                                                                                    </div>
                                                                                                </Col>
                                                                                                <Col md="12">
                                                                                                    <div style={{ width: "100%" }}>
                                                                                                        <input type="tel" className="form-control" style={{ width: "100%", maxWidth: "250px" }} name="alternatemobile" defaultValue={this.state.alternatemobile} onChange={this.handleInput} />
                                                                                                        {this.validator.message("alternatemobile", this.state.alternatemobile, "")}
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col md="6">
                                                                            <Row>
                                                                                <Col md="4" className="mb-3">
                                                                                    <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Company Name: </div>
                                                                                </Col>
                                                                                <Col md="8" className="mb-3">
                                                                                    <div className="main-profile-text" >{is_edit === false && <div className="d-flex justify-content-between">
                                                                                        <p className="font-size-16 mb-0">{this.state.current_company}</p>
                                                                                    </div>}
                                                                                        {is_edit === true &&
                                                                                            <div style={{ width: "100%" }}>
                                                                                                <input type="text" className="form-control" style={{ width: "100%",maxWidth: "250px" }} name="current_company" defaultValue={this.state.current_company} onChange={this.handleInput} />
                                                                                                {this.validator.message("current_company", this.state.current_company, "")}
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        {this.state.showDesignation && ( 
                                                                            <Col md="6">
                                                                                <Row>
                                                                                    <Col md="4" className="mb-3">
                                                                                        <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Designation: </div>
                                                                                    </Col>
                                                                                    <Col md="8" className="mb-3">
                                                                                        <div className="main-profile-text" >{is_edit === false && <div className="d-flex justify-content-between">
                                                                                            <p className="font-size-16 mb-0">{this.state.designation}</p>
                                                                                        </div>}
                                                                                            {is_edit === true &&
                                                                                                <div style={{ width: "100%" }}>
                                                                                                    <input type="text" className="form-control" style={{ width: "100%",maxWidth: "250px" }} name="designation" defaultValue={this.state.designation} onChange={this.handleInput} />
                                                                                                    {this.validator.message("designation", this.state.designation, "")}
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        )}
                                                                        <Col md="6">
                                                                            <Row>
                                                                                <Col md="4" className="mb-3">
                                                                                    <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Enquiry Date: </div>
                                                                                </Col>
                                                                                <Col md="8" className="mb-3">
                                                                                    <div className="main-profile-text" >{is_edit === false && <div className="d-flex justify-content-between">
                                                                                        <p className="font-size-16 mb-0">
                                                                                        {this.state.enquiry_date !== '0000-00-00' &&
                                                                                            this.convertDateFormat(this.state.enquiry_date)
                                                                                        }</p>
                                                                                    </div>}
                                                                                        {is_edit === true &&
                                                                                            <div style={{ width: "100%" }}>
                                                                                                <input type="date" className="form-control" onChange={this.handleInput} style={{  width: "100%",maxWidth: "250px" }} name="enquiry_date" 
                                                                                                defaultValue={this.state.enquiry_date} />
                                                                                                {this.validator.message("enquiry_date", this.state.enquiry_date, "")}
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        {this.state.showDateOfEvent && (
                                                                        <Col md="6">
                                                                            <Row>
                                                                                <Col md="4" className="mb-3">
                                                                                    <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Date of Event: </div>
                                                                                </Col>
                                                                                <Col md="8" className="mb-3">
                                                                                    <div className="main-profile-text" >{is_edit === false && <div className="d-flex justify-content-between">
                                                                                        <p className="font-size-16 mb-0">
                                                                                        {this.state.date_of_event !== '0000-00-00' &&
                                                                                            this.convertDateFormat(this.state.date_of_event)
                                                                                        }
                                                                                        </p>
                                                                                    </div>}
                                                                                        {is_edit === true &&
                                                                                            <div style={{ width: "100%" }}>
                                                                                                <input type="date" className="form-control" onChange={this.handleInput} style={{  width: "100%",maxWidth: "250px" }} name="date_of_event" 
                                                                                                min={minDate} 
                                                                                                defaultValue={this.state.date_of_event}
                                                                                                />
                                                                                                {this.validator.message("date_of_event", this.state.date_of_event, "")}
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        )}
                                                                        {this.state.showBrideGroomName && ( 
                                                                        <Col md="6">
                                                                            <Row>
                                                                                <Col md="4" className="mb-3">
                                                                                    <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Bride/Groom Name: </div>
                                                                                </Col>
                                                                                <Col md="8" className="mb-3">
                                                                                    <div className="main-profile-text" >{is_edit === false && <div className="d-flex justify-content-between">
                                                                                        <p className="font-size-16 mb-0">{this.state.bride_groom_name}</p>
                                                                                    </div>}
                                                                                        {is_edit === true &&
                                                                                            <div style={{ width: "100%" }}>
                                                                                                <input type="text" className="form-control" style={{  width: "100%",maxWidth: "250px" }} onChange={this.handleInput} name="bride_groom_name" defaultValue={this.state.bride_groom_name} />
                                                                                                {this.validator.message("bride_groom_name", this.state.bride_groom_name, "")}
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        )}
                                                                        {this.state.showVenueOfEvent && (
                                                                        <Col md="6">
                                                                            <Row>
                                                                                <Col md="4" className="mb-3">
                                                                                    <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Venue of Event: </div>
                                                                                </Col>
                                                                                <Col md="8" className="mb-3">
                                                                                    <div className="main-profile-text" >{is_edit === false && <div className="d-flex justify-content-between">
                                                                                        <p className="font-size-16 mb-0">{this.state.venue_of_event}</p>
                                                                                    </div>}
                                                                                        {is_edit === true &&
                                                                                            <div style={{ width: "100%" }}>
                                                                                                <input type="text" className="form-control" style={{ width: "100%", maxWidth: "250px" }} onChange={this.handleInput} name="venue_of_event" defaultValue={this.state.venue_of_event} />
                                                                                                {this.validator.message("venue_of_event", this.state.venue_of_event, "")}
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        )}
                                                                        {is_edit === false && 
                                                                        <Col md="6">
                                                                            <Row>
                                                                                <Col md="4" className="mb-3">
                                                                                    <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Client’s State: </div>
                                                                                </Col>
                                                                                <Col md="8" className="mb-3">
                                                                                    <div className="main-profile-text" >
                                                                                        <div className="d-flex justify-content-between">
                                                                                            <p className="font-size-16 mb-0">{this.state.state}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        }
                                                                        <Col md="6">
                                                                            <Row>
                                                                                <Col md="4" className="mb-3">
                                                                                    <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Client’s City: </div>
                                                                                </Col>
                                                                                <Col md="8" className="mb-3">
                                                                                    <div className="main-profile-text" >
                                                                                        {is_edit === false && <div className="d-flex justify-content-between">
                                                                                            <p className="font-size-16 mb-0">{client_location.label} </p>
                                                                                        </div>}
                                                                                        {is_edit === true &&
                                                                                            <div style={{ maxWidth: "250px" }}>
                                                                                                <Select isMulti={false} value={client_location} name="client_location" options={location_master} onChange={e => this.setState({ client_location: e })} classNamePrefix="select2-selection" />
                                                                                                {this.validator.message("client_location", this.state.client_location, "")}
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        {this.state.showNoOfPeople && ( 
                                                                        <Col md="6">
                                                                            <Row>
                                                                                <Col md="4" className="mb-3">
                                                                                    <div className="text-primary fw-bold font-size-16 mt-0 mb-0">No of People: </div>
                                                                                </Col>
                                                                                <Col md="8" className="mb-3">
                                                                                    <div className="main-profile-text" >{is_edit === false && <div className="d-flex justify-content-between">
                                                                                        <p className="font-size-16 mb-0">{this.state.no_of_people}</p>
                                                                                    </div>}
                                                                                        {is_edit === true &&
                                                                                            <div style={{ width: "100%" }}>
                                                                                                <input type="text" className="form-control" style={{  width: "100%",maxWidth: "250px" }} onChange={this.handleInput} name="no_of_people" defaultValue={this.state.no_of_people} />
                                                                                                {this.validator.message("no_of_people", this.state.no_of_people, "")}
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        )}
                                                                        {this.state.showBudget && (  
                                                                        <Col md="6">
                                                                            <Row>
                                                                                <Col md="4" className="mb-3">
                                                                                    <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Budget: </div>
                                                                                </Col>
                                                                                <Col md="8" className="mb-3">
                                                                                    <div className="main-profile-text" >{is_edit === false && <div className="d-flex justify-content-between">
                                                                                        <p className="font-size-16 mb-0">{this.state.budget}</p>
                                                                                    </div>}
                                                                                        {is_edit === true &&
                                                                                            <div style={{ width: "100%" }}>
                                                                                                <input type="text" className="form-control" style={{width: "100%", maxWidth: "250px" }} onChange={this.handleInput} name="budget" defaultValue={this.state.budget} />
                                                                                                {this.validator.message("budget", this.state.budget, "")}
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        )}
                                                                        {this.state.showNoOfFunctions && (
                                                                        <Col md="6">
                                                                            <Row>
                                                                                <Col md="4" className="mb-3">
                                                                                    <div className="text-primary fw-bold font-size-16 mt-0 mb-0">No of Functions: </div>
                                                                                </Col>
                                                                                <Col md="8" className="mb-3">
                                                                                    <div className="main-profile-text" >{is_edit === false && <div className="d-flex justify-content-between">
                                                                                        <p className="font-size-16 mb-0">{this.state.no_of_functions}</p>
                                                                                    </div>}
                                                                                        {is_edit === true &&
                                                                                            <div style={{ width: "100%" }}>
                                                                                                <input type="text" className="form-control" style={{ width: "100%",maxWidth: "250px" }} onChange={this.handleInput} name="no_of_functions" defaultValue={this.state.no_of_functions} />
                                                                                                {this.validator.message("no_of_functions", this.state.no_of_functions, "")}
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        )}
                                                                        {this.state.showNoOfDays && ( 
                                                                        <Col md="6">
                                                                            <Row>
                                                                                <Col md="4" className="mb-3">
                                                                                    <div className="text-primary fw-bold font-size-16 mt-0 mb-0">No of Days: </div>
                                                                                </Col>
                                                                                <Col md="8" className="mb-3">
                                                                                    <div className="main-profile-text" >{is_edit === false && <div className="d-flex justify-content-between">
                                                                                        <p className="font-size-16 mb-0">{this.state.no_of_days}</p>
                                                                                    </div>}
                                                                                        {is_edit === true &&
                                                                                            <div style={{ width: "100%" }}>
                                                                                                <input type="text" className="form-control" style={{ width: "100%",maxWidth: "250px" }} onChange={this.handleInput} name="no_of_days" defaultValue={this.state.no_of_days} />
                                                                                                {this.validator.message("no_of_days", this.state.no_of_days, "")}
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        )}
                                                                        {this.state.showWhatStarHotel && (  
                                                                        <Col md="6">
                                                                            <Row>
                                                                                <Col md="4" className="mb-3">
                                                                                    <div className="text-primary fw-bold font-size-16 mt-0 mb-0">What Star Hotel: </div>
                                                                                </Col>
                                                                                <Col md="8" className="mb-3">
                                                                                    <div className="main-profile-text" >{is_edit === false && <div className="d-flex justify-content-between">
                                                                                        <p className="font-size-16 mb-0">{this.state.what_star_hotel}</p>
                                                                                    </div>}
                                                                                        {is_edit === true &&
                                                                                            <div style={{ width: "100%" }}>
                                                                                                <input type="text" className="form-control" style={{ width: "100%",maxWidth: "250px" }} onChange={this.handleInput} name="what_star_hotel" defaultValue={this.state.what_star_hotel} />
                                                                                                {this.validator.message("what_star_hotel", this.state.what_star_hotel, "")}
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        )}
                                                                        {this.state.showCommunity && (  
                                                                        <Col md="6">
                                                                            <Row>
                                                                                <Col md="4" className="mb-3">
                                                                                    <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Community: </div>
                                                                                </Col>
                                                                                <Col md="8" className="mb-3">
                                                                                    <div className="main-profile-text" >{is_edit === false && <div className="d-flex justify-content-between">
                                                                                        <p className="font-size-16 mb-0">{this.state.community}</p>
                                                                                    </div>}
                                                                                        {is_edit === true &&
                                                                                            <div style={{ width: "100%" }}>
                                                                                                <input type="text" className="form-control" style={{ width: "100%",maxWidth: "250px" }} onChange={this.handleInput} name="community" defaultValue={this.state.community} />
                                                                                                {this.validator.message("community", this.state.community, "")}
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        )}
                                                                        {this.state.showArtist && ( 
                                                                        <Col md="6">
                                                                            <Row>
                                                                                <Col md="4" className="mb-3">
                                                                                    <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Type Of Artist Required: </div>
                                                                                </Col>
                                                                                <Col md="8" className="mb-3">
                                                                                    <div className="main-profile-text" >{is_edit === false && <div className="d-flex justify-content-between">
                                                                                        <p className="font-size-16 mb-0">{this.state.type_of_artist_required}</p>
                                                                                    </div>}
                                                                                        {is_edit === true &&
                                                                                            <div style={{ width: "100%" }}>
                                                                                                <input type="text" className="form-control" style={{ width: "100%",maxWidth: "250px" }} onChange={this.handleInput} name="type_of_artist_required" defaultValue={this.state.type_of_artist_required} />
                                                                                                {this.validator.message("type_of_artist_required", this.state.type_of_artist_required, "")}
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        )}
                                                                        {this.state.showServices && (  
                                                                        <Col md="6">
                                                                            <Row>
                                                                                <Col md="4" className="mb-3">
                                                                                    <div className="text-primary fw-bold font-size-16 mt-0 mb-0">What services are required: </div>
                                                                                </Col>
                                                                                <Col md="8" className="mb-3">
                                                                                    <div className="main-profile-text" >{is_edit === false && <div className="d-flex justify-content-between">
                                                                                        <p className="font-size-16 mb-0">
                                                                                            {service_required?.label ? service_required.label : 'No service required'}
                                                                                        </p>
                                                                                    </div>}
                                                                                        {is_edit === true &&
                                                                                            <div style={{ maxWidth: "250px" }}>
                                                                                                <Select 
                                                                                                    isMulti={false} 
                                                                                                    value={service_required} 
                                                                                                    options={service_required_master} 
                                                                                                    onChange={e => this.setState({ service_required: e })} 
                                                                                                    classNamePrefix="select2-selection" 
                                                                                                    name="service_required"
                                                                                                />
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        )}
                                                                        {this.state.showFranchise && (  
                                                                        <Col md="6">
                                                                            <Row>
                                                                                <Col md="4" className="mb-3">
                                                                                    <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Type of Franchise: </div>
                                                                                </Col>
                                                                                <Col md="8" className="mb-3">
                                                                                    <div className="main-profile-text" >{is_edit === false && <div className="d-flex justify-content-between">
                                                                                        <p className="font-size-16 mb-0">
                                                                                        {type_of_franchise.value !== null &&
                                                                                            type_of_franchise.label     
                                                                                        }</p>
                                                                                    </div>}
                                                                                        {is_edit === true &&
                                                                                            <div style={{ maxWidth: "250px" }} >
                                                                                                <Select value={type_of_franchise.value !== null &&
                                                                                                    type_of_franchise
                                                                                                } options={[{ label: "Master", value: 1 }, { label: "Unit", value: 2 }]} name="type_of_franchise"  onChange={e => this.setState({ type_of_franchise: e })} classNamePrefix="select2-selection" />
                                                                                                {this.validator.message("type_of_franchise", this.state.type_of_franchise, "")}
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        )}
                                                                        {this.state.showJobBusinessType && (  
                                                                        <Col md="6">
                                                                            <Row>
                                                                                <Col md="4" className="mb-3">
                                                                                    <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Job/Business Type: </div>
                                                                                </Col>
                                                                                <Col md="8" className="mb-3">
                                                                                    <div className="main-profile-text" >{is_edit === false && <div className="d-flex justify-content-between">
                                                                                        <p className="font-size-16 mb-0">{this.state.job_business_type}</p>
                                                                                    </div>}
                                                                                        {is_edit === true &&
                                                                                            <div style={{ width: "100%" }}>
                                                                                                <input type="text" className="form-control" style={{ width: "100%",maxWidth: "250px" }} onChange={this.handleInput} name="job_business_type" defaultValue={this.state.job_business_type} />
                                                                                                {this.validator.message("job_business_type", this.state.job_business_type, "")}
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        )}
                                                                        <Col md="6">
                                                                            <Row>
                                                                                <Col md="4" className="mb-3">
                                                                                    <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Assigned to: </div>
                                                                                </Col>
                                                                                <Col md="8" className="mb-3">
                                                                                    <div className="main-profile-text" >{is_edit === false && <div className="d-flex justify-content-between">
                                                                                        <p className="font-size-16 mb-0">{assigned_to.label}</p>
                                                                                    </div>}
                                                                                        {is_edit === true &&
                                                                                            <div style={{ maxWidth: "250px" }} >
                                                                                                <Select isMulti={false} value={assigned_to} options={assigned_to_master} name="assigned_to" onChange={e => this.setState({ assigned_to: e })} classNamePrefix="select2-selection" />
                                                                                                {this.validator.message("assigned_to", this.state.assigned_to, "required")}
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        {this.state.showProfile && ( 
                                                                        <Col md="6">
                                                                            <Row>
                                                                                <Col md="4" className="mb-3">
                                                                                    {is_edit === false &&
                                                                                        <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Profile: </div> 
                                                                                    }
                                                                                    {is_edit === true &&
                                                                                        <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Upload Profile - (PDF, WORD, PPT): </div> 
                                                                                    }
                                                                                </Col>
                                                                                <Col md="8" className="mb-3">
                                                                                    <div className="main-profile-text" >{is_edit === false && <div className="d-flex justify-content-between">
                                                                                        <p>{this.state.profile_url != null && this.state.profile_url !== '' ? (
                                                                                            <a className="btn btn-outline-primary" href={this.state.url_path + this.state.profile_url}>
                                                                                                Download Profile
                                                                                            </a>
                                                                                            ) : ''}
                                                                                        </p>
                                                                                    </div>}
                                                                                        {is_edit === true &&
                                                                                            <div style={{ width: "100%" }}>
                                                                                                <input type="file" className="form-control" style={{ width: "100%",maxWidth: "250px" }} accept=".pdf,.ppt,.word" onChange={e => {this.handleProfile(e)}} name="profile" />
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>   
                                                                        )}                                                                   
                                                                        {is_edit === false &&
                                                                        <Col md="6">
                                                                            <Row>
                                                                                <Col md="4" className="mb-3">
                                                                                    <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Created by: </div>
                                                                                </Col>
                                                                                <Col md="8" className="mb-3">
                                                                                    <div className="main-profile-text" >{is_edit === false && <div className="d-flex justify-content-between">
                                                                                        <p className="font-size-16 mb-0">{this.state.created_by}</p>
                                                                                    </div>}
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        }   
                                                                        {is_edit === false &&
                                                                        <Col md="6">
                                                                            <Row>
                                                                                <Col md="4" className="mb-3">
                                                                                    <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Created date: </div>
                                                                                </Col>
                                                                                <Col md="8" className="mb-3">
                                                                                    <div className="main-profile-text" >{is_edit === false && <div className="d-flex justify-content-between">
                                                                                        <p className="font-size-16 mb-0">{this.convertDateTimeFormat(this.state.created_at)}</p>
                                                                                    </div>}
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        } 
                                                                    </Row>
                                                                    <div className="main-profile-block w-100">
                                                                        {is_edit === true &&
                                                                             <div className="d-flex justify-content-end">
                                                                                <button className="btn btn-outline-dark me-2"  onClick={() => { this.editFormHide() }} type="button">CANCEL</button>
                                                                                <button className="btn btn-primary" disabled={submit} type="submit">{submit ? 'PLEASE WAIT...':'UPDATE'}</button>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                    <Card>
                                                        <CardBody>
                                                            <div className="container">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                    <h4>Timelines</h4>
                                                                    <ul className="timeline">
                                                                        {log && log.map(entry => (
                                                                            <li key={entry.id}>
                                                                                <div className="d-flex">
                                                                                    <h6>{entry.stage} - &nbsp;</h6>
                                                                                    <h6 style={{ color: "#4767b5", fontWeight: "600" }}>{entry.log}</h6>
                                                                                </div>
                                                                                <p>{entry.notes} {entry.employee} {this.convertDateTimeFormat2(entry.created_at)}</p>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <Card>
                                                <CardBody>
                                                    <div className="d-flex justify-content-between profile-card-header mb-3">
                                                        <div className="d-flex">
                                                            <h4>Notes</h4>
                                                        </div>
                                                        <Button onClick={this.toggleNotesModal}>Add</Button>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <h6>{notes_totalItems} Notes</h6>
                                                    </div>
                                                    <Row>
                                                        {notes.map(note => (
                                                            <Col md="6" className='mb-3' key={note.id}>
                                                                <div style={{ border: "1px solid #eee", borderRadius: "4px", padding: "14px 12px" }}>
                                                                    <div className="font-size-12 text-muted"><h6 className="d-inline"></h6>{note.notes}</div>
                                                                    <div className="d-flex justify-content-between">
                                                                        <p className='text-primary fw-bold font-size-12 mt-3 mb-0'>{note.created_by} | {this.convertDateTimeFormat(note.created_at)}</p>
                                                                        <div className="pt-3">  
                                                                        {note.is_edit == 1 &&
                                                                           <span style={{ fontWeight: "500", color: "#ced0cf",  marginRight: "3px"}}>Edited</span>                                                                         
                                                                        } 
                                                                        <a className='text-danger p-1' onClick={e => { this.handleNoteDelete(note.id,note.leads_id) }}><i className='bx bxs-trash'></i></a>
                                                                        <a className='text-success p-1' onClick={() => this.toggleNotesModalEdit(note)}><i className='bx bxs-edit-alt'></i></a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                    <Row className="justify-content-center">
                                                        <Col className="col-auto">
                                                            <Pagination
                                                                className="pagination-bar"
                                                                currentPage={notes_currentPage}
                                                                totalCount={notes_totalItems}
                                                                pageSize={notes_PageSize}
                                                                onPageChange={this.handleNotesPageChange}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                        <TabPane tabId="3">
                                            <Card>
                                                <CardBody>
                                                    <Nav className="icon-tab nav-justified mb-3">
                                                        <NavItem>
                                                            <NavLink
                                                                style={{ cursor: "pointer" }}
                                                                className={classnames({
                                                                    active: this.state.customActiveTab2 === "1",
                                                                })}
                                                                onClick={() => {
                                                                    this.toggleCustom2("1")
                                                                }}
                                                            >
                                                                <span className="">Log Call</span>
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem>
                                                            <NavLink
                                                                style={{ cursor: "pointer" }}
                                                                className={classnames({
                                                                    active: this.state.customActiveTab2 === "2",
                                                                })}
                                                                onClick={() => {
                                                                    this.toggleCustom2("2")
                                                                }}
                                                            >
                                                                
                                                                <span className="">Schedule Call</span>
                                                            </NavLink>
                                                        </NavItem>
                                                    </Nav>
                                                    <TabContent activeTab={this.state.customActiveTab2}>
                                                        <TabPane tabId="1">
                                                            <div className="d-flex justify-content-between profile-card-header mb-3">
                                                                <div className="d-flex">
                                                                    <h4>Log Call</h4>
                                                                </div>
                                                                <Button onClick={this.toggleCallModal}>Add</Button>
                                                            </div>     
                                                            <div className="d-flex justify-content-between">
                                                                <h6>{call_log_totalItems} Call Logged</h6>
                                                            </div>
                                                            <Row>
                                                                {call_log.map(log => (
                                                                    <Col md="6" className='mb-3'  key={log.id}>
                                                                        <div style={{ border: "1px solid #eee", borderRadius: "4px", padding: "14px 12px" }}>
                                                                            <div className="candidate-meta mb-0">
                                                                                <span>Call To: <span className="text-dark text-primary">{log.call_to}</span></span>
                                                                                <span> | Call Type: <span className="text-dark text-primary">{log.type_name}</span></span>
                                                                            </div>
                                                                            <div className="candidate-meta">
                                                                                <span>Call Purpose: <span className="text-dark text-primary">{log.purpose}</span></span>
                                                                            </div>
                                                                            <div className="candidate-meta">
                                                                                <span>Call Time: <span className="text-dark text-primary">{this.convertDateTimeFormat(log.start_date_time)} - {this.convertDateTimeFormat(log.end_date_time)}</span></span>
                                                                            </div>
                                                                            <div className="candidate-meta">
                                                                                <span>Call Discussion: <span className="text-dark text-primary" dangerouslySetInnerHTML={this.sanitizeHtml(log.discussion)} /></span>
                                                                            </div>
                                                                            <div className="d-flex justify-content-between">
                                                                                <p className='text-primary fw-bold font-size-12 mt-3 mb-0'>{log.created_by} | {this.convertDateTimeFormat(log.created_at)}</p>
                                                                                <div className="pt-3">  
                                                                                {log.is_edit == 1 &&
                                                                                <span style={{ fontWeight: "500", color: "#ced0cf",  marginRight: "3px"}}>Edited</span>                                                                         
                                                                                } 
                                                                                <a className='text-danger p-1' onClick={e => { this.handleCallLogDelete(log.id,log.leads_id) }}><i className='bx bxs-trash'></i></a>
                                                                                <a className='text-success p-1' onClick={() => this.toggleCallLogModalEdit(log)}><i className='bx bxs-edit-alt'></i></a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                ))}
                                                            </Row>  
                                                            <Row className="justify-content-center">
                                                                <Col className="col-auto">
                                                                    <Pagination
                                                                        className="pagination-bar"
                                                                        currentPage={call_log_currentPage}
                                                                        totalCount={call_log_totalItems}
                                                                        pageSize={call_log_PageSize}
                                                                        onPageChange={this.handleCallLogPageChange}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </TabPane>
                                                        <TabPane tabId="2">
                                                            <div className="d-flex justify-content-between profile-card-header mb-3">
                                                                <div className="d-flex">
                                                                    <h4>Schedule Call</h4>
                                                                </div>
                                                                <Button onClick={this.toggleCallScheduleModal}>Add</Button>
                                                            </div>     
                                                            <div className="d-flex justify-content-between">
                                                                <h6>{call_schedule_totalItems} Call scheduled</h6>
                                                            </div>
                                                            <Row>
                                                                {call_schedule.map(schedule => (
                                                                    <Col md="6" className='mb-3'  key={schedule.id}>
                                                                        <div style={{ border: "1px solid #eee", borderRadius: "4px", padding: "14px 12px" }}>
                                                                            <div className="candidate-meta mb-0">
                                                                                <span>Call To: <span className="text-dark text-primary">{schedule.call_to}</span></span>
                                                                                <span> | Call Type: <span className="text-dark text-primary">{schedule.type_name}</span></span>
                                                                            </div>
                                                                            <div className="candidate-meta">
                                                                                <span>Call Purpose: <span className="text-dark text-primary">{schedule.purpose}</span></span>
                                                                            </div>
                                                                            <div className="candidate-meta">
                                                                                <span>Call Time: <span className="text-dark text-primary">{this.convertDateTimeFormat(schedule.start_date_time)} - {this.convertDateTimeFormat(schedule.end_date_time)}</span></span>
                                                                            </div>
                                                                            <div className="candidate-meta">
                                                                                <span>Call Agenda: <span className="text-dark text-primary" dangerouslySetInnerHTML={this.sanitizeHtml(schedule.discussion)} /></span>
                                                                            </div>
                                                                            <div className="d-flex justify-content-between">
                                                                                <p className='text-primary fw-bold font-size-12 mt-3 mb-0'>{schedule.created_by} | {this.convertDateTimeFormat(schedule.created_at)}</p>
                                                                                <div className="pt-3">  
                                                                                {schedule.is_edit == 1 &&
                                                                                <span style={{ fontWeight: "500", color: "#ced0cf",  marginRight: "3px"}}>Edited</span>                                                                         
                                                                                } 
                                                                                <a className='text-danger p-1' onClick={e => { this.handleCallScheduleDelete(schedule.id,schedule.leads_id) }}><i className='bx bxs-trash'></i></a>
                                                                                <a className='text-success p-1' onClick={() => this.toggleCallScheduleModalEdit(schedule)}><i className='bx bxs-edit-alt'></i></a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                ))}
                                                            </Row>  
                                                            <Row className="justify-content-center">
                                                                <Col className="col-auto">
                                                                    <Pagination
                                                                        className="pagination-bar"
                                                                        currentPage={call_schedule_currentPage}
                                                                        totalCount={call_schedule_totalItems}
                                                                        pageSize={call_schedule_PageSize}
                                                                        onPageChange={this.handleCallSchedulePageChange}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </TabPane>
                                                    </TabContent>                                                     
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                        <TabPane tabId="4">
                                            <Row>
                                                <Col md="12">
                                                    <Card>
                                                        <CardBody>
                                                            <div className="d-flex justify-content-between profile-card-header mb-3">
                                                                <div className="d-flex">
                                                                    <h4>Meeting</h4>
                                                                </div>
                                                                <Button onClick={this.toggleMeetingModal}>Add</Button>
                                                            </div>     
                                                            <div className="d-flex justify-content-between">
                                                                <h6>{meeting_totalItems} Meeting scheduled</h6>
                                                            </div>
                                                            <Row>
                                                                {meetings.map(meeting => (
                                                                    <Col md="6" className='mb-3'  key={meeting.id}>
                                                                        <div style={{ border: "1px solid #eee", borderRadius: "4px", padding: "14px 12px" }}>
                                                                            <div className="candidate-meta mb-0">
                                                                                <span>Meeting with: <span className="text-dark text-primary">{meeting.meeting_with}</span></span>
                                                                                <span> | Meeting Type: <span className="text-dark text-primary">{meeting.type_name}</span></span>
                                                                            </div>
                                                                            {meeting.type == 1 && (
                                                                                <div className="candidate-meta">
                                                                                    <span>Meeting Link: <span className="text-dark text-primary"><a target="_blank" href={meeting.link} rel="noopener noreferrer">{meeting.link}</a></span></span>
                                                                                </div>
                                                                            )}
                                                                            {meeting.type == 2 && (
                                                                                <div className="candidate-meta">
                                                                                    <span>Meeting Location: <span className="text-dark text-primary">{meeting.location}</span></span>
                                                                                </div>
                                                                            )}
                                                                            <div className="candidate-meta">
                                                                                <span>Meeting Time: <span className="text-dark text-primary">{this.convertDateTimeFormat(meeting.start_date_time)} - {this.convertDateTimeFormat(meeting.end_date_time)}</span></span>
                                                                            </div>
                                                                            <div className="candidate-meta">
                                                                                <span>Participants: {' '}
                                                                                    {meeting.participants.map((participant, index) => (
                                                                                        <span key={participant.participants_id} className="text-dark text-primary">
                                                                                            {participant.participants_email}{index !== meeting.participants.length - 1 ? ', ' : ''}
                                                                                        </span>
                                                                                    ))}
                                                                                </span>
                                                                            </div>
                                                                            <div className="candidate-meta">
                                                                                <span>Meeting Purpose: <span className="text-dark text-primary">{meeting.purpose}</span></span>
                                                                            </div>
                                                                            <div className="candidate-meta">
                                                                                <span>Meeting Agenda: <span className="text-dark text-primary" dangerouslySetInnerHTML={this.sanitizeHtml(meeting.agenda)} /></span>
                                                                            </div>
                                                                            <div className="d-flex justify-content-between">
                                                                                <p className='text-primary fw-bold font-size-12 mt-3 mb-0'>{meeting.created_by} | {this.convertDateTimeFormat(meeting.created_at)}</p>
                                                                                <div className="pt-3">  
                                                                                {meeting.is_edit == 1 &&
                                                                                <span style={{ fontWeight: "500", color: "#ced0cf",  marginRight: "3px"}}>Edited</span>                                                                         
                                                                                } 
                                                                                 <a className='text-danger p-1' onClick={e => { this.handleMeetingDelete(meeting.id,meeting.leads_id) }}><i className='bx bxs-trash'></i></a>
                                                                                 <a className='text-success p-1' onClick={() => this.toggleMeetingModalEdit(meeting)}><i className='bx bxs-edit-alt'></i></a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            <Row className="justify-content-center">
                                                <Col className="col-auto">
                                                    <Pagination
                                                        className="pagination-bar"
                                                        currentPage={meeting_currentPage}
                                                        totalCount={meeting_totalItems}
                                                        pageSize={meeting_PageSize}
                                                        onPageChange={this.handleMeetingPageChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="5">
                                            <Row>
                                                <Col md="12">
                                                    <Card>
                                                        <CardBody>
                                                            <div className="d-flex justify-content-between profile-card-header mb-3">
                                                                <div className="d-flex">
                                                                    <h4>Attachment</h4>
                                                                </div>
                                                                <Button onClick={this.toggleAttachmentModal}>Add</Button>
                                                            </div>     
                                                            <div className="d-flex justify-content-between">
                                                                <h6>{atachment_totalItems} Attachments</h6>
                                                            </div>
                                                            <Row>
                                                                <Col md="12">
                                                                    <div className="table-responsive">
                                                                        <table className="table table-bordered">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Filename</th>
                                                                                    <th>Uploaded By</th>
                                                                                    <th>Timestamp</th>
                                                                                    <th>Actions</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {attachments.map(attachment => (
                                                                                    <tr key={attachment.id}>
                                                                                        <td>{attachment.file_name}</td>
                                                                                        <td>{attachment.created_by}</td>
                                                                                        <td>{this.convertDateTimeFormat(attachment.created_at)}</td>
                                                                                        <td> 
                                                                                            <a target="_blank" href={`${attachment_path_url}${attachment.url}`} rel="noopener noreferrer">
                                                                                                <i className="bx bxs-download me-4"></i>
                                                                                            </a>
                                                                                            <a
                                                                                                className="text-danger p-1"
                                                                                                onClick={e => {
                                                                                                    this.handleAttachmentDelete(attachment.id, attachment.leads_id);
                                                                                                }}
                                                                                            >
                                                                                                <i className="bx bxs-trash"></i>
                                                                                            </a>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="justify-content-center">
                                                                <Col className="col-auto">
                                                                    <Pagination
                                                                        className="pagination-bar"
                                                                        currentPage={attachment_currentPage}
                                                                        totalCount={atachment_totalItems}
                                                                        pageSize={attachment_PageSize}
                                                                        onPageChange={this.handleAttachmentPageChange}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="6">
                                            <Row>
                                                <Col md="12">
                                                    {this.state.elementSheet && (
                                                        <Card>
                                                            <CardBody>
                                                                <div className="d-flex justify-content-between profile-card-header mb-3">
                                                                    <div className="d-flex">
                                                                        <h4>Element Sheet</h4>
                                                                    </div>
                                                                </div>
                                                                <form onSubmit={this.handleFormAddESSubmit} className="mb-3">
                                                                    <Row>
                                                                        <h4 className="mb-3">Step 1:</h4>
                                                                        <Col md="4" className="mb-3">
                                                                            <Label htmlFor="event">Event <span className="text-danger">*</span></Label>
                                                                            <input name="add_es_event" placeholder="Type Event" type="text" className={"form-control"} onChange={this.handleInput} />
                                                                            {this.addElementSheetValidator.message('event', this.state.add_es_event, 'required')}
                                                                        </Col>
                                                                        <Col md="4" className="mb-3">
                                                                            <Label htmlFor="client">Client <span className="text-danger">*</span></Label>
                                                                            <input name="add_es_client" placeholder="Client" defaultValue={this.state.add_es_client} type="text" className={"form-control"} onChange={this.handleInput} disabled />
                                                                            {this.addElementSheetValidator.message("client", this.state.add_es_client, "required")}
                                                                        </Col>
                                                                        <Col md="4" className="mb-3">
                                                                            <Label htmlFor="date">Date <span className="text-danger">*</span></Label>
                                                                            <Input
                                                                                name="add_es_date"
                                                                                type="date"
                                                                                className="form-control"
                                                                                defaultValue={this.state.add_es_date}
                                                                            />
                                                                            {this.addElementSheetValidator.message("date", this.state.add_es_date, "required")}
                                                                        </Col>
                                                                        <Col md="4" className="mb-3">
                                                                            <Label htmlFor="venue">Venue <span className="text-danger">*</span></Label>
                                                                            <input name="add_es_venue" placeholder="Venue" defaultValue={this.state.add_es_venue} type="text" className={"form-control"} onChange={this.handleInput} />
                                                                            {this.addElementSheetValidator.message('Venue', this.state.add_es_venue, 'required')}
                                                                        </Col>
                                                                        <Col md="4" className="mb-3">
                                                                            <Label htmlFor="management_fees">Management Fees <span className="text-danger">*</span></Label>
                                                                            <input name="add_es_management_fees" placeholder="Type Management Fees" type="number" className={"form-control"} onChange={this.handleInput} />
                                                                            {this.addElementSheetValidator.message('management_fees', this.state.add_es_management_fees, 'required')}
                                                                        </Col>
                                                                        <Col md="4" className="mb-3">
                                                                            <Label htmlFor="total_of_elements">Total of Elements <span className="text-danger">*</span></Label>
                                                                            <input name="add_es_total_of_elements" placeholder="Type Total of Elements" type="number" className={"form-control"} onChange={this.handleInput} />
                                                                            {this.addElementSheetValidator.message('total_of_elements', this.state.add_es_total_of_elements, 'required')}
                                                                        </Col>
                                                                        <Col md="4" className="mb-3">
                                                                            <Label htmlFor="total_event_cost">Total Event Cost<span className="text-danger">*</span></Label>
                                                                            <input name="add_es_total_event_cost" placeholder="Type Total Event Cost" type="number" className={"form-control"} onChange={this.handleInput} />
                                                                            {this.addElementSheetValidator.message('total_event_cost', this.state.add_es_total_event_cost, 'required')}
                                                                        </Col>
                                                                        <Col md="4" className="mb-3">
                                                                            <Label htmlFor="less_advance_1">Less Advance 1<span className="text-danger">*</span></Label>
                                                                            <input name="add_es_less_advance_1" placeholder="Type Less Advance 1" type="number" className={"form-control"} onChange={this.handleInput} />
                                                                            {this.addElementSheetValidator.message('less_advance_1', this.state.add_es_less_advance_1, 'required')}
                                                                        </Col>
                                                                        <Col md="4" className="mb-3">
                                                                            <Label htmlFor="balance">Balance<span className="text-danger">*</span></Label>
                                                                            <input name="add_es_balance" placeholder="Type Balance" type="number" className={"form-control"} onChange={this.handleInput} />
                                                                            {this.addElementSheetValidator.message('balance', this.state.add_es_balance, 'required')}
                                                                        </Col>
                                                                    </Row>
                                                                    <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                                        <button className="btn btn-primary" disabled={addElementSheetSubmit} type="submit"> {addElementSheetSubmit ? 'Please wait...' : 'Submit'}</button>
                                                                    </div>
                                                                </form>
                                                            </CardBody>
                                                        </Card>
                                                    )}
                                                    {this.state.elementSheetLeadData && (
                                                    <>
                                                        <Card>
                                                            <CardBody>
                                                                <div className="d-flex justify-content-between profile-card-header mb-3">
                                                                    <div className="d-flex">
                                                                        <h4>Element Sheet</h4>
                                                                    </div>
                                                                </div>
                                                                <Row>                                                                           
                                                                    <Col md="12">
                                                                        <div className="d-flex justify-content-between flex-wrap profile-card-header">
                                                                            <div className="d-flex"> 
                                                                                <h4>Step 1:</h4>
                                                                                {is_edit_es === false &&
                                                                                <Link className="font-size-16 ms-4" onClick={() => this.setState({ is_edit_es: true })} to="#"><i className='bx bxs-edit-alt'></i></Link>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="profile-card-block mb-3">
                                                                            <form onSubmit={this.handleFormEditESSubmit}>
                                                                                <Row>
                                                                                    <Col md="6">
                                                                                        <Row>
                                                                                            <Col md="4" className="mb-3">
                                                                                                <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Event: </div>
                                                                                            </Col>
                                                                                            <Col md="8" className="mb-3">
                                                                                                <div className="main-profile-text" >{is_edit_es === false && <div className="d-flex justify-content-between">
                                                                                                    <p className="font-size-16 mb-0">{this.state.edit_es_event}</p>
                                                                                                </div>}
                                                                                                    {is_edit_es === true &&
                                                                                                        <div style={{ width: "100%" }}>
                                                                                                            <input type="text" className="form-control" style={{ width: "100%", maxWidth: "250px" }} name="edit_es_event"  onChange={this.handleInput} defaultValue={this.state.edit_es_event} />
                                                                                                            {this.editElementSheetValidator.message("event", this.state.edit_es_event, "required")}
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Col>
                                                                                    <Col md="6">
                                                                                        <Row>
                                                                                            <Col md="4" className="mb-3">
                                                                                                <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Client: </div>
                                                                                            </Col>
                                                                                            <Col md="8" className="mb-3">
                                                                                                <div className="main-profile-text" >{is_edit_es === false && <div className="d-flex justify-content-between">
                                                                                                    <p className="font-size-16 mb-0">{this.state.edit_es_client}</p>
                                                                                                </div>}
                                                                                                    {is_edit_es === true &&
                                                                                                        <div style={{ width: "100%" }}>
                                                                                                            <input type="text" className="form-control" style={{ width: "100%", maxWidth: "250px" }} name="edit_es_client"  onChange={this.handleInput} defaultValue={this.state.edit_es_client} disabled />
                                                                                                            {this.editElementSheetValidator.message("client", this.state.edit_es_client, "required")}
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Col>
                                                                                    <Col md="6">
                                                                                        <Row>
                                                                                            <Col md="4" className="mb-3">
                                                                                                <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Event Date: </div>
                                                                                            </Col>
                                                                                            <Col md="8" className="mb-3">
                                                                                                <div className="main-profile-text" >{is_edit_es === false && <div className="d-flex justify-content-between">
                                                                                                    <p className="font-size-16 mb-0">
                                                                                                    {this.state.edit_es_date !== '0000-00-00' &&
                                                                                                        this.convertDateFormat(this.state.edit_es_date)
                                                                                                    }
                                                                                                    </p>
                                                                                                </div>}
                                                                                                    {is_edit_es === true &&
                                                                                                        <div style={{ width: "100%" }}>
                                                                                                            <input type="date" className="form-control" style={{ width: "100%", maxWidth: "250px" }} name="edit_es_date"  onChange={this.handleInput} defaultValue={this.state.edit_es_date} />
                                                                                                            {this.editElementSheetValidator.message("date", this.state.edit_es_date, "required")}
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Col>
                                                                                    <Col md="6">
                                                                                        <Row>
                                                                                            <Col md="4" className="mb-3">
                                                                                                <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Event Venue: </div>
                                                                                            </Col>
                                                                                            <Col md="8" className="mb-3">
                                                                                                <div className="main-profile-text" >{is_edit_es === false && <div className="d-flex justify-content-between">
                                                                                                    <p className="font-size-16 mb-0">{this.state.edit_es_venue}</p>
                                                                                                </div>}
                                                                                                    {is_edit_es === true &&
                                                                                                        <div style={{ width: "100%" }}>
                                                                                                            <input type="text" className="form-control" style={{ width: "100%", maxWidth: "250px" }} name="edit_es_venue"  onChange={this.handleInput} defaultValue={this.state.edit_es_venue} />
                                                                                                            {this.editElementSheetValidator.message("venue", this.state.edit_es_venue, "required")}
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Col>
                                                                                    <Col md="6">
                                                                                        <Row>
                                                                                            <Col md="4" className="mb-3">
                                                                                                <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Management Fees: </div>
                                                                                            </Col>
                                                                                            <Col md="8" className="mb-3">
                                                                                                <div className="main-profile-text" >{is_edit_es === false && <div className="d-flex justify-content-between">
                                                                                                    <p className="font-size-16 mb-0">₹{this.state.edit_es_management_fees}</p>
                                                                                                </div>}
                                                                                                    {is_edit_es === true &&
                                                                                                        <div style={{ width: "100%" }}>
                                                                                                            <input type="text" className="form-control" style={{ width: "100%", maxWidth: "250px" }} name="edit_es_management_fees"  onChange={this.handleInput} defaultValue={this.state.edit_es_management_fees} />
                                                                                                            {this.editElementSheetValidator.message("management fees", this.state.edit_es_management_fees, "required")}
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Col>
                                                                                    <Col md="6">
                                                                                        <Row>
                                                                                            <Col md="4" className="mb-3">
                                                                                                <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Total of Elements: </div>
                                                                                            </Col>
                                                                                            <Col md="8" className="mb-3">
                                                                                                <div className="main-profile-text" >{is_edit_es === false && <div className="d-flex justify-content-between">
                                                                                                    <p className="font-size-16 mb-0">{this.state.edit_es_total_of_elements}</p>
                                                                                                </div>}
                                                                                                    {is_edit_es === true &&
                                                                                                        <div style={{ width: "100%" }}>
                                                                                                            <input type="text" className="form-control" style={{ width: "100%", maxWidth: "250px" }} name="edit_es_total_of_elements"  onChange={this.handleInput} defaultValue={this.state.edit_es_total_of_elements} />
                                                                                                            {this.editElementSheetValidator.message("total of elements", this.state.edit_es_total_of_elements, "required")}
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Col>
                                                                                    <Col md="6">
                                                                                        <Row>
                                                                                            <Col md="4" className="mb-3">
                                                                                                <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Total Event Cost: </div>
                                                                                            </Col>
                                                                                            <Col md="8" className="mb-3">
                                                                                                <div className="main-profile-text" >{is_edit_es === false && <div className="d-flex justify-content-between">
                                                                                                    <p className="font-size-16 mb-0">₹{this.state.edit_es_total_event_cost}</p>
                                                                                                </div>}
                                                                                                    {is_edit_es === true &&
                                                                                                        <div style={{ width: "100%" }}>
                                                                                                            <input type="text" className="form-control" style={{ width: "100%", maxWidth: "250px" }} name="edit_es_total_event_cost"  onChange={this.handleInput} defaultValue={this.state.edit_es_total_event_cost} />
                                                                                                            {this.editElementSheetValidator.message("total event cost", this.state.edit_es_total_event_cost, "required")}
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Col>
                                                                                    <Col md="6">
                                                                                        <Row>
                                                                                            <Col md="4" className="mb-3">
                                                                                                <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Less Advance 1: </div>
                                                                                            </Col>
                                                                                            <Col md="8" className="mb-3">
                                                                                                <div className="main-profile-text" >{is_edit_es === false && <div className="d-flex justify-content-between">
                                                                                                    <p className="font-size-16 mb-0">₹{this.state.edit_es_less_advance_1}</p>
                                                                                                </div>}
                                                                                                    {is_edit_es === true &&
                                                                                                        <div style={{ width: "100%" }}>
                                                                                                            <input type="text" className="form-control" style={{ width: "100%", maxWidth: "250px" }} name="edit_es_less_advance_1"  onChange={this.handleInput} defaultValue={this.state.edit_es_less_advance_1} />
                                                                                                            {this.editElementSheetValidator.message("less advance 1", this.state.edit_es_less_advance_1, "required")}
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Col>
                                                                                    <Col md="6">
                                                                                        <Row>
                                                                                            <Col md="4" className="mb-3">
                                                                                                <div className="text-primary fw-bold font-size-16 mt-0 mb-0">Balance: </div>
                                                                                            </Col>
                                                                                        <Col md="8" className="mb-3">
                                                                                                <div className="main-profile-text" >{is_edit_es === false && <div className="d-flex justify-content-between">
                                                                                                    <p className="font-size-16 mb-0">₹{this.state.edit_es_balance}</p>
                                                                                                </div>}
                                                                                                    {is_edit_es === true &&
                                                                                                        <div style={{ width: "100%" }}>
                                                                                                            <input type="text" className="form-control" style={{ width: "100%", maxWidth: "250px" }} name="edit_es_balance"  onChange={this.handleInput} defaultValue={this.state.edit_es_balance} />
                                                                                                            {this.editElementSheetValidator.message("balance", this.state.edit_es_balance, "required")}
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Col>
                                                                                </Row>
                                                                                <div className="main-profile-block w-100">
                                                                                    {is_edit_es === true &&
                                                                                        <div className="d-flex justify-content-end">
                                                                                            <button className="btn btn-outline-dark me-2" onClick={() => this.setState({ is_edit_es: false })} type="button">CANCEL</button>
                                                                                            <button className="btn btn-primary" disabled={editElementSheetSubmit} type="submit">{editElementSheetSubmit ? 'PLEASE WAIT...':'UPDATE'}</button>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </Col>                                                                            
                                                                </Row>                                                            
                                                            </CardBody>
                                                        </Card>
                                                        <Card>
                                                            <CardBody>
                                                                <Row>
                                                                    <Col md="12">
                                                                        <div className="d-flex flex-wrap justify-content-between profile-card-header mb-3">
                                                                            <div className="d-flex">
                                                                                <h4>Step 2: ES Transaction</h4>
                                                                            </div>
                                                                            <div className="d-flex flex-wrap">
                                                                                <form className="d-flex flex-wrap"  onSubmit={this.ImportCSV}>
                                                                                    <button  title="Export" type="button" onClick={downloadEstransactionSampleCsv} style={{minWidth: '123px', display: 'flex'}} className="btn btn-primary me-2 mb-2">Sample Import</button>
                                                                                    <div className="input-group mb-2 me-2" style={{ width: 'fit-content'}}>
                                                                                        <Input
                                                                                        type="file"
                                                                                        className="form-control"
                                                                                        name={"file"}
                                                                                        accept={".xlsx"}
                                                                                        id="file"
                                                                                        aria-describedby="file"
                                                                                        aria-label="Upload"
                                                                                        style={{ height: '36px',maxWidth: '250px' }}
                                                                                        />
                                                                                        <Button
                                                                                        color="primary"
                                                                                        type="submit"
                                                                                        id="inputGroupFileAddon04"
                                                                                        style={{ height: '36px' }}
                                                                                        >
                                                                                        Import
                                                                                        </Button>
                                                                                    </div>
                                                                                </form>
                                                                                <Button className="mb-2 me-2" color="primary"  onClick={() => this.ExportCSV('external')}>External Export</Button>
                                                                                <Button className="mb-2 me-2" color="primary"  onClick={() => this.ExportCSV('internal')}>Internal Export</Button>
                                                                                <button type="button" className="mb-2 me-2 btn btn-primary" onClick={this.toggleModal}><i className="mdi mdi-plus me-1"></i> Add Transaction</button>
                                                                            </div>
                                                                        </div>     
                                                                        <div className="d-flex justify-content-between">
                                                                            <h6>{es_transaction_totalItems} Transaction</h6>
                                                                        </div>
                                                                        <Row>
                                                                            <Col md="12">
                                                                                <div className="table-responsive">
                                                                                    <table className="table table-bordered">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th style={{minWidth:"150px"}}>Category</th>
                                                                                                <th style={{minWidth:"150px"}}>Element</th>
                                                                                                <th style={{minWidth:"200px"}}>From Date</th>
                                                                                                <th style={{minWidth:"200px"}}>To Date</th>
                                                                                                <th style={{minWidth:"100px"}}>Location</th>
                                                                                                <th style={{minWidth:"50px"}}>Size</th>
                                                                                                <th style={{minWidth:"100px"}}>Total Size</th>
                                                                                                <th style={{minWidth:"50px"}}>Quantity</th>
                                                                                                <th style={{minWidth:"120px"}}>Internal Rate</th>
                                                                                                <th style={{minWidth:"120px"}}>Internal Total</th>
                                                                                                <th style={{minWidth:"50px"}}>Margin</th>
                                                                                                <th style={{minWidth:"120px"}}>External Rate</th>
                                                                                                <th style={{minWidth:"120px"}}>External Total</th>
                                                                                                <th style={{minWidth:"120px"}}>Expected GOP</th>
                                                                                                <th style={{minWidth:"100px"}}>Actual GOP</th>
                                                                                                <th style={{minWidth:"250px"}}>Team Members</th>
                                                                                                <th style={{minWidth:"100px"}}>Advance 1</th>
                                                                                                <th style={{minWidth:"100px"}}>Balance</th>
                                                                                                <th style={{minWidth:"250px"}}>Remark</th>
                                                                                                <th style={{minWidth:"50px"}}>Action</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {es_transactions.map(es_transaction => (
                                                                                                <tr key={es_transaction.id}>
                                                                                                    <td>{es_transaction.es_category_name}</td>
                                                                                                    <td>{es_transaction.es_element_name}</td>
                                                                                                    <td>{this.convertDateFormat(es_transaction.from_date)}</td>
                                                                                                    <td>{this.convertDateFormat(es_transaction.to_date)}</td>
                                                                                                    <td>{es_transaction.location}</td>
                                                                                                    <td>{es_transaction.size}</td>
                                                                                                    <td>{es_transaction.total_size}</td>
                                                                                                    <td>{es_transaction.quantity}</td>
                                                                                                    <td>{es_transaction.internal_rate}</td>
                                                                                                    <td>{es_transaction.internal_total}</td>
                                                                                                    <td>{es_transaction.margin_percent}</td>
                                                                                                    <td>{es_transaction.external_rate}</td>
                                                                                                    <td>{es_transaction.external_total}</td>
                                                                                                    <td>{es_transaction.expected_GOP}</td>
                                                                                                    <td>{es_transaction.actual_GOP}</td>
                                                                                                    <td>
                                                                                                        {es_transaction.team_member.map((member, index) => (
                                                                                                            <span key={member.value}>
                                                                                                                {member.label}
                                                                                                                {index < es_transaction.team_member.length - 1 ? ', ' : ''}
                                                                                                            </span>
                                                                                                        ))}
                                                                                                    </td>
                                                                                                    <td>{es_transaction.advance}</td>
                                                                                                    <td>{es_transaction.balance}</td>
                                                                                                    <td>{es_transaction.remark}</td>                                                                                                        
                                                                                                    <td>
                                                                                                        <a className="text-primary p-1" onClick={() => this.toggleEditModal(es_transaction.id)}>
                                                                                                                <i className='bx bxs-edit-alt'></i>
                                                                                                        </a>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="justify-content-center">
                                                                            <Col className="col-auto">
                                                                                <Pagination
                                                                                    className="pagination-bar"
                                                                                    currentPage={es_transaction_currentPage}
                                                                                    totalCount={es_transaction_totalItems}
                                                                                    pageSize={es_transaction_PageSize}
                                                                                    onPageChange={this.handleEsTransactionPageChange}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </CardBody>
                                                        </Card>
                                                    </>
                                                     )}
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                </Col>
                            </Row>
                        }
                    </div>
                </div>
                {/* Modal */}
                <Modal isOpen={this.state.showNotesModal} toggle={this.toggleNotesModal}
                 size="md"
                 centered
                 >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">ADD Notes</h5>
                        <button
                        id="close_model"
                        type="button"
                        onClick={this.toggleNotesModal}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        >
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={this.handleNotesFormSubmit}>
                            <div className="main-profile-block">
                                <div className="main-profile-row">
                                    <div className="main-profile-label">Note: <span className="text-danger">*</span></div>
                                    <div className="main-profile-text">
                                    <textarea  onChange={this.handleInput} className="form-control" rows={6} placeholder="Type Your Note Here.." style={{width: "100%" }} name="add_note" />
                                    </div>
                                    {this.notesvalidator.message("add_note", this.state.add_note, "required|string")}
                                </div>
                                <div className="d-flex justify-content-end">
                                    <button className="btn btn-primary mt-3" disabled={notesSubmit} type="submit">{notesSubmit ? 'PLEASE WAIT...':'Submit'}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal>
                <Modal isOpen={this.state.showAttachmentModal} toggle={this.toggleAttachmentModal}
                 size="md"
                 centered
                 >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">ADD Attachment</h5>
                        <button
                        id="close_model"
                        type="button"
                        onClick={this.toggleAttachmentModal}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        >
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={this.handleAttachmentFormSubmit}>
                            <div className="main-profile-block">
                                <div className="main-profile-row">
                                    <div className="main-profile-label">File Name: <span className="text-danger">*</span></div>
                                    <div className="main-profile-text">
                                        <input className="form-control" id="attachment_file_name" name="attachment_file_name"  onChange={this.handleInput} placeholder="Type File Name" type="text"  />
                                        {this.attachmentvalidator.message("file name", this.state.attachment_file_name, "required")}
                                    </div>
                                </div>
                                <div className="main-profile-row mt-3">
                                    <div className="main-profile-label">Attachment: <span className="text-danger">*</span></div>
                                    <div className="main-profile-text">
                                        <input className="form-control" id="attachment" name="add_attachment" accept=".pdf,.docx,.doc,image/*"  onChange={e => {this.handleAttachment(e)}}placeholder="Select Attachment" type="file" />
                                        {this.attachmentvalidator.message("attachment", this.state.add_attachment, "required")}
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <button className="btn btn-primary mt-3" disabled={attachmentSubmit} type="submit">{attachmentSubmit ? 'PLEASE WAIT...':'Submit'}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal>
                <Modal isOpen={showNotesModalEdit} toggle={this.toggleNotesModalEdit} size="md" centered>
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">Edit Notes</h5>
                        <button
                            id="close_model"
                            type="button"
                            onClick={this.toggleNotesModalEdit}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={this.handleNotesEditFormSubmit}>
                            <div className="main-profile-block">
                                <div className="main-profile-row">
                                    <div className="main-profile-label">Note:<span className="text-danger">*</span></div>
                                    <div className="main-profile-text">
                                        <textarea
                                            className="form-control"
                                            rows={6}
                                            placeholder="Type Your Note Here.."
                                            style={{ width: "100%" }}
                                            name="edit_note"
                                            value={editNote.edit_note}
                                            onChange={this.handleInputEditNotesChange}
                                        />
                                        {this.noteseditvalidator.message("edit_note", editNote.edit_note, "required|string")}
                                        <input type="hidden" name="id" value={editNote.id} />
                                        <input type="hidden" name="leads_id" value={editNote.leads_id} />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <button className="btn btn-primary mt-3" disabled={editnotesSubmit} type="submit">{editnotesSubmit ? 'PLEASE WAIT...':'Submit'}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal>
                <Modal isOpen={this.state.showCallModal} toggle={this.toggleCallModal}
                 size="lg"
                 centered
                 >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">Log Call</h5>
                        <button
                        id="close_model"
                        type="button"
                        onClick={this.toggleCallModal}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        >
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form className="mt-3" onSubmit={this.handleCallLogFormSubmit}>
                            <Row>
                                <Col md="8" className="mb-3">
                                    <Label htmlFor="call_log_purpose">Call Purpose<span className="text-danger">*</span></Label>
                                    <Input name="call_log_purpose" onChange={this.handleInput} placeholder="Type Call Purpose" type="text" className={"form-control"}
                                    />
                                    {this.callLogvalidator.message("call_log_purpose", this.state.call_log_purpose, "required")}
                                </Col>
                                <Col md="4" className="mb-3">
                                    <Label htmlFor="call_log_to">Call To<span className="text-danger">*</span></Label>
                                    <Input name="call_log_to" onChange={this.handleInput}  value={this.state.call_log_to} type="text" className={"form-control"}
                                    disabled />
                                    {this.callLogvalidator.message("call_log_to", this.state.call_log_to, "required")}
                                </Col>
                                <Col md="4" className="mb-3">
                                    <label className="control-label">Call Type<span className="text-danger">*</span></label>
                                    <Select  name="call_log_type" isMulti={false} options={[{ label: "Outbound", value: 1 }, { label: "Inbound", value: 2 }, { label: "Client Didn't Pick up", value: 3}]} classNamePrefix="select2-selection" onChange={e => this.setState({ call_log_type: e })} />
                                    {this.callLogvalidator.message("call_log_type", this.state.call_log_type, "required")}
                                </Col>
                                <Col md="4" className="mb-3">
                                    <Label htmlFor="call_log_status">Call Status<span className="text-danger">*</span></Label>
                                    <Input name="call_log_status" value={this.state.call_log_status} type="text" className={"form-control"}
                                        disabled />
                                    {this.callLogvalidator.message("call_log_status", this.state.call_log_status, "required")}
                                </Col>
                                <Col md="4" className="mb-3">
                                    <Label htmlFor="call_log_conducted_by">Conducted By<span className="text-danger">*</span></Label>
                                    <Input name="call_log_conducted_by" onChange={this.handleInput}  value={this.state.call_log_conducted_by}   type="text" className={"form-control"}
                                        disabled />
                                    {this.callLogvalidator.message("call_log_conducted_by", this.state.call_log_conducted_by, "required")}
                                </Col>
                                <Col md="6" className="mb-3">
                                    <Label htmlFor="call_log_start_date_time">
                                        Start Date & Time<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        name="call_log_start_date_time"
                                        type="datetime-local"
                                        className="form-control"
                                        onChange={e => {
                                            const call_start_date_time = e.target.value;
                                            this.setState({
                                                call_log_start_date_time: call_start_date_time,
                                                call_log_end_date_time: ''
                                            });
                                        }}
                                    />
                                    {this.callLogvalidator.message("call_log_start_date_time", this.state.call_log_start_date_time, "required")}
                                </Col>
                                <Col md="6" className="mb-3">
                                    <Label htmlFor="call_log_end_date_time">
                                        End  Date & Time<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        name="call_log_end_date_time"
                                        type="datetime-local"
                                        className="form-control"
                                        min={this.state.call_log_start_date_time}
                                        onChange={e => this.setState({ call_log_end_date_time: e.target.value })}
                                    />
                                    {this.callLogvalidator.message("call_log_end_date_time", this.state.call_log_end_date_time, "required")}
                                </Col> 
                                 <Col md="12" className="mb-3">
                                    <Label htmlFor="call_log_discussion">
                                    Call Discussion<span className="text-danger">*</span>
                                    </Label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={this.state.call_log_discussion}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            this.setState({call_log_discussion: data})
                                        }}
                                        name="call_log_discussion"
                                    />
                                    {this.callLogvalidator.message("call_log_discussion", this.state.call_log_discussion, "required")}
                                </Col>
                            </Row>
                            <div className="main-profile-block">
                                <div className="d-flex justify-content-end">
                                    <button className="btn btn-primary mt-3" disabled={callLogSubmit} type="submit">{callLogSubmit ? 'PLEASE WAIT...':'Submit'}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal>
                <Modal isOpen={this.state.showCallScheduleModal} toggle={this.toggleCallScheduleModal}
                 size="lg"
                 centered
                 >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">Schedule Call</h5>
                        <button
                        id="close_model"
                        type="button"
                        onClick={this.toggleCallScheduleModal}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        >
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form className="mt-3" onSubmit={this.handleCallScheduleFormSubmit}>
                            <Row>
                                <Col md="8" className="mb-3">
                                    <Label htmlFor="call_schedule_purpose">Call Purpose<span className="text-danger">*</span></Label>
                                    <Input name="call_schedule_purpose" onChange={this.handleInput} placeholder="Type Call Purpose" type="text" className={"form-control"}
                                    />
                                    {this.callSchedulevalidator.message("call_schedule_purpose", this.state.call_schedule_purpose, "required")}
                                </Col>
                                <Col md="4" className="mb-3">
                                    <Label htmlFor="call_schedule_to">Call To<span className="text-danger">*</span></Label>
                                    <Input name="call_schedule_to" onChange={this.handleInput}  value={this.state.call_schedule_to} type="text" className={"form-control"}
                                    disabled />
                                    {this.callSchedulevalidator.message("call_schedule_to", this.state.call_schedule_to, "required")}
                                </Col>
                                <Col md="4" className="mb-3">
                                    <label className="control-label">Call Type<span className="text-danger">*</span></label>
                                    <Select  name="call_schedule_type" isMulti={false} options={[{ label: "Outbound", value: 1 }]} classNamePrefix="select2-selection" onChange={e => this.setState({ call_schedule_type: e })} />
                                    {this.callSchedulevalidator.message("call_schedule_type", this.state.call_schedule_type, "required")}
                                </Col>
                                <Col md="4" className="mb-3">
                                    <Label htmlFor="call_schedule_status">Call Status<span className="text-danger">*</span></Label>
                                    <Input name="call_schedule_status" value={this.state.call_schedule_status} type="text" className={"form-control"}
                                        disabled />
                                    {this.callSchedulevalidator.message("call_schedule_status", this.state.call_schedule_status, "required")}
                                </Col>
                                <Col md="4" className="mb-3">
                                    <Label htmlFor="call_schedule_conducted_by">Conducted By<span className="text-danger">*</span></Label>
                                    <Input name="call_schedule_conducted_by" onChange={this.handleInput}  value={this.state.call_schedule_conducted_by}   type="text" className={"form-control"}
                                        disabled />
                                    {this.callSchedulevalidator.message("call_schedule_conducted_by", this.state.call_schedule_conducted_by, "required")}
                                </Col>
                                <Col md="6" className="mb-3">
                                    <Label htmlFor="call_schedule_start_date_time">
                                        Start Date & Time<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        name="call_schedule_start_date_time"
                                        type="datetime-local"
                                        className="form-control"
                                        min={minDateTime} 
                                        onChange={e => {
                                            const schedule_start_date_time = e.target.value;
                                            this.setState({
                                                call_schedule_start_date_time: schedule_start_date_time,
                                                call_schedule_end_date_time: ''
                                            });
                                        }}
                                    />
                                    {this.callSchedulevalidator.message("call_schedule_start_date_time", this.state.call_schedule_start_date_time, "required")}
                                </Col>
                                <Col md="6" className="mb-3">
                                    <Label htmlFor="call_schedule_end_date_time">
                                        End  Date & Time<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        name="call_schedule_end_date_time"
                                        type="datetime-local"
                                        className="form-control"
                                        min={this.state.call_schedule_start_date_time}
                                        onChange={e => this.setState({ call_schedule_end_date_time: e.target.value })}
                                    />
                                    {this.callSchedulevalidator.message("call_schedule_end_date_time", this.state.call_schedule_end_date_time, "required")}
                                </Col> 
                                 <Col md="12" className="mb-3">
                                    <Label htmlFor="call_schedule_agenda">
                                    Call Agenda<span className="text-danger">*</span>
                                    </Label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={this.state.call_schedule_agenda}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            this.setState({call_schedule_agenda: data})
                                        }}
                                        name="call_schedule_agenda"
                                    />
                                    {this.callSchedulevalidator.message("call_schedule_agenda", this.state.call_schedule_agenda, "required")}
                                </Col>
                            </Row>
                            <div className="main-profile-block">
                                <div className="d-flex justify-content-end">
                                    <button className="btn btn-primary mt-3" disabled={callScheduleSubmit} type="submit">{callScheduleSubmit ? 'PLEASE WAIT...':'Submit'}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal>
                <Modal isOpen={this.state.showCallLogModalLog} toggle={this.toggleCallLogModalEdit}
                 size="lg"
                 centered
                 backdrop="static"  
                 >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">Edit Log Call</h5>
                        <button
                        id="close_model"
                        type="button"
                        onClick={() => this.toggleCallLogModalEdit()}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        >
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form className="mt-3" onSubmit={this.handleCallLogEditFormSubmit}>
                            <Row>
                                <Col md="8" className="mb-3">
                                    <Label htmlFor="purpose">Call Purpose<span className="text-danger">*</span></Label>
                                    <Input name="purpose" placeholder="Type Call Purpose"  onChange={this.handleInputChangecallLog} value={this.state.editCallLog.purpose} type="text" className={"form-control"}
                                    />
                                    {this.callLogeditvalidator.message("Call purpose", this.state.editCallLog.purpose, "required")}
                                </Col>
                                <Col md="4" className="mb-3">
                                    <Label htmlFor="to">Call To<span className="text-danger">*</span></Label>
                                    <Input name="to" value={this.state.editCallLog.to} type="text" className={"form-control"}
                                    disabled />
                                   {this.callLogeditvalidator.message("Call to", this.state.editCallLog.to, "required")}
                                </Col>
                                <Col md="4" className="mb-3">
                                    <label className="control-label">Call Type<span className="text-danger">*</span></label>
                                    <Select
                                        isMulti={false}
                                        options={[{ label: "Outbound", value: 1 }, { label: "Inbound", value: 2 }, { label: "Client Didn't Pick up", value: 3}]} 
                                        classNamePrefix="select2-selection"
                                        value={this.state.editCallLog.type}
                                        onChange={this.handleSelectChangeCallLog}
                                        name="call_type"
                                    />
                                    {this.callLogeditvalidator.message("Call type", this.state.editCallLog.type, "required")}
                                </Col>
                                <Col md="4" className="mb-3">
                                    <Label htmlFor="status">Call Status<span className="text-danger">*</span></Label>
                                    <Input name="status" value="Completed" type="text" className={"form-control"}
                                        disabled />
                                </Col>
                                <Col md="4" className="mb-3">
                                    <Label htmlFor="conducted_by">Conducted By<span className="text-danger">*</span></Label>
                                    <Input name="conducted_by" value={this.state.editCallLog.conducted_by}  type="text" className={"form-control"}
                                        disabled />
                                   {this.callLogeditvalidator.message("Call Conducted By", this.state.editCallLog.conducted_by, "required")}
                                </Col>
                                <Col md="6" className="mb-3">
                                    <Label htmlFor="">
                                        Start Date & Time<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        name="start_date_time"
                                        type="datetime-local"
                                        className="form-control"
                                        value={this.state.editCallLog.start_date_time}
                                        onChange={e => {
                                            const edit_call_start_date_time = e.target.value;
                                            this.setState(prevState => ({
                                                editCallLog: {
                                                    ...prevState.editCallLog,
                                                    start_date_time: edit_call_start_date_time,
                                                    end_date_time: ''
                                                }
                                            }));
                                        }}
                                    />
                                    {this.callLogeditvalidator.message("start_date_time", this.state.editCallLog.start_date_time, "required")}
                                </Col>
                                <Col md="6" className="mb-3">
                                    <Label htmlFor="">
                                        End Date & Time<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        name="end_date_time"
                                        type="datetime-local"
                                        className="form-control"
                                        value={this.state.editCallLog.end_date_time}
                                        min={this.state.editCallLog.start_date_time}
                                        onChange={e => this.setState(prevState => ({
                                            editCallLog: {
                                                ...prevState.editCallLog,
                                                end_date_time: e.target.value
                                            }
                                        }))}
                                    />
                                    {this.callLogeditvalidator.message("end_date_time", this.state.editCallLog.end_date_time, "required")}
                                </Col>
                                <Col md="12" className="mb-3">
                                    <Label htmlFor="discussion">Call Discussion<span className="text-danger">*</span></Label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={this.state.editCallLog.discussion}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            this.setState(prevState => ({
                                                editCallLog: {
                                                    ...prevState.editCallLog,
                                                    discussion: data
                                                }
                                            }));
                                        }}
                                    />
                                    {this.callLogeditvalidator.message("meeting discussion", this.state.editCallLog.discussion, "required")}
                                </Col>
                            </Row>
                            <div className="main-profile-block">
                                <div className="d-flex justify-content-end">
                                    <button className="btn btn-primary mt-3" disabled={editCallLogSubmit} type="submit">{editCallLogSubmit ? 'PLEASE WAIT...':'Submit'}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal>
                <Modal isOpen={this.state.showCallScheduleModalLog} toggle={this.toggleCallScheduleModalEdit}
                 size="lg"
                 centered
                 backdrop="static"  
                 >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">Edit Schedule Call</h5>
                        <button
                        id="close_model"
                        type="button"
                        onClick={() => this.toggleCallScheduleModalEdit()}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        >
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form className="mt-3" onSubmit={this.handleCallScheduleEditFormSubmit}>
                            <Row>
                                <Col md="8" className="mb-3">
                                    <Label htmlFor="purpose">Call Purpose<span className="text-danger">*</span></Label>
                                    <Input name="purpose" placeholder="Type Call Purpose"  onChange={this.handleInputChangecallSchedule} value={this.state.editCallSchedule.purpose} type="text" className={"form-control"}
                                    />
                                    {this.callScheduleEditvalidator.message("Call purpose", this.state.editCallSchedule.purpose, "required")}
                                </Col>
                                <Col md="4" className="mb-3">
                                    <Label htmlFor="to">Call To<span className="text-danger">*</span></Label>
                                    <Input name="to" value={this.state.editCallSchedule.to} type="text" className={"form-control"}
                                    disabled />
                                   {this.callScheduleEditvalidator.message("Call to", this.state.editCallSchedule.to, "required")}
                                </Col>
                                <Col md="4" className="mb-3">
                                    <label className="control-label">Call Type<span className="text-danger">*</span></label>
                                    <Select
                                        isMulti={false}
                                        options={[{ label: "Outbound", value: 1 }]} 
                                        classNamePrefix="select2-selection"
                                        value={this.state.editCallSchedule.type}
                                        onChange={this.handleSelectChangeCallSchedule}
                                    />
                                    {this.callScheduleEditvalidator.message("Call type", this.state.editCallSchedule.type, "required")}
                                </Col>
                                <Col md="4" className="mb-3">
                                    <Label htmlFor="">Call Status<span className="text-danger">*</span></Label>
                                    <Input name="" value="Completed" type="text" className={"form-control"}
                                        disabled />
                                </Col>
                                <Col md="4" className="mb-3">
                                    <Label htmlFor="conducted_by">Conducted By<span className="text-danger">*</span></Label>
                                    <Input name="conducted_by" value={this.state.editCallSchedule.conducted_by}  type="text" className={"form-control"}
                                        disabled />
                                   {this.callScheduleEditvalidator.message("Call Conducted By", this.state.editCallSchedule.conducted_by, "required")}
                                </Col>
                                <Col md="6" className="mb-3">
                                    <Label htmlFor="">
                                        Start Date & Time<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        name="start_date_time"
                                        type="datetime-local"
                                        className="form-control"
                                        min={minDateTime}
                                        value={this.state.editCallSchedule.start_date_time}
                                        onChange={e => {
                                            const edit_meeting_start_date_time = e.target.value;
                                            this.setState(prevState => ({
                                                editCallSchedule: {
                                                    ...prevState.editCallSchedule,
                                                    start_date_time: edit_meeting_start_date_time,
                                                    end_date_time: ''
                                                }
                                            }));
                                        }}
                                    />
                                    {this.callScheduleEditvalidator.message("start_date_time", this.state.editCallSchedule.start_date_time, "required")}
                                </Col>
                                <Col md="6" className="mb-3">
                                    <Label htmlFor="">
                                        End Date & Time<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        name="end_date_time"
                                        type="datetime-local"
                                        className="form-control"
                                        value={this.state.editCallSchedule.end_date_time}
                                        min={this.state.editCallSchedule.start_date_time}
                                        onChange={e => this.setState(prevState => ({
                                            editCallSchedule: {
                                                ...prevState.editCallSchedule,
                                                end_date_time: e.target.value
                                            }
                                        }))}
                                    />
                                    {this.callScheduleEditvalidator.message("end_date_time", this.state.editCallSchedule.end_date_time, "required")}
                                </Col>
                                <Col md="12" className="mb-3">
                                    <Label htmlFor="agenda">Call Agenda<span className="text-danger">*</span></Label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={this.state.editCallSchedule.agenda}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            this.setState(prevState => ({
                                                editCallSchedule: {
                                                    ...prevState.editCallSchedule,
                                                    agenda: data
                                                }
                                            }));
                                        }}
                                    />
                                    {this.callScheduleEditvalidator.message("meeting agenda", this.state.editCallSchedule.agenda, "required")}
                                </Col>
                            </Row>
                            <div className="main-profile-block">
                                <div className="d-flex justify-content-end">
                                    <button className="btn btn-primary mt-3" disabled={editCallScheduleSubmit} type="submit">{editCallScheduleSubmit ? 'PLEASE WAIT...':'Submit'}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal>
                <Modal isOpen={this.state.showMeetingModal} toggle={this.toggleMeetingModal}
                 size="lg"
                 centered
                 >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">ADD Meeting</h5>
                        <button
                        id="close_model"
                        type="button"
                        onClick={this.toggleMeetingModal}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        >
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form className="mt-3" onSubmit={this.handleMeetingFormSubmit}>
                            <Row>
                                <Col md="4" className="mb-3">
                                    <label className="control-label">Meeting Type<span className="text-danger">*</span></label>
                                    <Select
                                        isMulti={false}
                                        options={[
                                            { label: "Virtual", value: 1 },
                                            { label: "In Person", value: 2 }
                                        ]}
                                        classNamePrefix="select2-selection"
                                        onChange={e => {this.setState({ meeting_type: e }); this.handleMeetingTypeChange(e); }} 
                                        name="meeting_type"
                                    />
                                    {this.meetingvalidator.message("meeting_type", this.state.meeting_type, "required")}
                                </Col>
                                <Col md="8" className="mb-3">
                                    {meetingType && meetingType.value === 1 && (
                                        <div>
                                            <Label htmlFor="meeting_link">Meeting Link<span className="text-danger">*</span></Label>
                                            <Input
                                                name="meeting_link"
                                                placeholder="Meeting Link"
                                                type="text"
                                                value={this.state.meeting_link}
                                                className={"form-control"}
                                                onChange={this.handleInput}
                                            />
                                            {this.meetingvalidator.message("meeting_link", this.state.meeting_link, "")}
                                        </div>
                                    )}
                                    {meetingType && meetingType.value === 2 && (
                                        <div>
                                            <Label htmlFor="meeting_location">Meeting Location<span className="text-danger">*</span></Label>
                                            <Input
                                                name="meeting_location"
                                                placeholder="Meeting Location"
                                                type="text"
                                                value={this.state.meeting_location}
                                                className={"form-control"}
                                                onChange={this.handleInput}
                                                onBlur={this.handleLocationBlur}
                                                // onKeyDown={this.handleLocationSet}
                                            />
                                            {this.state?.google_location_list?.length > 0 && (
                                            <div 
                                                className="google_location_option_list"
                                                style={{
                                                border: "1px solid #ced4da", 
                                                borderRadius: '4px',
                                                position: "absolute",
                                                bottom: "-5px",
                                                background: "#fff",
                                                display: "block",
                                                width: "calc(100% - 25px)",
                                                zIndex: "1",
                                                transform: "translateY(100%)",
                                                }}>
                                                {this.state.google_location_list.map((location) => (
                                                <div 
                                                    key={location} 
                                                    className="cursor-pointer p-2 google_location_option" 
                                                    onClick={() => { this.selectMeetingLocation(location); }}
                                                    tabIndex="0"
                                                >
                                                    {location}
                                                </div>
                                                ))}
                                            </div>
                                            )}
                                            {this.meetingvalidator.message("meeting_location", this.state.meeting_location, "")}
                                        </div>
                                    )}
                                </Col>
                                <Col md="8" className="mb-3">
                                    <Label htmlFor="meeting_purpose">Meeting Purpose<span className="text-danger">*</span></Label>
                                    <Input name="meeting_purpose" placeholder="Type Meeting Purpose" type="text" onChange={this.handleInput} className={"form-control"}
                                    />
                                    {this.meetingvalidator.message("meeting_purpose", this.state.meeting_purpose, "required")}
                                </Col>
                                <Col md="4" className="mb-3">
                                    <Label htmlFor="meeting_with">Meeting with<span className="text-danger">*</span></Label>
                                    <Input name="meeting_with"  onChange={this.handleInput}  value={this.state.meeting_with} type="text" className={"form-control"}
                                    disabled />
                                    {this.meetingvalidator.message("meeting_with", this.state.meeting_with, "required")}
                                </Col>
                                <Col md="6" className="mb-3">
                                    <Label htmlFor="meeting_start_date_time">
                                        Start Date & Time<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        name="meeting_start_date_time"
                                        type="datetime-local"
                                        className="form-control"
                                        min={minDateTime} 
                                        onChange={e => {
                                            const meeting_start_date_time = e.target.value;
                                            this.setState({
                                                meeting_start_date_time: meeting_start_date_time,
                                                meeting_end_date_time: ''
                                            });
                                            this.handleStartTimeChange2(e);
                                        }}
                                    />
                                    {this.meetingvalidator.message("meeting_start_date_time", this.state.meeting_start_date_time, "required")}
                                </Col>
                                <Col md="6" className="mb-3">
                                    <Label htmlFor="meeting_end_date_time">
                                        End  Date & Time<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        name="meeting_end_date_time"
                                        type="datetime-local"
                                        className="form-control"
                                        min={this.state.meeting_start_date_time} // Set the min attribute dynamically
                                        onChange={e => this.setState({ meeting_end_date_time: e.target.value })}
                                    />
                                    {this.meetingvalidator.message("meeting_end_date_time", this.state.meeting_end_date_time, "required")}
                                </Col> 
                                <Col md="4" className="mb-3">
                                    <Label htmlFor="meeting_conducted_by">Conducted By<span className="text-danger">*</span></Label>
                                    <Input name="meeting_conducted_by"  onChange={this.handleInput}  value={this.state.meeting_conducted_by}   type="text" className={"form-control"}
                                        disabled />
                                    {this.meetingvalidator.message("meeting_conducted_by", this.state.meeting_conducted_by, "required")}
                                </Col>
                                <Col md="8" className="mb-3">
                                    <label className="control-label">Participants<span className="text-danger">*</span></label>
                                    <CreatableSelect
                                        isMulti
                                        options={participant_master}
                                        classNamePrefix="select2-selection"
                                        onChange={e => {this.setState({ meeting_participants: e }); this.handleParticipantsChange(e); }}
                                       
                                    />
                                    {this.meetingvalidator.message("meeting_participants", this.state.meeting_participants, "required")}
                                </Col>
                                <Col md="12" className="mb-3">
                                    <Label htmlFor="meeting_agenda">
                                    Meeting Agenda<span className="text-danger">*</span>
                                    </Label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={this.state.meeting_agenda}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            this.setState({meeting_agenda: data})
                                        }}
                                        name="meeting_agenda"
                                    />
                                    {this.meetingvalidator.message("meeting_agenda", this.state.meeting_agenda, "required")}
                                </Col>
                            </Row>
                            <div className="main-profile-block">
                                <div className="d-flex justify-content-end">
                                    <button className="btn btn-primary mt-3" disabled={meetingSubmit} type="submit">{meetingSubmit ? 'PLEASE WAIT...':'Submit'}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal>
                <Modal isOpen={this.state.showMeetingModalEdit} toggle={this.toggleMeetingModalEdit}
                 size="lg"
                 centered
                 >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">Edit Meeting</h5>
                        <button
                        id="close_model"
                        type="button"
                        onClick={() => this.toggleMeetingModalEdit()}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        >
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                    <form className="mt-3" onSubmit={this.handleMeetingEditFormSubmit}>
                        <Row>
                            <Col md="4" className="mb-3">
                                <label className="control-label">Meeting Type<span className="text-danger">*</span></label>
                                <Select
                                    isMulti={false}
                                    options={[
                                        { label: "Virtual", value: 1 },
                                        { label: "In Person", value: 2 }
                                    ]}
                                    classNamePrefix="select2-selection"
                                    value={this.state.editMeeting.type}
                                    onChange={this.handleEditMeetingTypeChange}
                                />
                                {this.meetingeditvalidator.message("meeting type", this.state.editMeeting.type, "required")}
                            </Col>
                            {this.state.editMeeting.type && this.state.editMeeting.type.value == 1 && (
                                <Col md="8" className="mb-3">
                                    <Label htmlFor="link">Meeting Link<span className="text-danger">*</span></Label>
                                    <Input
                                        name="link"
                                        placeholder="Meeting Link"
                                        type="text"
                                        className="form-control"
                                        value={this.state.editMeeting.link}
                                        onChange={this.handleInputChange}
                                    />
                                    {this.meetingeditvalidator.message("meeting link", this.state.editMeeting.link, "")}
                                </Col>
                            )}
                            {this.state.editMeeting.type && this.state.editMeeting.type.value == 2 && (
                                <Col md="8" className="mb-3">
                                    <Label htmlFor="location">Meeting Location<span className="text-danger">*</span></Label>
                                    <Input
                                        name="location"
                                        placeholder="Meeting Location"
                                        type="text"
                                        className="form-control"
                                        value={this.state.editMeeting.location}
                                        onChange={this.handleInputChange}
                                    />
                                    {this.meetingeditvalidator.message("meeting location", this.state.editMeeting.location, "")}
                                </Col>
                            )}
                            <Col md="8" className="mb-3">
                                <Label htmlFor="purpose">Meeting Purpose<span className="text-danger">*</span></Label>
                                <Input
                                    name="purpose"
                                    placeholder="Type Meeting Purpose"
                                    value={this.state.editMeeting.purpose}
                                    type="text"
                                    className="form-control"
                                    onChange={this.handleInputChange}
                                />
                                {this.meetingeditvalidator.message("meeting purpose", this.state.editMeeting.purpose, "required|string")}
                            </Col>
                            <Col md="4" className="mb-3">
                                <Label htmlFor="with">Meeting with<span className="text-danger">*</span></Label>
                                <Input
                                    name="with"
                                    value={this.state.editMeeting.with}
                                    type="text"
                                    className="form-control"
                                    disabled
                                />
                                {this.meetingeditvalidator.message("meeting with", this.state.editMeeting.with, "required|string")}
                            </Col>
                            <Col md="6" className="mb-3">
                                <Label htmlFor="start_date_time">
                                    Start Date & Time<span className="text-danger">*</span>
                                </Label>
                                <Input
                                    name="start_date_time"
                                    type="datetime-local"
                                    className="form-control"
                                    min={minDateTime}
                                    value={this.state.editMeeting.start_date_time}
                                    onChange={e => {
                                        const edit_meeting_start_date_time = e.target.value;
                                        this.setState(prevState => ({
                                            editMeeting: {
                                                ...prevState.editMeeting,
                                                start_date_time: edit_meeting_start_date_time,
                                                end_date_time: ''
                                            }
                                        }), () => {
                                            this.handleStartTimeChange2(e);
                                        });
                                    }}
                                />
                                {this.meetingeditvalidator.message("start_date_time", this.state.editMeeting.start_date_time, "required")}
                            </Col>
                            <Col md="6" className="mb-3">
                                <Label htmlFor="end_date_time">
                                    End Date & Time<span className="text-danger">*</span>
                                </Label>
                                <Input
                                    name="end_date_time"
                                    type="datetime-local"
                                    className="form-control"
                                    value={this.state.editMeeting.end_date_time}
                                    min={this.state.editMeeting.start_date_time}
                                    onChange={e => this.setState(prevState => ({
                                        editMeeting: {
                                            ...prevState.editMeeting,
                                            end_date_time: e.target.value
                                        }
                                    }))}
                                />
                                {this.meetingeditvalidator.message("end_date_time", this.state.editMeeting.end_date_time, "required")}
                            </Col>
                            <Col md="4" className="mb-3">
                                <Label htmlFor="conducted_by">Conducted By<span className="text-danger">*</span></Label>
                                <Input
                                    name="conducted_by"
                                    value={this.state.editMeeting.conducted_by}
                                    type="text"
                                    className="form-control"
                                    disabled
                                />
                                {this.meetingeditvalidator.message("meeting conducted by", this.state.editMeeting.conducted_by, "required|string")}
                            </Col>
                            <Col md="8" className="mb-3">
                                <label className="control-label">Participants<span className="text-danger">*</span></label>
                                <CreatableSelect
                                    isMulti
                                    options={this.state.participant_master}
                                    classNamePrefix="select2-selection"
                                    value={this.state.editMeeting.participants}
                                    onChange={e => {
                                        this.handleEditParticipantsChange(e);
                                    }}
                                />
                                {this.meetingeditvalidator.message("participants", this.state.editMeeting.participants, "required")}
                            </Col>
                            <Col md="12" className="mb-3">
                                <Label htmlFor="meeting_agenda">Meeting Agenda<span className="text-danger">*</span></Label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={this.state.editMeeting.agenda}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        this.setState(prevState => ({
                                            editMeeting: {
                                                ...prevState.editMeeting,
                                                agenda: data
                                            }
                                        }));
                                    }}
                                />
                                {this.meetingeditvalidator.message("meeting agenda", this.state.editMeeting.agenda, "required|string")}
                            </Col>
                            <input type="hidden" name="id" value={this.state.editMeeting.id} />
                            <input type="hidden" name="leads_id" value={this.state.editMeeting.leads_id} />
                        </Row>
                        <div className="main-profile-block">
                            <div className="d-flex justify-content-end">
                                <button className="btn btn-primary mt-3" disabled={this.state.editmeetingSubmit} type="submit">
                                    {this.state.editmeetingSubmit ? 'PLEASE WAIT...' : 'Submit'}
                                </button>
                            </div>
                        </div>
                    </form>
                    </div>
                </Modal>
                {isShowing && (
                    <AddTransaction
                        leads_id={this.props.match.params.id}
                        isShowing={isShowing}
                        hide={this.toggleModal}
                        reloadElementSheet={() => this.fetchElementSheet()}
                        reloadLead={() => this.loadLead()}
                    />
                )}
                {this.state.isEditShowing && (
                    <EditTransaction
                        leads_id={this.props.match.params.id}
                        es_transaction_id={this.state.selectedTransactionId}  // Pass selected es_transaction_id
                        isShowing={this.state.isEditShowing}
                        hide={this.toggleEditModal}  // Pass the toggle function to close the modal
                        reloadElementSheet={() => this.fetchElementSheet()}
                        reloadLead={() => this.loadLead()}
                    />
                )}
            </React.Fragment>
        )
    }
}

LeadOverview.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }),
    location: PropTypes.object
}
export default LeadOverview;
